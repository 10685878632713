import React, { useEffect, useState } from "react"
import { DeviceType } from "../../../models/device";
import { GeofenceType } from "../../../models/geofence";
import MapBoxGL from "../../../components/map-box/MapBoxGl";
import { Layer, Marker, Popup, Source } from "react-map-gl";
import createGeofenceCircle from "../../../components/map-box/createGeofenceCircle";import { Feature, Polygon } from 'geojson';
import PopPupDevice from "../../../components/map-box/PopPupDevice";

type GeofenceCircle = Feature<Polygon, {
    radius: number;
    center: [number, number];
}>;

const GeofenceMapByID: React.FC<{
    geofence : GeofenceType | undefined
    devices: DeviceType[]
    setCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>
    center: { lat: number; lng: number }
    }> = ({ geofence, devices, setCenter, center }) => {
    let circleBeep : string = "circleBeepGreen";
    const [geoJsonCircle, setGeoJsonCircle] = useState<GeofenceCircle | null>(null);
    const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);


    useEffect(() => {
        if (geofence) {
           const center: [number, number] = [geofence.longitude, geofence.latitude];
           const radiusInMeters = geofence.radius;

           const circle = createGeofenceCircle(center, radiusInMeters);
           setGeoJsonCircle(circle);
        } }, [geofence]);

    const handleMarkerClick = (device: DeviceType, e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent event bubbling
      setSelectedDevice(device);
    };

    return (
        <>
        <div className="w-100 h-350px">
          <MapBoxGL center={center} searchBox>
            {devices?.map((device: DeviceType, idx: number) => {
              const devicePosition = {
                lat: device.last_lat ?? 0,
                lng: device.last_long ?? 0
              };
              circleBeep = device.handle_state === "sos" ? "circleBeepRed" : device.handle_state === "in_progress" ? "circleBeepYellow" : "circleBeepGreen"
              return (
                <div key={idx} z-1>
                <Marker
                  latitude={devicePosition.lat}
                  longitude={devicePosition.lng}
                  anchor="bottom"
                >
                <div className={`${circleBeep}` }
                    onClick={(e) => handleMarkerClick(device, e)}
                >
                {selectedDevice?.id === device?.id && (
                  <Popup
                  anchor="top-left"
                  longitude={devicePosition.lng}
                  latitude={devicePosition.lat}
                  onClose={() => setSelectedDevice(null)}
                  style={{ maxWidth: 'none', width: 'auto', padding: '0', margin: '0' }}         
                >
                  <PopPupDevice device={device} />
                </Popup>
                )}

                </div>
                </Marker>
              </div>
              );
            })}
             {geofence && (
                <>
                  <Source id="geofence" type="geojson" data={geoJsonCircle}>
                    <Layer
                      id="geofence-layer"
                      type="fill"
                      paint={{
                        'fill-color': 'rgba(255, 0, 0, 0.5)',
                        'fill-outline-color': 'rgba(255, 0, 0, 1)',
                        'fill-opacity': 0.5
                      }}
                    />
                  </Source>
                  <Marker
                    latitude={geofence?.latitude ?? 0}
                    longitude={geofence?.longitude ?? 0}
                    anchor="bottom"
                  >
                    <div className="bg-white opacity-75 px-2 py-1 rounded z-3">
                    {`${geofence?.radius && geofence?.radius < 1000 ? geofence?.radius + ' m' : geofence?.radius / 1000 + ' km'}`}
                    </div>
                  </Marker>
                </>
              )}

          </MapBoxGL>
        </div>
        </>
    )
}

export default GeofenceMapByID