import { useEffect, useState } from "react";
import { CreateGeofenceType } from "../../models/geofence";
import { Form, Formik, FormikHelpers } from "formik";
import { createGeofenceSchema } from "../../schemas/geofence";
import { FormBody } from "../../components/form/FormBody";
import { FormInputAsyncSearch, FormInputRadio, FormInputText } from "../../components/form/FormInputs";
import { FormFooterBase } from "../../components/form/FormFooters";
import { PageTitle } from "../../../_metronic/layout/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetGroupList } from "../../hooks/groups";
import { OptionType } from "../../models/base";
import { addGeofence } from "../../api/geofence";
import { useAppContext } from "../../../_metronic/helpers/AppContext";
import CreateGeofenceMaps from "./component/CreateGeofenceMaps";

export default function AddGeofence() {
    const navigate = useNavigate();
    const [ resetMap, setResetMap ] = useState<boolean>(false);
    const [optionsGroup, setOptionsGroup] = useState<OptionType[]>([]);
    const { data: dataGroup} = useGetGroupList();
    const { role, organizationName, subOrganizationName, nameGroup } = useAppContext();
    const initCreateGeofence: CreateGeofenceType = {
        name: '',
        fence_alarm: 'both',
        latitude: 0,
        longitude: 0,
        radius: 0,
        group_id: 0
    }
    const [position, setPosition] = useState({ lat: 0, lng: 0, radius: 250 });

    const getCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            radius: 250
          });
        });
      };

      useEffect(() => {
        getCurrentPosition();
      }, []);

    const handlePositionChange = (lat: number, lng: number, radius: number) => {
        setPosition({ lat, lng, radius });
    };

    const handleResetMap = () => {
        setResetMap(!resetMap);
    }

    const onFormSubmit = async (
        values: CreateGeofenceType,
        action: FormikHelpers<CreateGeofenceType>
    ) => {
        if (!position.lat && !position.lng && !position.radius) {
            return alert('geofence position is not provided')
        } else {
            const newGeofenceData: CreateGeofenceType = {
                ...values,
                latitude: position.lat,
                longitude: position.lng,
                radius: position.radius || 250,
            }
                if ( role === 4) delete newGeofenceData.group_id
            try {
                await addGeofence (newGeofenceData)
                toast.success('Geofence Berhasil Ditambahkan')
                action.resetForm()
                navigate('/geofence')
            } catch (error : any ) {
                console.log(error)
                toast.error(error?.response?.data?.error)
            }
        }
        
    }

    useEffect(() => {
        if (dataGroup) {
            const options: OptionType[] = dataGroup.map((group: any) => ({
              value: group.id,
              label: group.code,
          }));
          setOptionsGroup(options);
        }
    },[dataGroup])

    return (
        <>
        <PageTitle breadcrumbs={[
        {
        title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
        path: '/geofence/add',
        description: 'Tambah Geofence',
        isActive: true,
        isSeparator: false
        }]}>Konfigurasi Grup</PageTitle>
        <div className="w-100 h-350px">
            <CreateGeofenceMaps
            lng={position.lng}
            lat={position.lat}
            onPositionChange={handlePositionChange}
            />
        </div>
    <div>
        <Formik
            initialValues={initCreateGeofence}
            validationSchema={createGeofenceSchema}
            onSubmit={onFormSubmit}
        >
            <Form className='card mb-10'>
                <FormBody>
                    <FormInputText name='name' labelName='Nama Geofence' required={true} />
                    <div className={`${role === 4 ? 'd-none' : ''}`}>
                        <FormInputAsyncSearch
                                name="group_id"
                                labelName={'Kloter / Grup'}
                                options={optionsGroup}
                                value={role === 4 ? '4201' : undefined}
                                required={true}
                            />
                    </div>
                    <FormInputRadio
                        name='fence_alarm'
                        labelName='Jenis Alarm'
                        required={true}
                        values={['in', 'out', 'both']}
                        valuesLabel={['Masuk Zona', 'Keluar Zona', 'Masuk & Keluar Zona']}
                        checkedValue=""
                    />
                </FormBody>
                <FormFooterBase linkTo={'/geofence'} confirmText="Simpan" withBackButton={true} hitClickBack={handleResetMap}/>
            </Form>
        </Formik>
    </div>
    </>
    )
}