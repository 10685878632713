import moment from "moment";
import { useGetPersonalInfoById } from "./personalInfo";

interface DataJamaahList {
    label : string
    data : string
}


export const useGetListDataJamaah = (id?: string) => {
    const { data: dataJamaahById } = useGetPersonalInfoById(id);

    const listDataJamaah : DataJamaahList[] = [
        {
            label: 'Nama',
            data: dataJamaahById?.name ?? "-"
        },
        {
            label: "ID Jama'ah",
            data: dataJamaahById?.hajj_info ?? "-"
        },
        {
            label: "Nomor Paspor",
            data: dataJamaahById?.passport_number ?? "-"
        },
        {
            label: "Jenis Kelamin",
            data: dataJamaahById?.gender === 'male' ? 'Laki-laki' : 'Perempuan'
        },
        {
            label: "Tanggal Lahir",
            data: dataJamaahById?.date_of_birth ? moment(dataJamaahById?.date_of_birth).format('DD MMMM YYYY') : "-"
        },
        {
            label: "Golongan Darah",
            data: dataJamaahById?.blood_type ?? "-"
        },
        {
            label: "Asal Kota / Daerah",
            data: dataJamaahById?.place_of_origin ?? "-"
        },
        {
            label: "Alamat",
            data: dataJamaahById?.address ?? "-"
        },
        {
            label: "Pekerjaan",
            data: dataJamaahById?.job ?? "-"
        },
        {
            label: "Pendidikan Terakhir",
            data: dataJamaahById?.last_education ?? "-"
        },
        {
            label: "Kontak Darurat 1",
            data: dataJamaahById?.emergency_contact_1?.toString() ?? "-"
        },
        {
            label: "Kontak Darurat 2",
            data: dataJamaahById?.emergency_contact_2?.toString() ?? "-"
        }
    ];

    const listDataKesehatan : DataJamaahList[] = [
        {
            label: 'Catatan Dokter',
            data: dataJamaahById?.medical_notes ?? "-"
        },
        {
            label: 'Status Risti',
            data: dataJamaahById?.status_risti === 0 ? "Tidak Risti" : "Risti"
        },
        {
            label: 'Status Disabilitas',
            data: parseInt(dataJamaahById?.disability_status ?? '0') === 0 ? "Tidak" : "Disabilitas"
        },
        {
            label: 'Berat Badan',
            data: dataJamaahById?.weight?.toString() ?? "-"
        },
        {
            label: 'Tinggi Badan',
            data: dataJamaahById?.height?.toString() ?? "-"
        }
    ];

    const listDataTambahan : DataJamaahList[] = [
        {
            label: 'Kategori',
            data: dataJamaahById?.category ?? "-"
        },
        {
            label: 'Keberangkatan',
            data: dataJamaahById?.departure_date ? moment(dataJamaahById?.departure_date).format('DD MMMM YYYY') : '-'
        },
        {
            label: 'Unit Organisasi',
            data: dataJamaahById?.organization_unit?.name ?? "-"
        },
        {
            label: 'Sub Organisasi Unit',
            data: dataJamaahById?.sub_organization_unit?.name ?? "-"
        },
        {
            label: 'Kloter',
            data: dataJamaahById?.group?.code ?? "-"
        },
        {
            label: 'Sektor',
            data: dataJamaahById?.sector ?? "-"
        },
        {
            label: 'Maktab',
            data: dataJamaahById?.maktab ?? "-"
        }
    ];
    

    return { listDataJamaah , listDataKesehatan, listDataTambahan }
}



