import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useGetEmbarkationList } from '../../hooks/embarkation'
import { useGetGroupByEmbarkasiId, useGetGroupList } from '../../hooks/groups'
import { useGetParentOU, useGetSubOUByOUId } from '../../hooks/ou'
import { OUType } from '../../models/ou'
import './filterStyle.css'
import { useAppContext } from '../../../_metronic/helpers/AppContext'
import { OptionType } from '../../models/base'
import { FormSelectSearch } from '../../components/form/FormInputs'

const DeviceManagementFilter: FC<{
  onChangeStatus?: (status: string) => void,
  onChangeEmbarkation?: (embarkation: number) => void,
  onChangeGroup?: (group: number) => void,
  onChangeAvailability?: (availability: string) => void,
  onChangeCategory?: (category: string) => void,
  onChangeRole?: (role: string) => void,
  onChangeDate?: (date: string) => void,
  onChangeAlarmType?: (status: string) => void,
  onChangeDays?: (days: string) => void,
  onChangeGenderMale?: (genderMale: string) => void,
  onChangeGenderFemale?: (genderMale: string) => void,
  onChangeElderlyStatus?: (elderly_status: string) => void,
  onChangeDisabilityStatus?: (disability_status: string) => void,
  onChangeStatusRisti?: (status_risti: string) => void,
  onChangeOrganizationUnitId?: (organizationUnitId: number) => void,
  onChangeSubOrganizationId?: (subOrganizationId: number) => void,
  onChangeGroupId? : (groupId: number) => void
  status?: string | '',
  embarkation?: number | 0,
  group?: number | 0,
  availability?: string | '',
  category?: string | '',
  role?: string | '',
  date?: string | '',
  alarmType?: string | '',
  days?: string | '',
  genderMale?: string| '0',
  genderFemale?: string | '0',
  elderly_status?: string | '0',
  disability_status?: string | '0',
  status_risti?: string | '0',
  organizationUnitId?: number | 0,
  subOrganizationId?: number | 0,
  groupId?: number | 0,
  hiddenStatus?: boolean,
  hiddenEmbarkation?: boolean,
  hiddenAvailability?: boolean,
  hiddenCategory?: boolean,
  hiddenRole?: boolean,
  hiddenDate?: boolean,
  hiddenAlarmType?: boolean,
  hiddenDays?: boolean
  hiddenGender?: boolean,
  hiddenStatusJamaah?: boolean,
  hiddenOrganizationUnitId?: boolean,
  hiddenSubOrganizationId?: boolean,
  hiddenGroupId?: boolean
}> = ({
  onChangeStatus,
  onChangeEmbarkation,
  onChangeGroup,
  onChangeAvailability,
  onChangeCategory,
  onChangeRole,
  onChangeDate,
  onChangeAlarmType,
  onChangeDays,
  onChangeDisabilityStatus,
  onChangeElderlyStatus,
  onChangeGenderMale,
  onChangeGenderFemale,
  onChangeStatusRisti,
  onChangeOrganizationUnitId,
  onChangeSubOrganizationId,
  onChangeGroupId,
  status,
  embarkation,
  group,
  availability,
  category,
  role,
  date,
  alarmType,
  genderMale,
  genderFemale,
  elderly_status,
  disability_status,
  status_risti,
  days,
  groupId,
  organizationUnitId,
  subOrganizationId,
  hiddenEmbarkation,
  hiddenStatus,
  hiddenAvailability,
  hiddenCategory,
  hiddenRole,
  hiddenDate,
  hiddenAlarmType,
  hiddenGender,
  hiddenStatusJamaah,
  hiddenDays,
  hiddenOrganizationUnitId,
  hiddenSubOrganizationId,
  hiddenGroupId
}) => {
    const { data: embarkationList } = useGetEmbarkationList()
    const { data: groupByEmbarkationList } = useGetGroupByEmbarkasiId(embarkation)
    const [hiddenKloter, setHiddenKloter] = useState<boolean>(true)
    const { data: dataOU }  = useGetParentOU();
    const { data: dataGroup} = useGetGroupList();
    const { data: dataSubOU } = useGetSubOUByOUId(organizationUnitId?.toString() ?? '0');
    const { organizationID : OUID, subOrganizationID : subOUID , role : roleID } = useAppContext();
    const [optionsOU, setOptionsOU] = useState<OptionType[]>([]);
    const [optionSubOU, setOptionSubOU] = useState<OptionType[]>([]);
    const [optionGroup, setOptionGroup] = useState<OptionType[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);

    useEffect(() => {
      onChangeOrganizationUnitId?.(OUID)
      onChangeSubOrganizationId?.(subOUID)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OUID, subOUID])

    useEffect(() => {
      if (dataOU) {
        const options: OptionType[] = dataOU.map((ou : OUType) => ({
          label: ou.name,
          value: ou.id
        }))
        setOptionsOU(options)
      }
    }, [dataOU])

    useEffect(() => {
      if (dataSubOU && dataSubOU?.length > 0) {
        const options: OptionType[] = dataSubOU.map((ou : OUType) => ({
          label: ou.name,
          value: ou.id
        }))
        setOptionSubOU(options)
      }
    }, [dataSubOU])

    useEffect(() => {
      if (dataGroup) {
        const options: OptionType[] = dataGroup.map((group : any) => ({
          label: group.code,
          value: group.id
        }))
        setOptionGroup(options)
      }
    }, [dataGroup])

    const handleOUChange = (value: string | number) => {
      onChangeOrganizationUnitId?.(value as number)
    }

    const handleSubOUChange = (value: string | number) => {
      onChangeSubOrganizationId?.(value as number)
    }

    const handleGroupChange = (value: string | number) => {
      onChangeGroupId?.(value as number)
    }

    const resetData = () => {
      onChangeAvailability?.('')
      onChangeStatus?.('')
      onChangeEmbarkation?.(0)
      onChangeGroup?.(0)
      onChangeCategory?.('')
      setHiddenKloter?.(true)
      onChangeRole?.('')
      onChangeDate?.('')
      onChangeAlarmType?.('')
      onChangeDisabilityStatus?.('0')
      onChangeElderlyStatus?.('0')
      onChangeGenderFemale?.('0')
      onChangeStatusRisti?.('0')
      onChangeGenderMale?.('0')
      onChangeDays?.('')
      onChangeSubOrganizationId?.(0)
      onChangeGroupId?.(0)
      
      if (!hiddenOrganizationUnitId) onChangeOrganizationUnitId?.(0)
    }

    const [columns, setColumns] = useState<number>(1);
    const [items, setItems] = useState<number>(0);
    const [rows, setRows] = useState<number>(3);

    
    useEffect(() => {
      const hiddenProperties = {
        hiddenEmbarkation,
        hiddenStatus,
        hiddenAvailability,
        hiddenCategory,
        hiddenRole,
        hiddenDate,
        hiddenAlarmType,
        hiddenGender,
        hiddenStatusJamaah,
        hiddenOrganizationUnitId,
        hiddenSubOrganizationId,
        hiddenGroupId,
      };
      const countVisibleItems = () => {
        return Object.values(hiddenProperties).reduce((acc, value) => acc + (value !== true ? 1 : 0), 0);
      };
  
      setItems(countVisibleItems());
  
      if (items <= 2) {
        setColumns(1);
        setRows(items);
      } else if (items === 3) {
          setRows(3);
      } else {
        const half = Math.ceil(items / 2);
        setRows(half);
      }
    }, [hiddenEmbarkation, hiddenStatus, hiddenAvailability, hiddenCategory, hiddenRole, hiddenDate, hiddenAlarmType, hiddenGender, hiddenStatusJamaah, hiddenDays, hiddenOrganizationUnitId, hiddenSubOrganizationId, hiddenGroupId, items]);
   
    return (
      <div className='d-inline-block'>
        {/* begin::Filter Button */}
        <button
          // disabled={isLoading}
          type='button'
          className='btn btn-light-primary me-3'
          onClick={() => setShowFilter(!showFilter)}
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
          Filter
        </button>
        {/* end::Filter Button */}
        {/* begin::SubMenu */}
        <div className={`popup-filter mt-2 ${items > 3 ? 'w-600px w-md-625px' : 'w-300px w-md-325px'} ${!showFilter ? 'd-none' : ''}`} >
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter</div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200 w-100'></div>
          {/* end::Separator */}
          {/* begin::Filter */}
          <div className=' pt-5' data-kt-user-table-filter='form'>
              <div className="container" style={{ gridTemplateColumns: `repeat(${columns}, 1fr) ` , gridTemplateRows: `repeat(${rows}, 1fr)` }}>
                {/* begin::Input By Availability */}
                <div className='mb-6 item' hidden={hiddenAvailability}>
                  <label className='form-label fs-6 fw-bold'>Ketersediaan</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeAvailability?.(e.target.value)
                    }}
                    value={availability}
                  >
                    <option value={''}>Semua</option>
                    <option value={'yes'}>Tersedia</option>
                    <option value={'no'}>Tidak Tersedia</option>
                  </select>
                </div>
                {/* end::Input By Availability */}
                {/* begin::Input status */}
                <div className='item' hidden={hiddenStatus}>
                  <label className='form-label fs-6 fw-bold' >Status Perangkat</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeStatus?.(e.target.value)
                    }}
                    value={status}
                  >
                    <option value={''}>Semua Status</option>
                    <option value={'regular'}>Normal</option>
                    <option value={'sos'}>SOS</option>
                    <option value={'in_progress'}>Penanganan</option>
                  </select>
                </div>
                {/* end::Input status */}
                {/* begin:Input By Status Tracking */}
                <div className='item' hidden={hiddenAlarmType}>
                  <label className='form-label fs-6 fw-bold' >Jenis Alarm</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeAlarmType?.(e.target.value)
                    }}
                    value={alarmType}
                  >
                    <option value={''}>Semua</option>
                    <option value={'sos'}>SOS</option>
                    <option value={'geofence'}>Geofence</option>
                  </select>
                </div>
                {/* end:Input By Status Tracking */}
                {/* begin:Input By Date */}
                <div className='item' hidden={hiddenDate}>
                  <label className='form-label fs-6 fw-bold' >Waktu</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder=''
                    onChange={(e) => {
                      onChangeDate?.(e.target.value)
                    }}
                    value={date}
                  />
                </div>
                {/* end:Input By Date */}
                {/* begin::Select By Category */}
                <div className='item' hidden={hiddenCategory}>
                  <label className='form-label fs-6 fw-bold' >Kategori</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeCategory?.(e.target.value)
                    }}
                    value={category}
                  >
                    <option value={''}>Semua</option>
                    <option value={'hajj'}>Haji</option>
                    <option value={'umrah'}>Umroh</option>
                  </select>
                </div>

                {/* begin:: Select Organization Unit */}
                <div className='item' hidden={hiddenOrganizationUnitId}  >
                  <FormSelectSearch 
                    labelName='Unit Organisasi'
                    label={optionsOU.find((item) => item.value === organizationUnitId)?.label || 'Semua'}
                    name='organizationUnitId'
                    onSelectedValue={handleOUChange}
                    options={[{label: 'Semua', value: ''} , ...optionsOU]}
                    disabled={false}
                    required={false}
                  />
                </div>

                {/* begin:: Select Sub Organization */}
                <div className='item' hidden={hiddenSubOrganizationId}> 
                  <FormSelectSearch
                    labelName='Sub Unit Organisasi'
                    label={optionSubOU.find((item) => item.value === subOrganizationId)?.label || 'Semua'}
                    name='subOrganizationUnitId'
                    onSelectedValue={handleSubOUChange}
                    options={[{label: 'Semua', value: ''} , ...optionSubOU]}
                    disabled={!organizationUnitId}
                    required={false}
                  />
                </div>

                {/* begin::Select By Role */}
                <div className='item' hidden={hiddenRole}>
                  <label className='form-label fs-6 fw-bold' >Role</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeRole?.(e.target.value)
                    }}
                    value={role}
                  >
                    <option value={''}>Semua</option>
                    
                    {roleID === 1 && <option value={'superadmin'}>Superadmin</option> }
                    {roleID !== 3 && roleID !==4 && <option value={'admin'}>Admin</option>}
                    {roleID !== 4 && <option value={'supervisor'}>Supervisor</option>}
                    <option value={'operator'}>Operator</option>
                  </select>
                </div>

                {/* begin::Input embarkation */}
                <div className='item' hidden={hiddenEmbarkation}>
                  <label className='form-label fs-6 fw-bold' >Embarkasi:</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeEmbarkation?.(parseInt(e.target.value) | 0)
                      onChangeGroup?.(0)
                      if (parseInt(e.target.value) === 0) {
                        setHiddenKloter(true)
                      } else {
                        setHiddenKloter(false)
                      }
                    }}
                    value={embarkation}
                  >
                    <option value={0}>All Embarkation</option>
                    {embarkationList?.map((embarkation, idx) => (
                      <option key={idx} value={embarkation.id}>{embarkation.code}</option>
                    ))}
                  </select>
                </div>
                {/* end::Input embarkation */}
                {/* begin::Input group */}
                <div className='item' hidden={hiddenKloter}>
                  <label  className='form-label fs-6 fw-bold'>Kloter:</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='two-step'
                    data-hide-search='true'
                    onChange={(e) => {
                      onChangeGroup?.(parseInt(e.target.value) | 0)
                    }}
                    value={group}
                  >
                    <option value={0}>All Kloter</option>
                    {groupByEmbarkationList?.map((group, idx) => (
                      <option key={idx} value={group.id}>{group.code}</option>
                    ))}
                  </select>
                </div>
                {/* begin ::Select Group */}
                <div className='item' hidden={hiddenGroupId}>
                  <FormSelectSearch
                    labelName='Group'
                    label={optionGroup.find((item) => item.value === groupId)?.label || 'Semua'}
                    name='groupId'
                    onSelectedValue={handleGroupChange}
                    options={[{label: 'Semua', value: ''} , ...optionGroup]}
                    disabled={false}
                    required={false}
                  />
                </div>
          
                {/* Checkbox Gender */}
                <div className='item' hidden={hiddenGender} >
                  <label className='form-label fs-6 fw-bold' >Jenis Kelamin</label>
                  <div className='pt-2 mb-6'>
                  <div className='form-check form-check-custom form-check-solid form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='genderMale'
                      id='gender'
                      checked={genderMale === '1'}
                      onChange={(e) => {
                        onChangeGenderMale?.(e.target.checked ? '1' : '0')
                      }}
                    />
                    <label className='form-check-label' htmlFor='gender'>
                      Laki-laki
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='genderFemale'
                      id='gender'
                      checked={genderFemale === '1'}
                      onChange={(e) => {
                        onChangeGenderFemale?.(e.target.checked ? '1' : '0')
                      }}
                    />
                    <label className='form-check-label' htmlFor='gender'>
                      Perempuan
                    </label>
                  </div>
                  </div>
                </div>
                {/* Checkbox Status Jamaah */}
                <div className='item ' hidden={hiddenStatusJamaah}>
                  <label className='form-label fs-6 fw-bold'>Status Jamaah</label>
                    <div className='pt-2 mb-6'>
                    <div className='form-check form-check-custom form-check-solid form-check-inline'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='elderly_status'
                        id='elderly_status'
                        checked={elderly_status === '1'}
                        onChange={(e) => {
                          onChangeElderlyStatus?.(e.target.checked ? '1' : '0')
                        }}
                      />
                      <label className='form-check-label' htmlFor='elderly_status'>
                        Lansia
                      </label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid form-check-inline '>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='disability_status'
                        id='disability_status'
                        checked={disability_status === '1'}
                        onChange={(e) => {
                          onChangeDisabilityStatus?.(e.target.checked ? '1' : '0')
                        }}
                      />
                      <label className='form-check-label' htmlFor='disability_status'>
                        Disabilitas
                      </label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid form-check-inline '>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='status_risti'
                        id='status_risti'
                        checked={status_risti === '1'}
                        onChange={(e) => {
                          onChangeStatusRisti?.(e.target.checked ? '1' : '0')
                        }}
                      />
                      <label className='form-check-label' htmlFor='status_risti'>
                        Risti
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* end col */}
            </div>
            {/* end::Input group */}
            {/* begin::Actions */}
            <div className='d-flex justify-content-end mt-2 mb-6 me-6 w-100'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                data-kt-menu-dismiss='false'
                data-kt-user-table-filter='reset'
                onClick={() => {
                  resetData()
                }}
              >
                Reset
              </button>
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Content */}
        {/* end::SubMenu */}
      </div>
    )
  }

export { DeviceManagementFilter }
