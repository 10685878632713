import { useNavigate, useParams } from "react-router-dom"
import { PageTitle } from "../../../_metronic/layout/core"
import { Form, Formik, FormikHelpers } from "formik"
import { FormBody } from "../../components/form/FormBody"
import { FormInputKloterMulti, FormInputSelect } from "../../components/form/FormInputs"
import { FormFooterBase } from "../../components/form/FormFooters"
import { addConfigGroup } from "../../models/configurationKloter"
import { useGetGroupList } from "../../hooks/groups"
import { useEffect, useState } from "react"
import { addConfigGroupSchema } from "../../schemas/configGroup"
import { Spinner } from "react-bootstrap"
import { useAddConfigKloter, useGetConfigurationKloterByID } from "../../hooks/configurationKloter"
import { OptionType } from "../../models/base"
import { optionInterval } from "../../libs/variables"
import { useAppContext } from "../../../_metronic/helpers/AppContext"

export const FormConfigurationKloter = () => {
    const { id } = useParams()
    const navigate  = useNavigate();
    const { mutate: addConfigKloter } = useAddConfigKloter()
    const [options, setOptions] = useState<OptionType[]>([]);
    const { data: dataGroup, isLoading } = useGetGroupList()
    const { data: dataConfigGroupByID } = useGetConfigurationKloterByID(parseInt(id ?? "0")) 
    const { role, organizationName, subOrganizationName, nameGroup, groupId } = useAppContext();
    const formInitValue: addConfigGroup = ({
        group_id: [],
        update_interval: 0,
    })
    const titleHeader = role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : '';
    useEffect(() => {
        if (dataGroup) {
            const options: OptionType[] = dataGroup.map((group: any) => ({
                value: group.id,
                label: group.code,
            }));
            setOptions(options);
        }
    }, [dataGroup]);
    const onFormSubmit = (values: addConfigGroup, action: FormikHelpers<addConfigGroup>) => {
        try {
            id ? addConfigKloter({command : "edit", data: values , id: parseInt(id)}) : addConfigKloter({command : "create", data: values})
        } catch (error) {
            console.log(error)
        } finally {
            action.resetForm();
            navigate('/configurationKloter')
        }
    }
    return (
        <>
            <PageTitle breadcrumbs={[
            {
                title: `${titleHeader} - Perangkat`,
                path: '/configurationKloter',
                description: id ? "Ubah Konfigurasi Perangkat" : "Tambah Konfigurasi Perangkat",
                isActive: true,
                isSeparator: false
            }]}>Konfigurasi Grup</PageTitle>
            <div className="card mb-5 mx-xl-10">
                <Formik
                    initialValues={formInitValue}
                    validationSchema={addConfigGroupSchema}
                    onSubmit={onFormSubmit}
                >
                    <Form>
                        <FormBody>
                            <h2 className="fs-3 fw-bold text-gray-800 mb-3">{id ? "Ubah Konfigurasi Perangkat" : "Tambah Konfigurasi Perangkat"}</h2>
                            {id && role === 3 && <h3 className="fs-8 fw-normal text-gray-600">{`${organizationName} - ${subOrganizationName}`}</h3>}
                            {id && role === 4 && <h3 className="fs-8 fw-normal text-gray-600">{`${subOrganizationName} - ${nameGroup}`}</h3>}

                            
                            <hr className="mb-6  text-gray-400" />

                            {isLoading ? <><Spinner animation="border" /></> : <>
                                <FormInputKloterMulti
                                    name="group_id"
                                    labelName="Kloter / Grup"
                                    options={options}
                                    value={ dataConfigGroupByID ? dataConfigGroupByID?.group_id : groupId !== 0 ? groupId : undefined}
                                    label={ dataConfigGroupByID?.group?.code ? dataConfigGroupByID?.group?.code : nameGroup !== '' ? nameGroup : null }
                                    disabled={ id ? true : role === 4 ? true : false}
                                />
                            </>}
                            <p className="fw-bold text-body-tertiary m-0 p-0">Pengaturan Interval</p>
                            <FormInputSelect
                                name="update_interval"
                                labelName="Perubahan Interval"
                                value={dataConfigGroupByID?.update_interval ?? 0}
                            >
                                <option key={"default_interval"} value={0}>Pilih...</option>
                                {optionInterval.map((item, index) => {
                                    return (
                                        <option key={`interval`+index} value={item.value}>{item.label}</option>
                                    )
                                })}
                            </FormInputSelect>
                            {id && <input type="hidden" name="id" value={id} />}
                        </FormBody>
                        <FormFooterBase linkTo={"/configurationKloter"} confirmText="Simpan" />
                    </Form>
                </Formik>
            </div>
        </>
    )
}