import { useQuery } from "@tanstack/react-query"
import { getDataDashboard } from "../api/dashboard"

const _DASHBOARD_DATA = 'data_statistic'
export const useGetDataDashboard = () => {
    return useQuery(
        [_DASHBOARD_DATA],
        async () => {
            return await getDataDashboard();
        }
    );
};