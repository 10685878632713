import axios from "axios"
import { AddUserType, UserType } from "../models/users";
import { Pagination } from "../models/base";

const Endpoint = '/api/users'

export const getUsers = async (q: string = '') => {
    const res = await axios.get(Endpoint + '?' + q);
    return await res.data.data as Pagination<UserType[]>;
}

export const getUserByID = async (id: number) => {
    if(!id) return null
    const res = await axios.get(`${Endpoint}/${id}`);
    return res.data.data as UserType;
}

export const getUserByEmail = async (email: string) => {
    const res = await axios.get(`${Endpoint}/identifier/${email}`);
    return res.data.data as UserType;
}

export const addUser = async (user: AddUserType) => {
    const result = await axios.post(Endpoint, user);
    return result
}

export const updateUser = async (id: number, user: AddUserType) => {
    return await axios.put(`${Endpoint}/${id}`, user);
}

export const deleteUserByID = async (id: number) => {
    const res = await axios.delete(`${Endpoint}/${id}`);
    return res.data.error;
}