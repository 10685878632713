import { Link } from "react-router-dom"
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers"
import { PageTitle } from "../../../_metronic/layout/core"
import { useGetOUList } from "../../hooks/ou";
import { Badge, Button } from "react-bootstrap";
import { OUType } from "../../models/ou";
import ModalOrganizationUnitDetail from "./modal/ModalOrganizationUnitDetail";
import { useEffect, useState } from "react";
import ServerTable, { useServerTable } from "../../components/table/ServerTable";
import { createColumnHelper } from "@tanstack/react-table";
import { DeviceManagementFilter } from "../../modules/filter/DeviceManagementFilter";
import ToggleButton from "../geofence/ToggleComponent";
import { UserOnLocalStorageType } from "../../models/users";

const OrganizationUnitPage = () => {
    const {
        pageIndex,
        pageSize,
        filterValue,
        sorting,
        pagination,
        setFiltering,
        setPagination,
        setSorting,
    } = useServerTable();
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const dataUser = localStorage.getItem('data_user');
    const parseDataUser: UserOnLocalStorageType = JSON.parse(dataUser ?? '');
    const { data: OUList } = useGetOUList({
        q: filterValue,
        pageIndex,
        pageSize,
        sortBy: sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(),
        category: selectedCategory
    });

    const [showModal, setShowModal] = useState<boolean>(false);
    const [id, setID] = useState<number>(0);

    const handleShowModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setPagination({pageIndex: 0, pageSize: 10})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filterValue, selectedCategory])

    const handleClick = (id: number) => {
        setShowModal(true);
        setID(id);
    }
    const columnHelper = createColumnHelper<OUType>();
    const columns = [
        columnHelper.accessor(() => 'index', {
            id: 'index',
            header: () => <div className="text-center w-100">#</div>,
            cell: (info) => (
                <div className="text-center w-100">{pageSize * pageIndex + info?.row?.index + 1}</div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'code', {
            id: 'code',
            header: () => <div className="text-start w-100">KODE</div>,
            cell: (info) => (
                <div className="text-start w-100">{info?.row?.original.code}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'name', {
            id: 'name',
            header: () => <div className="text-start w-100">NAMA ORGANISASI</div>,
            cell: (info) => (
                <div className="text-start w-100">{info?.row?.original.name}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'type', {
            id: 'type',
            header: () => <div className="text-start w-100">KATEGORI</div>,
            cell: (info) => (
                <div className="text-start w-100">{info?.row?.original.type === 'hajj' ? 'Haji' : 'Umroh'}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'pic', {
            id: 'pic',
            header: () => <div className="text-start w-100">NAMA PENANGGUNG JAWAB</div>,
            cell: (info) => (
                <div className="text-start w-100">{info?.row?.original.pic}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'is_active', {
            id: 'is_active',
            header: () => <div className="text-center w-100">STATUS</div>,
            cell: (info) => (
                <div className="text-center w-100" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        {info?.row?.original.is_active === false ? (
                            <Badge bg="light-danger" style={{ color: '#f0406c', fontWeight: "bold" }}>
                                Tidak Aktif
                            </Badge>
                        ) : (
                            <Badge bg="light-success" style={{ color: '#c6f341', fontWeight: "bold" }}>
                                Aktif
                            </Badge>
                        )}
                    </div>
                </div>
            ),

            enableSorting: true
        }),
        columnHelper.accessor(() => 'control', {
            id: 'control',
            header: () => <div className="text-center w-100">KONTROL</div>,
            cell: (info) => (
                <div className="d-flex flex-row align-items-center justify-content-center">
                    {parseDataUser.role !== 1 ?
                        <>

                        </>
                        :
                        <>
                            <div style={{ marginRight: '5px', padding: '0' }}>
                                <ToggleButton is_active={info?.row?.original.is_active} key={String(info.row.original.id)} id={info.row.original.id} type="ou" />
                            </div>
                        </>
                    }

                    <div style={{ marginRight: '7px' }}>
                        <Button
                            className="btn btn-icon btn-sm btn-light-info"
                            onClick={() => handleClick(info?.row?.original.id)}
                        ><i className="bi bi-eye fs-2"></i></Button>
                    </div>
                    <div>
                        <Link to={`/organization-unit/edit/${info?.row?.original.id}`} className="btn btn-icon btn-light-primary btn-sm">
                            <i className="bi bi-pencil-square fs-2"></i>
                        </Link>
                    </div>
                </div>
            ),
            enableSorting: false
        }),
    ]

    return (
        <>
            <PageTitle breadcrumbs={[
                {
                    title: parseDataUser.role === 1 ? "TSM" : parseDataUser?.organization_unit?.name ,
                    path: '/organization-unit',
                    description:"",
                    isActive: false,
                    isSeparator: false
                }
            ]}>{parseDataUser.role !== 1 ? "Sub Organisasi" : "Unit Organisasi"}</PageTitle>
            <KTCard className='p-5 shadow-sm'>
                <div className="card-title d-flex justify-content-between">
                    <div
                        data-bs-target='#kt_account_profile_details'
                        aria-controls="kt_account_profile_details">
                        <div className="card-title m-0 d-flex flex-column">
                            <div className='d-flex align-items-center position-relative my-1'>
                                <KTIcon iconName='magnifier' className="fs-1 position-absolute ms-6" />
                                <input
                                    type='text'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control-solid w-250px ps-14'
                                    placeholder='Cari'
                                    onChange={(e) => setFiltering(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {parseDataUser.role !== 1 ?
                            <>

                            </>
                            :
                            <>
                                <DeviceManagementFilter
                                    onChangeCategory={(category: string) => setSelectedCategory(category)}
                                    category={selectedCategory}
                                    hiddenEmbarkation={true}
                                    hiddenAvailability={true}
                                    hiddenStatus={true}
                                    hiddenRole={true}
                                    hiddenAlarmType={true}
                                    hiddenDate={true}
                                    hiddenStatusJamaah={true}
                                    hiddenGender={true}
                                    hiddenOrganizationUnitId={true}
                                    hiddenSubOrganizationId={true}
                                    hiddenGroupId={true}
                                />
                            </>
                        }

                        <Link to={'/organization-unit/add'} className="btn btn-primary">
                            <KTIcon iconName='bi bi-plus-circle-dotted' className="fs-2" />
                            Tambah
                        </Link>
                    </div>
                </div>
                <KTCardBody>
                    <ServerTable
                        data={OUList?.rows ?? []}
                        columns={columns}
                        totalData={OUList?.total_rows ?? 0}
                        pageCount={OUList?.total_pages ?? 0}
                        pagination={pagination}
                        sorting={sorting}
                        setPagination={setPagination}
                        setSorting={setSorting}
                    />
                </KTCardBody>
            </KTCard>
            <ModalOrganizationUnitDetail show={showModal} onClose={handleShowModal} id={id} />
        </>
    )
}

export default OrganizationUnitPage