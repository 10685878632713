/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-white'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-row flex-column-fluid'>
        {/* begin::Wrapper */}
        {/* TODO : change bg login
        <div
          className='position-relative h-100'
          style={{
            backgroundColor: '#8AB71E',
            backgroundAttachment: 'fixed',
            width: '40%',
          }}
        >
          <img
            className='position-absolute bottom-0 start-0 w-100'
            src={toAbsoluteUrl('/media/patterns/bgAuth.png')}
            alt='...'
          />
          <img
            className='position-absolute top-0 w-50 mt-5'
            style={{
              left: '25%',
            }}
            alt='...'
            src={toAbsoluteUrl('/media/logos/ramah-indonesia-white.png')}
          />
        </div> */}
        <div
          className='w-500px p-10 p-lg-15 mx-auto overflow-auto bg-body'
          style={{
            width: '60%',
          }}
        >
          <Outlet />
        </div>
        
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}

// style={{
//   backgroundImage: `url(${toAbsoluteUrl(
//     "/media/illustrations/sketchy-1/14.png"
//   )})`,
// }}
