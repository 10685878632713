import axios from "axios";
import { AuthResponseModel } from "../modules/auth";
import { LoginType } from "../models/auth";
import { createSignature } from "../utils/jwtConverter";

const API_URL = process.env.REACT_APP_TRACKER_API_URL
export const LOGIN_URL = `${API_URL}/api/auth/v2/login`

export const loginV2 = async (data : LoginType) => {
    const signature = await createSignature(data, 'web')
    const mappedData = {
       email_or_username: data.email,
       password: data.password,
       client: 'web',
       signature
    }
    return await axios.post<AuthResponseModel>(LOGIN_URL, mappedData)
}

export const userLogout = async (refreshToken: string) => {
  return await axios.post<AuthResponseModel>(`${API_URL}/api/auth/logout`,{ refresh_token: refreshToken })
}