import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {convertStringToStringArray} from '../../utils/converter'
import {convertQueryToString} from '../../utils/queryToString'
import {BaseQuery} from '../../models/base'

interface IGetData<TReturn> {
  queryFn: (query: string) => TReturn
  queryKey: string | string[]
  query?: BaseQuery
  keepPreviousData?: boolean
  refetchOnWindowFocus? : boolean
}

interface IGetById<TId extends string | number, TReturn> {
  id?: TId
  queryFn: (id: TId) => TReturn
  queryKey: string | string[]
}

export const useGetListData = <TReturn>({
  queryFn,
  queryKey,
  query,
  keepPreviousData = true,
  refetchOnWindowFocus = true
}: IGetData<TReturn>): UseQueryResult<Awaited<TReturn>, unknown> => {
  const keys = convertStringToStringArray(queryKey)
  return useQuery({
    queryKey: [...keys, query],
    queryFn: () => queryFn(convertQueryToString(query)),
    keepPreviousData,
    refetchOnWindowFocus
  })
}

export const useGetDataById = <TId extends string | number, TReturn>({
  id,
  queryFn,
  queryKey,
}: IGetById<TId, TReturn>): UseQueryResult<Awaited<TReturn>, unknown> => {
  return useQuery({
    queryKey: convertStringToStringArray(queryKey),
    queryFn: () => {
      if (typeof id === 'undefined') return null
      return queryFn(id)
    },
    refetchOnWindowFocus: false
  })
}
