/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import { useAppContext } from '../../../helpers/AppContext'
import { useAuth } from '../../../../app/modules/auth'
import { useGetProfile } from '../../../../app/hooks/profile'

const HeaderUserMenu: FC = () => {
  const { fullname, email } = useAppContext();
  const { logout } = useAuth();
  const { data : dataUser } = useGetProfile();
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={dataUser?.photo_url}
                onError={(e) => { 
                  e.currentTarget.src = toAbsoluteUrl('/media/svg/humans/custom-2.svg');
                }}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {fullname}
            </div>
            <a href={'mailto:'+email} className='fw-bold text-muted text-hover-primary fs-9'>
              {email} 
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          Profil
        </Link>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
      <div className='menu-link px-5'
           onClick={() => logout()}
           >
          Keluar Akun
        </div>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
