import AWS from 'aws-sdk'

export const calculateAge = async (date_of_birth: string) => {
  const birthDate = new Date(date_of_birth);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  const day = today.getDate() - birthDate.getDate();
  if (month < 0 || (month === 0 && day < 0)) {
    age--;
  }
  const elder = await isElder(age);
  return { age, elder };
}

const isElder = async (age: number) => {
  let elder
  if (age >= 60) {
    elder = 1
  } else {
    elder = 0
  }
  return elder
}

export const UploadFileImportToS3 = async (file: File | null | undefined, type: string, fileName?: string, extensionPhoto?: string) => {
  let result = true
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
    region: process.env.REACT_APP_AWS_S3_REGION as string,
  })

  const s3 = new AWS.S3()
  const bucketName = process.env.REACT_APP_AWS_S3_BUCKET as string
  if (type !== 'massal' && !fileName) {
    throw new Error("ID Jamaah Tidak Ditemukan");
  }
  let directory = 'import_logs/Upload.xlsx';

  if (type === 'massal') {
    directory = 'import_logs/' + fileName 
  } else if(type === 'foto') {
    directory = 'personal_info/' + fileName + '.' + extensionPhoto
  } else if(type === 'foto-user') {
    directory = 'user/'+ fileName + '.' + extensionPhoto
  } else {
    throw new Error('Type is missing');
  }

  if (file) {
    const params = {
      Bucket: bucketName,
      Key: directory,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type,
    }
    s3.putObject(params, (err, data) => {
      if (err) {
        result = false
        console.log('Error uploading file: ', err)
      } else {
        console.log('File uploaded successfully: ', data)
      }
    })
  }

  return result
}

export const roleUser = (id: number) => {
  if (id === 1) {
    return "Superadmin"
  } else if (id === 2) {
    return "Admin"
  } else if (id === 3) {
    return "Supervisor"
  } else if (id === 4) {
    return "Operator"
  } else {
    return "Unknown"
  }
}

export const generateRandomPassword = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * Uploads a file to AWS S3 using a presigned URL.
 * @param {File | null | undefined} file The file to upload.
 * @param {string} url The presigned URL.
 */
export const uploadWithPresignedUrl = async (file: File | null | undefined, url: string) => {
  if (file) {
    const result = await fetch(url, {
      headers: {
        "x-amz-acl": "public-read",
      },
      method: 'PUT',
      body: file
    })
    return result
  }
}
