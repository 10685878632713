import { useQuery } from "@tanstack/react-query"
import { getDataSOS, getDataStatisticGeofence } from "../api/statistik"

const _STATISTIK_DATA_SOS = 'data_statistic_sos'
const _STATISTIK_DATA_GEOFENCE = 'data_statistic_geofence'

export const useGetDataSOS = () => {
    return useQuery(
        [_STATISTIK_DATA_SOS],
        async () => {
            return await getDataSOS();
        }
    );
}

export const useGetDataGeofence = () => {
    return useQuery(
        [_STATISTIK_DATA_GEOFENCE],
        async () => {
            return await getDataStatisticGeofence();
        }
    );
}
