import React, { useState } from 'react'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import '../style/CustomToastModal.css'
import { PersonalInfoType } from '../models/personalInfo'
import TableLogRowModal from './TableLogRowModal'
import { DeviceType } from '../models/device'
import { InventoryLog } from '../models/inventory'

interface CustomToastModalProps {
  isOpen: boolean
  toggle: () => void
  message: string
  type: string
  dataRows?: Array<PersonalInfoType>
  dataRowsDevice?: Array<DeviceType>
  dataRowsInventory?: InventoryLog
  dataType: string
}

const CustomToastModal: React.FC<CustomToastModalProps> = ({
  isOpen,
  toggle,
  message,
  type,
  dataRows,
  dataRowsDevice,
  dataRowsInventory,
  dataType
}) => {

  const getIcon = (type: string) => {
    switch (type) {
      case 'success':
        return <i className='bi bi-check-circle-fill' style={{ color: '#09bd0c' }} />
      case 'error':
        return <i className='bi bi-x-circle-fill' style={{ color: '#ec2029' }} />
      case 'warning':
        return <i className='bi bi-exclamation-circle-fill' style={{ color: '#ffa800' }} />
      case 'pending':
        return (
          <div className='spinner-border spinner-border-sm' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        )
      default:
        return null
    }
  }

  const titleToast = (type: string) => {
    switch (type) {
      case 'success':
        return <h5>Selesai</h5>
      case 'error':
        return <h5>Gagal</h5>
      case 'pending':
        return <h5>Mohon Tunggu...</h5>
      default:
        return <h5>Selesai</h5>
    }
  }

  const [openModal, setOpenModal] = useState<boolean>(false)

  const showModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <ToastContainer className={`custom-toast-modal${type === 'success' || type === 'warning' ? '-with-button' : ''}`} position='bottom-end'>
        <Toast show={isOpen} onClose={toggle}>
          <Toast.Header closeButton={type === 'pending' ? false : true}>

            <strong className="me-auto">{titleToast(type)}</strong>
          </Toast.Header>
          <Toast.Body >
            <div className='row d-flex flex-column'>
              <div className='col'>
                {getIcon(type)} {message}
              </div>
              {type === 'success' || type === 'warning' ? (
                <div className='col d-flex aling-items-center justify-content-center mt-5'>
                  <Button className='btn btn-light-primary' style={{ width: '100%' }} onClick={() => setOpenModal(true)}>
                    Lihat Hasil
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <TableLogRowModal data={dataRows} dataDevice={dataRowsDevice} dataInventory={dataRowsInventory} showModal={showModal} openModal={openModal} setOpenModal={setOpenModal} dataType={dataType} />
    </>
  )
}

export default CustomToastModal
