import * as yup from 'yup'
import { groupSchema } from './group'

const requiredStringWithMessage = (msg?: string) => yup.string().required(msg || '')

const baseUserSchema = {
    name: requiredStringWithMessage('Nama perlu diisi'),
    username: yup.string()
        .min(6, "Minimal 6 karakter")
        .max(30, "Maksimal 30 karakter")
        .required("Username perlu diisi")
        .test('no-space', 'username tidak boleh mengandung spasi', value => !/ /.test(value))
        .test('non-symbol', 'Username tidak boleh mengandung simbol', value => /^[a-zA-Z0-9]+$/.test(value)),
    email: yup.string().email().required("Email perlu diisi"),
    phone_number: yup.string()
        .matches(/^08\d{8,}$/, 'Nomor Telepon harus dimulai dengan 08 dan memiliki 10 digit atau lebih')
        .required("Nomor Telepon Perlu Diisi"),
    gender: yup.string().oneOf(['male','female']).required("Jenis Kelamin perlu dipilih"),
}

const fileSchema = yup.mixed()
    .test('is-file', 'Gambar perlu diisi', value => value instanceof File)
    .test('is-file-type', 'File harus type file', value => value instanceof File)
    .required("Gambar perlu diisi")

export const usersSchema = yup.object().shape({
    id: yup.number().required(),
    ...baseUserSchema,
    password: yup.string(),
    role: yup.string(),
    instances: yup.string(),
    detail: yup.string(),
    group: groupSchema,
    status: yup.string(),
    last_active: yup.string(),
    created_at: yup.string(),
    updated_at: yup.string(),
});

const userAddSchema = {
    ...baseUserSchema,
    file: fileSchema
}

export const userAddAdminSchema = yup.object().shape({
    ...userAddSchema, 
    organization_unit_id: yup.string().required('Induk Organisasi perlu diisi')
});

export const userAddSupervisorSchema = yup.object().shape({
    ...userAddSchema,
    sub_organization_unit_id: yup.string().required('Sub Organisasi perlu diisi')
});

export const userAddOperatorSchema = yup.object().shape({
    ...userAddSchema,
    group_id: yup.string().required('Group perlu diisi')
});

export const userEditAdminSchema = yup.object().shape({
    ...baseUserSchema,
    organization_unit_id: yup.string().required('Induk Organisasi perlu diisi')
})

export const userEditSupervisorSchema = yup.object().shape({
    ...baseUserSchema,
    sub_organization_unit_id: yup.string().required('Sub Organisasi perlu diisi')
})

export const userEditOperatorSchema = yup.object().shape({
    ...baseUserSchema,
    group_id: yup.string().required('Group perlu diisi')
})

export const userListSchema = yup.array().of(usersSchema)