import React from "react";
import { useGetDataDashboard } from "../../../../app/hooks/dashboard";
import { CustomPieChart } from "../../../../app/components/CustomPieChart";

type Props = {
  className: string
}

export const PieCharts1: React.FC<Props> = () => {
  const { data : dataDashboard } = useGetDataDashboard();
  const totalMalePersonalInfo = dataDashboard?.personal_info.male_personal_info ?? 0;
  const totalFemalePersonalInfo = dataDashboard?.personal_info.female_personal_info ?? 0;
  const totalPersonalInfo = (totalMalePersonalInfo + totalFemalePersonalInfo);
  const percentageMale = ((totalMalePersonalInfo / totalPersonalInfo) * 100).toFixed(0);
  const percentageFemale = ((totalFemalePersonalInfo / totalPersonalInfo) * 100).toFixed(0);

  const data = [
    {
      id: 1,
      value: totalMalePersonalInfo ?? 0,
      name: `Laki-laki`,
      percentageValue: `${!isNaN(parseInt(percentageMale)) ? percentageMale : 0}%`
    },
    {
      id: 2,
      value: totalFemalePersonalInfo ?? 0,
      name: `Perempuan`,
      percentageValue: `${!isNaN(parseInt(percentageFemale)) ? percentageFemale : 0}%`
    }
  ]

  return (
    <CustomPieChart data={data} totalData={totalPersonalInfo} type='gender'/>
  );
}