import React, { useEffect, useState } from 'react'
import {Nav, Spinner, Table} from 'react-bootstrap'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import moment from 'moment'
import {useGetDeviceHistories} from '../../../hooks/deviceHistory'
import {DeviceHistoryType} from '../../../models/deviceHIstory'
import {DeviceManagementFilter} from '../../../modules/filter/DeviceManagementFilter'
import IconNotification from './IconNotification'
import {getDeviceHistories} from '../../../api/deviceHistory'
import ModalLocationHistory from '../../devices/components/LocationHistoryModal'
import { useInView } from 'react-intersection-observer'

interface NotificationProps {}

const Notification: React.FC<NotificationProps> = () => {
  const {inView, ref} = useInView()
  const [page, setPage] = useState(0)
  const limit = 10;
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [reachedEnd, setReachedEnd] = useState(false)
  const [alarmType, setAlarmType] = useState<string>('sos')
  const [alarmFilter, setAlarmFilter] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [notificationList, setNotificationList] = useState<DeviceHistoryType[]>([])
  const {data: notificationData} = useGetDeviceHistories({
    device_id : '',
    q : "",
    status : "",
    alarm_type : '',
    date : date,
    search : search,
    hajj_info : "",
    page : 0,
    limit : 50
  })
  const query = {
    device_id : '',
    q : "",
    status : "",
    alarm_type : alarmType+alarmFilter,
    date : date,
    search : search,
    hajj_info : "",
    page : page,
    limit : limit
  }
  useEffect(() => {
    getNewHistory()
    // eslint-disable-next-line
  },[date, search, alarmType, alarmFilter])
  
  
  const getNewHistory = async () => {
    setPage(0)
    setNotificationList([])
    const data = await getDeviceHistories({...query, page: 0});
    setNotificationList(data)
    setReachedEnd(false)
    setIsLoadingMore(false)
    return data
  }

  const getMoreHistory = () => {
    setTimeout( async () => {
      const data = await getDeviceHistories({...query, page:page+1});
    if (data) {
      setNotificationList([...notificationList, ...data]);
      setPage(page+1)
    } else {
      setReachedEnd(true)
      setIsLoadingMore(false)
    }
      setIsLoadingMore(false)
    },500)
  }
  
  useEffect(() => {
    if (inView && !isLoadingMore && !reachedEnd && notificationList?.length > 0) {
      setIsLoadingMore(true)
      getMoreHistory()
    }
    // eslint-disable-next-line
  }, [inView]);

  const [activeLink, setActiveLink] = useState<string>('link-1')
  const [hiddenContent, setHiddenContent] = useState<{
    sos: boolean
    in_progress: boolean
    done: boolean
  }>({
    sos: false,
    in_progress: true,
    done: true,
  })
  const [showPlaybackModal, setShowPlaybackModal] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<DeviceHistoryType>()
  const data = {
    id: selectedNotification?.device_id ?? 0,
    targetDate: selectedNotification?.location_timestamp ?? '',
    last_lat: 0,
    last_long:0,
    hajj_info_id: selectedNotification?.hajj_info ?? 0,
    targetId : selectedNotification?.id ?? 0
  }

  const handlePlaybackModal = (notification: DeviceHistoryType) => {
    setShowPlaybackModal(true)
    setSelectedNotification(notification)
  }

  const handleClosePlaybackModal = () => {
    setShowPlaybackModal(false)
  }

  const handleContent = (key: number, eventKey: string): void => {
    setActiveLink(eventKey);
    if (key === 1) {
      setHiddenContent({
        sos: false,
        in_progress: true,
        done: true,
      });
      setAlarmType('sos');
    } else if (key === 2) {
      setHiddenContent({
        sos: true,
        in_progress: false,
        done: true,
      });
      setAlarmType('handled');
    } else {
      setHiddenContent({
        sos: true,
        in_progress: true,
        done: false,
      });
      setAlarmType('done'); 
    }
  };
  const filteredByStatus = (status: string) => {
    return notificationData?.filter((notification) => {
      if (status === 'new' && hiddenContent.sos) {
        return notification.alarm_type === status
      }
      if (status === 'in_progress' && hiddenContent.in_progress) {
        return notification.alarm_type === status
      }
      if (status === 'done' && hiddenContent.done) {
        return notification.status === 'canceled'
      }
      return false
    })
  }


  const formatMessage = (type: string, status: string) => {
    if (status === 'new') {
      if (type === 'geofence_in') {
        return ' masuk Ke Zona Yang Ditentukan!'
      } else if (type === 'geofence_out') {
        return ' keluar Dari Zona Yang Ditentukan!'
      } else if (type === 'sos') {
        return ' membutuhkan Bantuan Segera!'
      }
    } else if (status === 'in_progress') {
      return ' maktab Sedang Dalam Penanganan!'
    } else if (status === 'done') {
      return ' penanganan Selesai!'
    } else if (status === 'canceled') {
      return ' penanganan Dibatalkan!'
    }
     else {
      return ''
    }
  }

  const formatAlarm = (type: string): string => {
    if (type === 'geofence_in' || type === 'geofence_out') {
      return 'Geofence'
    } else {
      return 'SOS'
    }
  }

  const filteredDataByDate: {[date: string]: DeviceHistoryType[]} = {}
  notificationList?.forEach((notification: DeviceHistoryType) => {
    const date = moment(notification.created_at).format('YYYY-MM-DD')
    if (!filteredDataByDate[date]) {
      filteredDataByDate[date] = []
    }
    filteredDataByDate[date].push(notification)
  })
  return (
    <>
      <div style={{marginTop: '20px'}}>
        <KTCard className='p-4'>
          <h5 style={{fontWeight: 'bold'}}>Aktivitas</h5>
          <Nav variant='underline' className='nav nav-tabs nav-tabs-line'>
            <Nav.Item>
              <Nav.Link
                eventKey='link-1'
                onClick={() => handleContent(1, 'link-1')}
                style={{
                  color: activeLink === 'link-1' ? '#15c2d1' : '#000',
                }}
              >
                <div className='d-flex align-items-center justify-content-between p-3 fw-bold'>
                  SOS & Geofence &nbsp;
                  {filteredByStatus('sos')?.length ? (
                    <div
                      style={{
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '11px',
                        width: '30px',
                        height: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginLeft: '-5px',
                      }}
                    >
                      {(filteredByStatus('sos')?.length as number) - 1}+
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='link-2'
                onClick={() => handleContent(2, 'link-2')}
                style={{
                  color: activeLink === 'link-2' ? '#15c2d1' : '#000',
                }}
              >
                <div className='d-flex align-items-center justify-content-between p-3 fw-bold'>
                  Dalam Penanganan &nbsp;
                  {filteredByStatus('in_progress')?.length ? (
                    <div
                      style={{
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '10px',
                        width: '40px',
                        height: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {(filteredByStatus('in_progress')?.length as number) - 1}+
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='link-3'
                onClick={() => handleContent(3, 'link-3')}
                style={{
                  color: activeLink === 'link-3' ? '#15c2d1' : '#000',
                }}
              >
                <div className='d-flex align-items-center justify-content-between p-3 fw-bold'>
                  Selesai &nbsp;
                  {filteredByStatus('done')?.length ? (
                    <div
                      style={{
                        color: 'white',
                        backgroundColor: 'red',
                        borderRadius: '11px',
                        width: '30px',
                        height: '20px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      
                      {(filteredByStatus('done')?.length as number)}+
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <div className='card-title' style={{marginTop: '20px'}}>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-full ps-14'
                placeholder='Cari'
                onChange={(e) => setSearch(e.target.value as string)}
                value={search}
              />
              <div style={{marginLeft: '10px', width: '200px'}}>
                <DeviceManagementFilter
                  onChangeAlarmType={(alarm_type: string) => setAlarmFilter(alarm_type)}
                  onChangeDate={(date: string) => setDate(date)}
                  alarmType={alarmFilter}
                  hiddenAvailability={true}
                  hiddenCategory={true}
                  hiddenEmbarkation={true}
                  hiddenStatus={true}
                  hiddenRole={true}
                  hiddenStatusJamaah={true}
                  hiddenGender={true}
                  hiddenOrganizationUnitId={true}
                  hiddenSubOrganizationId={true}
                  hiddenGroupId={true}
                  hiddenAlarmType={alarmType==='done'}
                />
              </div>
            </div>
          </div>

          <div className={`${notificationList?.length > 0 ? 'h-500px overflow-auto' : ''}`}>
            {Object.entries(filteredDataByDate).map(([date, notifications]) => (
            <div key={`date-${date}`}>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th colSpan={3} style={{ backgroundColor: '#e4eaef' }}>
                      <div style={{ marginLeft: '10px' }}>
                        {moment(date).locale('id').format('DD MMMM YYYY')}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                  {notifications.map((notification) => (
                    <tr
                      key={`notification-${notification.id}`}
                      style={{
                        fontWeight: notification.status === 'unread' ? 'bold' : 'light',
                        cursor: 'pointer',
                      }}
                    >
                      <td>
                        <div className='row w-100'>
                          <div className='col-1'>
                            <IconNotification 
                              status={notification.status}
                              type='' 
                              />
                          </div>
                          <div
                            className='col-11'
                            onClick={() => handlePlaybackModal(notification)}
                          >
                            <div className='row'>
                              <div className='col-6'>
                                <p className='text-black-100 fw-bold'>
                                  ID Haji : {notification.personal_info.hajj_info}
                                </p>
                              </div>
                              <div className='col-6 float-right'>
                                <p className='text-black-50 fw-bold'>Status</p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-6'>
                                <p className='text-black-100 fw-bold'>
                                  {notification.personal_info.name},
                                  {formatMessage(notification.alarm_type, notification.status)}
                                </p>
                              </div>
                              <div className='col-6 float-right'>
                                <p className='text-black-100 fw-bold'>
                                  {formatAlarm(notification.alarm_type)}
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-6'>
                                <p className='text-black-100 fw-bold'>
                                  Maktab : {notification?.personal_info?.organization_unit?.pic}
                                </p>
                              </div>
                              <div className='col-6 float-right'>
                                <p className='text-black-100 fw-bold'>
                                  {moment(notification.created_at).format('HH:mm')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            ))}
            <div className='pb-3' ref={ref}>
              {isLoadingMore && (
                <div className='d-flex justify-content-center items-center gap-3 pb-3'>
                <Spinner animation="border" role="status">
                </Spinner> 
                <p>Loading...</p>
                </div>
              )}
            </div>
          </div>

        </KTCard>
      </div>
      <ModalLocationHistory
        show={showPlaybackModal}
        onClose={handleClosePlaybackModal}
        data={data}
      />
    </>
  )
}

export default Notification
