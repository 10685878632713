import * as yup from 'yup'

export const addConfigGroupSchema = yup.object({
    group_id : yup.array().test('non-empty', 'Kloter Perlu Dipilih', (value) => (value?.length ?? 0) > 0),
    update_interval : yup.number().required("Update Interval Perlu Dipilih"),
}); 

export const updateConfigDeviceSchema = yup.object({
    update_interval : yup.number().required("Update Interval Perlu Dipilih"),
})

export type AddConfigKloterType = yup.InferType<typeof addConfigGroupSchema>