import { useNavigate, useParams } from "react-router-dom"
import { useCreateOU, useGetOUById, useUpdateOU } from "../../hooks/ou"
import { useEffect, useState } from "react"
import { AddOUType, OUType } from "../../models/ou"
import { Form, Formik, FormikHelpers } from "formik"
import { PageTitle } from "../../../_metronic/layout/core"
import { addOUSchema, addSubOUSchema } from "../../schemas/ou"
import { FormHeader } from "../../components/form/FormHeaders"
import { FormBody } from "../../components/form/FormBody"
import { FormInputText, FormInputTextArea } from "../../components/form/FormInputs"
import { FormFooterBase } from "../../components/form/FormFooters"
import { useAppContext } from "../../../_metronic/helpers/AppContext"

const FormOrganizationUnit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        role, 
        organizationName, 
    } = useAppContext();
    const { data: dataOUById } = useGetOUById(String(id ?? 0) ?? '0');
    const { mutate: createOU } = useCreateOU();
    const { mutate: updateOU } = useUpdateOU();
    const [data, setData] = useState<OUType | null | undefined>();
    const formInitValue: AddOUType = {
        name: '',
        pic: '',
        phone_number: '',
        email: '',
        address: '',
        type: 'umrah'
    }  

    const onFormSubmit = (values: AddOUType, action: FormikHelpers<AddOUType>) => {
        try {
            id ? updateOU({ id: id, data: values}) : createOU({ data: values, role: role });
        } catch (error) {
            console.log("ERROR_ON_SUBMIT: ",error)
        } finally {
            action.resetForm();
            navigate('/organization-unit');
        }
    }

    useEffect(() => {
        if (id) {
            setData(dataOUById ?? null);
        }
    }, [id, dataOUById]);

    let title = id ? 'Edit ' : 'Tambah '
        title += role === 1 ? ' Unit Organisasi' : ' Sub Organisasi'
    
    return (
        <>
            <PageTitle breadcrumbs={[{
                title: role !== 1 ? organizationName : "TSM",
                path: '/organization-unit',
                description: title,
                isSeparator: false,
                isActive: true
            }]}>{role !== 1 ? "Sub Unit Organisasi" : "Unit Organisasi"}</PageTitle>
            <Formik
                initialValues={formInitValue}
                validationSchema={role !== 1 ? addSubOUSchema : addOUSchema}
                onSubmit={onFormSubmit}
            >
                <Form className="card mb-5 mb-xl-10 shadow-sm">
                    <FormHeader
                        title={
                            role !== 1 ?
                                id ? 'Edit Sub Organisasi' : 'Tambah Sub Organisasi'
                                : id ? 'Edit Unit Organisasi' : 'Tambah Unit Organisasi'
                        }
                    />
                    <FormBody>
                        {role !== 1 ? <>
                            <FormInputText name='name_ou' labelName="Induk Organisasi" value={organizationName} disabled={true}/>
                        </>
                        : <></>
                        }
                        <FormInputText name="name" labelName={role !== 1 ? 'Nama Sub Organisasi' : 'Nama Unit Organisasi'} value={ data?.name ?? '' } />
                        {/* <FormInputRadio
                            name="type"
                            labelName="Kategori"
                            values={['hajj', 'umrah']}
                            valuesLabel={['Haji', 'Umroh']}
                            checkedValue={data?.type}
                            onSelectedValueChange={handleValueType}
                            disabled={id ? true : false}
                        /> */}
                        <p className="fw-bold text-black-50">Penanggung Jawab</p>
                        <FormInputText name="pic" labelName="Nama" value={data?.pic ?? ''} />
                        <FormInputText name="phone_number" labelName="Nomor Telepon" value={data?.phone_number ?? ''} />
                        <FormInputText name="email" labelName="Email" value={data?.email ?? ''} />
                        <FormInputTextArea name="address" labelName="Alamat" value={data?.address ?? ''} />
                    </FormBody>
                    <FormFooterBase linkTo="/organization-unit" backText="Kembali" confirmText="Simpan" />
                </Form>
            </Formik>
        </>
    )
}

export default FormOrganizationUnit