import axios from 'axios'
import {UnbindDevicePersonalType, UpdateDevicePersonalType} from '../schemas/personalInfo'
import {Base, Pagination} from '../models/base'
import {
  AddPersonalInfoType,
  ImportPersonalInfoType,
  PersonalInfoType,
  unbindDevicesByGroupIdType,
} from '../models/personalInfo'

const Endpoint = '/api/device_personal_info'
const JamaahEndpoint = '/api/device_personal_info/batch'
const LogRows = '/api/import_log_rows/:id'
const LogProgress = '/api/import_log_progress/:id'

/**
 * Retrieves a list of personal information based on the given query.
 *
 * @param {string} query - The query string used to filter the personal information.
 * @return {Pagination<PersonalInfoType[]>} - A pagination object containing a list of personal information.
 *
 *
 */

export const addJamaahMassal = async (data: ImportPersonalInfoType) => {
  const resData = await axios.post<Base<ImportPersonalInfoType>>(JamaahEndpoint, data)
  return resData
}

export const getLogRows = async (id: string) => {
  const res = await axios.get(LogRows.replace(':id', id))
  return res.data
}

export const getLogProgress = async (id: string) => {
  const res = await axios.get(LogProgress.replace(':id', id))
  return res.data
}

export const getPersonalInfoList = async (query: string = '') => {
  const res = await axios.get(Endpoint + '?' + query)
  return (await res.data.data) as Pagination<PersonalInfoType[]>
}

export const getAllPersonalInfo = async () => {
  const res = await axios.get(Endpoint + '?sort=created_at,desc&size=10&')
  const resData = await axios.get(
    Endpoint + '?sort=created_at,desc&size=' + res.data.data.total_rows + '&'
  )
  return (await resData.data.data.rows) as PersonalInfoType[]
}

/**
 * Retrieves personal information by ID
 *
 * @param {string} id - The ID of the personal information to retrieve.
 * @return {PersonalInfoType} The retrieved personal information.
 */
export const getPersonalInfoById = async (id: string) => {
  const res = await axios.get<Base<PersonalInfoType>>(`${Endpoint}/${id}`)
  return res.data.data
}

/**
 * Adds personal information to the database.
 *
 * @param {AddPersonalInfoType} data - The personal information to be added.
 * @return {Promise<Base<PersonalInfoType>>} - A promise that resolves to the result of the API call.
 */
export const addPersonalInfo = async (data: AddPersonalInfoType) => {
    const mappedValues = {
      ...data,
      status_risti: parseInt(data.status_risti),
      height: parseInt(data.height),
      weight: parseInt(data.weight),
      age: parseInt(data.age !== '' ? data.age : '10'),
      disability_status: parseInt(data.disability_status),
      elderly_status: parseInt(data.elderly_status)
    }
    return await axios.post<Base<PersonalInfoType>>(Endpoint, mappedValues)
}

/**
 * Update personal information by ID.
 *
 * @param {string} id - The ID of the personal information.
 * @param {AddPersonalInfoType} data - The updated personal information data.
 * @return {Promise<Base<PersonalInfoType>>} A promise that resolves to the updated personal information.
 */
export const updatePersonalInfoById = async (id: string, data: AddPersonalInfoType) => {
  const mappedValues = {
    ...data,
    status_risti: parseInt(data.status_risti),
    height: parseInt(data.height),
    weight: parseInt(data.weight),
    age: parseInt(data.age !== '' ? data.age : '10'),
    disability_status: parseInt(data.disability_status),
    elderly_status: parseInt(data.elderly_status)
  }
  return await axios.put<Base<PersonalInfoType>>(`${Endpoint}/${id}`, mappedValues)
}

export const deletePersonalInfoById = async (id: number) => {
  return await axios.delete<Base<PersonalInfoType>>(`${Endpoint}/${id}`)
}

/**
 * Updates a device's personal information by its ID.
 *
 * @param {string} id - The ID of the device.
 * @param {UpdateDevicePersonalType} updatedData - The updated personal information of the device.
 * @return {Promise<AxiosResponse<any>>} A promise that resolves to the response from the API.
 */
export const updateDevicePersonalById = async (
  id: string,
  updatedData: UpdateDevicePersonalType
) => {
  const mappedValues = {
    ...updatedData,
    device_id: parseInt(updatedData.device_id),
  }
  return await axios.put(`${Endpoint}/${id}`, mappedValues)
}

/**
 * Unbinds a personal device by its ID.
 *
 * @param {string} id - The ID of the device to unbind.
 * @param {UnbindDevicePersonalType} updatedData - The updated data for the device.
 * @return {Promise} A promise that resolves with the result of the PUT request.
 */
export const unbindDevicePersonalById = async (
  id: string,
  updatedData: UnbindDevicePersonalType
) => {
  return await axios.put(`${Endpoint}/${id}`, updatedData)
}

/**
 * Unbinds a device by group ID.
 *
 * @param {string} id - The ID of the group to unbind the device from.
 * @return {Promise} - A promise that resolves when the device is successfully unbound.
 */

export const unbindDevicesByGroupId = async (data: unbindDevicesByGroupIdType) => {
  const res = await axios.put(`${Endpoint}/unbind_devices/group_ids`, data)
  return res
}
