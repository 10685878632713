import React from "react";
import { useGetDataDashboard } from "../../../../app/hooks/dashboard";
import { CustomPieChart } from "../../../../app/components/CustomPieChart";

type Props = {
  className: string
}

export const PieCharts2: React.FC<Props> = ({className}) => {
  const { data : dataDashboard } = useGetDataDashboard();
  const totalMaleDisabilityPersonalInfo = dataDashboard?.personal_info.disability_personal_info_male ?? 0;
  const totalFemaleDisabilityPersonalInfo = dataDashboard?.personal_info.disability_personal_info_female ?? 0;
  const totalPersonalInfo = (totalMaleDisabilityPersonalInfo + totalFemaleDisabilityPersonalInfo);
  const percentageDisabilityMale = ((totalMaleDisabilityPersonalInfo / totalPersonalInfo) * 100).toFixed(2);
  const percentageDisabilityFemale = ((totalFemaleDisabilityPersonalInfo / totalPersonalInfo) * 100).toFixed(2);

  const data = [
    {
      id: 1,
      value: totalMaleDisabilityPersonalInfo ?? 0,
      name: `Laki-laki`,
      percentageValue: `${!isNaN(parseInt(percentageDisabilityMale)) ? percentageDisabilityMale : 0}%`
    },
    {
      id: 2,
      value: totalFemaleDisabilityPersonalInfo ?? 0,
      name: `Perempuan`,
      percentageValue: `${!isNaN(parseInt(percentageDisabilityFemale)) ? percentageDisabilityFemale : 0}%`
    }
  ]

  return (
    <CustomPieChart data={data} totalData={totalPersonalInfo} type='disability'/>
    
  );
}