import React, {useEffect, useState} from 'react'
import ReactMapGL, {GeolocateControl, MapRef, NavigationControl} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import Geocoder from './Geocoder'

interface MapBoxGLProps {
  center?: {
    lat: number
    lng: number
  }
  searchBox?: boolean
  children?: React.ReactNode
}

const MapBoxGL = ({center, children, searchBox}: MapBoxGLProps) => {
  const [currentCenter, setCurrentCenter] = useState<{lat: number; lng: number}>(
    center || {lat: -6.188038690454364, lng: 106.7361316613683}
  )
  const mapRef = React.useRef<MapRef | null>(null)
  const zoom = 14

  useEffect(() => {
    if (currentCenter.lng && currentCenter.lat) {
      mapRef.current?.flyTo({center: [currentCenter.lng, currentCenter.lat], zoom})
    }
  }, [currentCenter])

  useEffect(() => {
    if (!center && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        },
        (error) => {
          console.error('Error getting location', error)
        }
      )
    } else if (center) {
      setCurrentCenter(center)
    }
  }, [center])

  return (
    <div className='w-100 h-100'>
      <ReactMapGL
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPS_KEY}
        mapStyle='mapbox://styles/mapbox/streets-v11'
        initialViewState={{
          latitude: -6.188038690454364,
          longitude: 106.7361316613683,
          zoom,
        }}
      >
        <NavigationControl position='bottom-right' />
        <GeolocateControl
          position='bottom-right'
          trackUserLocation
          onGeolocate={(e) => setCurrentCenter({lat: e.coords.latitude, lng: e.coords.longitude})}
        />
        {searchBox && (
          <Geocoder
          onResult={(result: any) =>
            setCurrentCenter({
              lat: result.geometry.coordinates[1],
              lng: result.geometry.coordinates[0],
            })
          }
        />)}
        {children}
      </ReactMapGL>
    </div>
  )
}

export default MapBoxGL
