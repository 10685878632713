import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TrackingMapsPage from '../../app/pages/tracking-maps/TrackingMapsPage'
import GeofencePage from '../pages/geofence/GeofencePage'
import { UsersPage } from '../pages/users/UsersPage'
import { FormUser } from '../pages/users/FormUser'
import ConfigurationKloterPage from '../pages/configurationKloter/ConfigurationKloterPage'
import { FormConfigurationKloter } from '../pages/configurationKloter/FormConfigurationKloter'
import AddGeofence from '../pages/geofence/AddGeofence'
import LogGeofence from '../pages/geofence/LogGeofence'
import JamaahPage from '../pages/jamaah/JamaahPage'
import FormJamaah from '../pages/jamaah/FormJamaah'
import ImportDataJamaah from '../pages/jamaah/ImportDataJamaah'
import DetailJamaah from '../pages/jamaah/DetailJamaah'
import OrganizationUnitPage from '../pages/organizationUnit/OrganizationUnitPage'
import FormOrganizationUnit from '../pages/organizationUnit/FormOrganizationUnit'
import InventoryPage from '../pages/inventory/InventoryPage'
import AddInventoryPage from '../pages/inventory/AddInventoryPage'
import MoveInventoryPage from '../pages/inventory/MoveInventoryPage'
import PageNotFound from '../pages/notFound/NotFound'
import ProfilePage from '../pages/profile/ProfilePage'

const PrivateRoutes = () => {
  const DevicePage = lazy(() => import('../../app/pages/devices/DevicePage'))
  const FormDevice = lazy(() => import('../../app/pages/devices/FormDevice'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='devices/*'
          element={
            <SuspensedView>
              <DevicePage />
            </SuspensedView>
          }
        />
        <Route
          path='form-device/:type'
          element={
            <SuspensedView>
              <FormDevice />
            </SuspensedView>
          }
        />
        <Route
          path='tracking/*'
          element={
            <SuspensedView>
              <TrackingMapsPage />
            </SuspensedView>
          }
        />
        <Route
          path='geofence/*'
          element={
            <SuspensedView>
              <GeofencePage />
            </SuspensedView>
          }
        />
        <Route
          path='geofence/add'
          element={
            <SuspensedView>
              <AddGeofence />
            </SuspensedView>
          }
        />
        <Route
          path='geofence/log/:id'
          element={
            <SuspensedView>
              <LogGeofence />
            </SuspensedView>
          }
        />
        <Route
          path='users/add'
          element={
            <SuspensedView>
              <FormUser></FormUser>
            </SuspensedView>
          }
        >
        </Route>
        <Route
          path='users/update/:id'
          element={
            <SuspensedView>
              <FormUser></FormUser>
            </SuspensedView>
          }
        >
        </Route>
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage></UsersPage>
            </SuspensedView>
          }
        >
        </Route>
        <Route
          path="configurationKloter/*"
          element={
            <SuspensedView>
              <ConfigurationKloterPage />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="configurationKloter/add"
          element={
            <SuspensedView>
              <FormConfigurationKloter />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="configurationKloter/update/:id"
          element={
            <SuspensedView>
              <FormConfigurationKloter />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="jamaah"
          element={
            <SuspensedView>
              <JamaahPage />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="jamaah/add"
          element={
            <SuspensedView>
              <FormJamaah />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="jamaah/import"
          element={
            <SuspensedView>
              <ImportDataJamaah />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="jamaah/update/:id"
          element={
            <SuspensedView>
              <FormJamaah />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="jamaah/detail/:id/:type"
          element={
            <SuspensedView>
              <DetailJamaah />
            </SuspensedView>
          }>
        </Route>
        <Route
          path="organization-unit"
          element={
            <SuspensedView>
              <OrganizationUnitPage />
            </SuspensedView>
          }
        />
        <Route
          path="organization-unit/add"
          element={
            <SuspensedView>
              <FormOrganizationUnit />
            </SuspensedView>
          }
        />
        <Route
          path="organization-unit/edit/:id"
          element={
            <SuspensedView>
              <FormOrganizationUnit />
            </SuspensedView>
          }
        />

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />


          {/* <Route
          path="inventory"
          element={
            <SuspensedView>
              <InventoryPage />
            </SuspensedView>
          }
        />
        <Route
          path="inventory/add"
          element={
            <SuspensedView>
              <AddInventoryPage />
            </SuspensedView>
          }
        />
        <Route
        path='inventory/move'
        element={
          <SuspensedView>
            <MoveInventoryPage />
          </SuspensedView>
        }
        />

        {/* Page Not Found */}

        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}

      </Route>
        <Route
        path='*'
        element={
        <PageNotFound />
        }
      />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
