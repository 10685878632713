/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useGetDataDashboard } from '../../../../app/hooks/dashboard';

type Props = {
  className: string
}

export const ChartsWidget3: React.FC<Props> = ({className}) => {
  const { data : dataDashboard } = useGetDataDashboard();

  return (
    <div className={`card ${className}`}>
      <div className='card-body d-flex flex-column align-items-center justify-content-center'>
        <div>
          <div className='d-flex flex-column gap-2'>
            <h3 className='text-left text-muted mb-0 mr-2'>Total Jamaah</h3>
            <div className='d-flex align-items-center mb-2'>
              <i className='bi bi-people-fill fs-2x text-primary'></i>
              <h4 className='mb-0 fs-2x px-3'>{dataDashboard?.personal_info.personal_info_total}</h4>
            </div>
          </div>
          <div className='d-flex flex-column gap-2'>
            <h3 className='text-left text-muted mb-0 mr-2 mt-2'>Jamaah Terhubung</h3>
            <div className='d-flex align-items-center'>
              <i className='bi bi-music-player-fill fs-2x text-success'></i>
              <h4 className='mb-0 fs-2x px-3'>{dataDashboard?.personal_info.binded_personal_info}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );  
  
};


