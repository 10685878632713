import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan,
} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import CustomToastModal from '../../app/components/CustomToast'
import {useGetLogProgress, useGetLogRows} from '../../app/hooks/personalInfo'
import { useGetDeviceLogProggress, useGetDeviceLogRow } from '../../app/hooks/device'
import {useAppContext} from '../helpers/AppContext'
import { PersonalInfoType } from '../../app/models/personalInfo'
import { useGetInventoryLogProgress, useGetInventoryLogRow } from '../../app/hooks/inventory'
import { DeviceType } from '../../app/models/device'
import { InventoryLog } from '../../app/models/inventory'

const MasterLayout = () => {
  const { jamaahImportId, dataJamaahRows, deviceImportId, dataDeviceRows, inventoryImportId, dataInventoryRows } = useAppContext();
  const { mutate: getLogProgress, toast, setToast } = useGetLogProgress()
  const { mutate: getDeviceLogProgress, toast: deviceToast, setToast: setDeviceToast } = useGetDeviceLogProggress()
  const { mutate: getLogRows } = useGetLogRows();
  const { mutate: getDeviceLogRows } = useGetDeviceLogRow();
  const { mutate: getInventoryLogProgress, toast: inventoryToast, setToast: setInventoryToast } = useGetInventoryLogProgress();
  const { mutate: getInventoryLogRows } = useGetInventoryLogRow();
  const [ dataRows, setDataRows ] = useState<Array<PersonalInfoType>>([]);
  const [ dataRowsDevice, setDataRowsDevice ] = useState<Array<DeviceType>>([]);
  const [ dataRowsInventory, setDataRowsInventory ] = useState<InventoryLog>({} as InventoryLog);
  const toggleToast = () => setToast({...toast, isOpen: !toast.isOpen})
  const toggleDeviceToast = () => setDeviceToast({...deviceToast, isOpen: !deviceToast.isOpen})
  const toggleInventoryToast = () => setInventoryToast({...inventoryToast, isOpen: !inventoryToast.isOpen})
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    if (jamaahImportId !== "") {
      getLogProgress({id: jamaahImportId})
    }
  }, [jamaahImportId, getLogProgress]);

  useEffect(() => {
    if (deviceImportId !== "") {
      getDeviceLogProgress({id: deviceImportId})
    }
  },[deviceImportId, getDeviceLogProgress]);

  useEffect(() => {
    if (inventoryImportId !== "") {
      getInventoryLogProgress({id: inventoryImportId})
    }
  },[inventoryImportId, getInventoryLogProgress]);


  useEffect(() => {
    if (toast.type === 'success' || toast.type === 'warning') {
        getLogRows({id: jamaahImportId})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast.type, getLogRows, getLogRows])

  useEffect(() => {
    if (deviceToast.type === 'success' || deviceToast.type === 'warning') {
      getDeviceLogRows({id: deviceImportId})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deviceToast.type, getDeviceLogRows])

  useEffect(() => {
    if (inventoryToast.type === 'success' || inventoryToast.type === 'warning') {
      getInventoryLogRows({id: inventoryImportId})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inventoryToast.type, getInventoryLogRows])

  useEffect(() => {
    if(dataJamaahRows ?? dataJamaahRows?.length > 0){
      setDataRows(dataJamaahRows)
    }
  },[dataJamaahRows]);

  useEffect(() => {
      setDataRowsDevice(dataDeviceRows)
  },[dataDeviceRows]);

  useEffect(() => {
    setDataRowsInventory(dataInventoryRows)
    console.log(dataInventoryRows)
  },[dataInventoryRows])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
      <CustomToastModal
        isOpen={toast.isOpen}
        toggle={toggleToast}
        message={toast.message}
        type={toast.type}
        dataRows={dataRows}
        dataType='jamaah'
      />
      <CustomToastModal
        isOpen={deviceToast.isOpen}
        toggle={toggleDeviceToast}
        message={deviceToast.message}
        type={deviceToast.type}
        dataRowsDevice={dataRowsDevice}
        dataType='device'
      />
      <CustomToastModal
        isOpen={inventoryToast.isOpen}
        toggle={toggleInventoryToast}
        message={inventoryToast.message}
        type={inventoryToast.type}
        dataRowsInventory={dataRowsInventory}
        dataType='inventory'
      />
    </PageDataProvider>
  )
}

export {MasterLayout}
