import {BaseQuery} from '../models/base'

export const convertQueryToString = (query?: BaseQuery) => {
  const defaultQuery = 'sort=created_at,desc'
  if (!query) return defaultQuery
  const {q, sortBy, pageIndex, pageSize, code, status, embarkation_id, group_id, is_available, category, role, elderly_status, disability_status, genderMale, genderFemale, status_risti, organization_unit_id, sub_organization_unit_id} = query
  let strQuery = ''
  if (q) strQuery += `q=${q}&`
  sortBy === '' ? (strQuery += `${defaultQuery}&`) : (strQuery += `sort=${sortBy}&`)
  if (pageIndex) strQuery += `page=${pageIndex}&`
  if (pageSize) strQuery += `size=${pageSize}&`
  if (code) strQuery += `code=${code}&`
  if (status) strQuery += `handle_state=${status}&`
  if (embarkation_id) strQuery += `embarkation_id=${embarkation_id}&`
  if (group_id) strQuery += `group_id=${group_id}&`
  if (is_available) strQuery += `is_available=${is_available}&`
  if (category) strQuery += `category=${category}&`
  if (role) strQuery += `role=${role}&`
  if (elderly_status) strQuery += `elderly_status=${elderly_status}&`
  if (status_risti) strQuery += `status_risti=${status_risti}&`
  if (disability_status) strQuery += `disability_status=${disability_status}&`
  if (organization_unit_id) strQuery += `organization_unit_id=${organization_unit_id}&`
  if (sub_organization_unit_id) strQuery += `sub_organization_unit_id=${sub_organization_unit_id}&`
  let gender
    if (genderMale === '0' && genderFemale === '0') {
      gender = '';
    } 
    if (genderMale === '0' && genderFemale === '1') {
      gender = 'female';
    } 
    if (genderMale === '1' && genderFemale === '0') {
      gender = 'male';
    } 
    if (genderMale === '1' && genderFemale === '1') {
      gender = '';
    }
    if (genderMale === '1' && !genderFemale) {
      gender = 'male'
    }
    if (!genderMale && genderFemale === '1') {
      gender = 'female'
    }
  if (gender) strQuery += `gender=${gender}&` 
  return strQuery
}
