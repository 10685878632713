import { OptionType } from "../models/base"

export const optionInterval: OptionType[] = [
    { value: 1, label: '1 Menit' },
    { value: 5, label: '5 Menit' },
    { value: 10, label: '10 Menit' },
    { value: 15, label: '15 Menit' },
    { value: 30, label: '30 Menit' },
    { value: 60, label: '60 Menit' },
]

export const optionJob:  OptionType[] = [
    { value: "Pedagang", label: "Pedagang" },
    { value: "Ibu Rumah Tangga", label: "Ibu Rumah Tangga" },
    { value: "Pegawai Swasta", label: "Pegawai Swasta" },
    { value: "Pegawai BUMN", label: "Pegawai BUMN" },
    { value: "Pelajar/Mahasiswa", label: "Pelajar / Mahasiswa" },
    { value: "Pensiunan", label: "Pensiunan" },
    { value: "Petani", label: "Petani" },
    { value: "PNS", label: "PNS" },
    { value: "TNI/POLRI", label: "TNI / Polri"}
]

export const optionLastEducation:  OptionType[] = [
    { value: "SD", label: "SD" },
    { value: "SLTP", label: "SLTP" },
    { value: "SLTA", label: "SLTA" },
    { value: "D1/D2/D3/SM", label: "D1/D2/D3/SM" },
    { value: "D4/S1", label: "D4/S1"},
    { value: "S2", label: "S2" },
    { value: "S3", label: "S3" },
    { value: "Lainnya", label: "Lainnya" }
]