import { Slider } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { Card } from 'react-bootstrap';

interface DataType {
    id: number,
    value: number,
    name?: string,
    percentageValue?: string
}

interface CustomPieChartProps {
    data: DataType[]
    totalData: number
    type: string
}

export const CustomPieChart: React.FC<CustomPieChartProps> = ({ data, totalData, type }) => {
    let title;
    let color : string[] = [];

    if(type === 'gender'){
        title = 'Data Jenis Kelamin';
        color = ['#efc142', '#3a86f6'];
    } else if(type === 'disability') {
        title = 'Data Disabilitas';
        color = ['#5cc261', '#6845e0'];
    } else if(type === 'risti') {
        title = 'Data Risti';
        color = ['#e4405e', '#efc142'];
    };

    return (
        <Card className='h-100 w-100'>
            <Card.Header className='border-0 d-flex align-items-center'>
                <span className='card-label fw-normal fs-4 mb-1 text-left'>{title}</span>
            </Card.Header>
            <Card.Body className='pt-0'>
                {!data ? 
                <>
                <Slider value={0} min={0} max={100} /> Loading...
                </>
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PieChart
                    margin={{ bottom: 0, left: 0, right: 0 , top: 0 }}
                    colors={color}
                    series={[
                        {
                            data,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
                        },
                    ]}
                    
                    height={200}
                />
                    <div className='ms-5 w-50'>
                        {data.map((item, index) => (
                        <div key={index}>
                            <p className="m-0 p-0 fs-12 fw-bold text-muted"><i className="bi bi-circle-fill" style={{ color: color[index] }}></i> {item.name}</p>
                             <p className='m-0 p-0 fs-2 fw-bold'>{item.value} <span className='fs-6 text-muted'>{item.percentageValue}</span></p>
                        </div>
                        ))}
                        <p className=" m-0 p-0 fs-12 fw-bold text-muted">Total</p>
                        <p className='m-0 p-0 fs-2 fw-bold'>{totalData}</p> 
                    </div>
                </div>
                }
            </Card.Body>
        </Card>
    )
}
