import { useState, useMemo } from 'react'
import { useDebounce } from 'usehooks-ts'
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  PaginationState,
} from '@tanstack/react-table'

interface ServerTableProps<TData> {
  data: TData[]
  columns: ColumnDef<TData, string>[]
  pageCount: number
  totalData: number
  pagination: PaginationState
  sorting: SortingState
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>
  showPaginationNumbers?: boolean
  onPageSizeChangeCallback?: (newSize: number) => void
}

interface UseServerTableProps {
  filterDelay?: number
  initialFilterValue?: string
  initialPageIndex?: number
  initialPageSize?: number
}

export const useServerTable = (props?: UseServerTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [filtering, setFiltering] = useState<string>(props?.initialFilterValue ?? '')
  const debounceFiltering = useDebounce<string>(filtering, props?.filterDelay ?? 500)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: props?.initialPageIndex ?? 0,
    pageSize: props?.initialPageSize ?? 10,
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  return {
    pageSize,
    pageIndex,
    filterValue: debounceFiltering,
    sorting,
    pagination,
    setFiltering,
    setPagination,
    setSorting,
  }
}

export default function ServerTable<TData extends object>({
  data,
  columns,
  pageCount,
  totalData,
  pagination,
  sorting,
  setPagination,
  setSorting,
  showPaginationNumbers = true,
  onPageSizeChangeCallback = () => { }
}: ServerTableProps<TData>) {
  const table = useReactTable({
    data: data,
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    manualSorting: true,
  })

  const numStart = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1
  const numEnd = numStart - 1 + table.getState().pagination.pageSize

  const Paginator = ({ totalPages }: { totalPages: number }): JSX.Element => {
    const pagination: JSX.Element[] = []
    const currentIndex = table.getState().pagination.pageIndex;

    const createIndexLink = (i: number) => {
      const currentIndex = table.getState().pagination.pageIndex
      return (
        <li
          key={`li-${i}`}
          className={`paginate_button page-item ${currentIndex === i ? 'active' : ''}`}>
          <button
            aria-controls="kt_datatable_column_rendering"
            className="page-link"
            onClick={() => table.setPageIndex(i)}
          >{i + 1}
          </button>
        </li>
      )
    }

    const createDots = () => <div key={`dots-${totalPages}`}>...</div>

    if (totalPages < 7) {
      for (let i = 0; i < totalPages; i++) {
        pagination.push(createIndexLink(i))
      }
      return <>{pagination}</>
    }

    if (currentIndex < 3) {
      for (let i = 0; i < 3; i++) {
        pagination.push(createIndexLink(i))
      }
      pagination.push(createDots())

      for (let i = totalPages - 3; i <= totalPages-1; i++) {
        pagination.push(createIndexLink(i))
      }
    } else if ( currentIndex > totalPages - 6) {
      pagination.push(createDots())
      for (let i = totalPages - 6; i <= totalPages-1; i++) {
        pagination.push(createIndexLink(i))
      }
    } else {
      for (let i = currentIndex - 2; i < currentIndex+1; i++) {
        pagination.push(createIndexLink(i))
      }
      pagination.push(createDots())
      for (let i = totalPages - 3; i <= totalPages-1; i++) {
        pagination.push(createIndexLink(i))
      }
    }
    return <>{pagination}</>
  }

  return (
    <>
      <div className='mt-4 table-responsive'>
        <table className='table overflow-x-scroll table-hover'>
          <thead className='fw-bold text-body-tertiary'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    role='button'
                    onClick={header.column.getToggleSortingHandler()}
                    className='px-2 py-2'
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'select-none cursor-pointer d-flex align-items-center gap-1'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <i className="bi bi-sort-alpha-up"></i>,
                          desc: <i className="bi bi-sort-alpha-down-alt"></i>,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='fw-bold'>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className='align-middle'>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className='px-2 py-2 border-top'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='py-2 d-flex flex-column flex-md-row-reverse align-items-center justify-content-between gap-2'>
        <div className='d-flex gap-2'>
          <ul className="pagination">
            <li className={`paginate_button page-item previous ${!table.getCanPreviousPage() && 'disabled'}`} id="kt_datatable_column_rendering_previous">
              <button
                aria-controls="kt_datatable_column_rendering"
                className={`page-link`}
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.setPageIndex(0)}
              >
              <i className="bi bi-chevron-double-left"></i>
              </button>
            </li>
            <li className={`paginate_button page-item previous ${!table.getCanPreviousPage() && 'disabled'}`} id="kt_datatable_column_rendering_previous">
              <button
                aria-controls="kt_datatable_column_rendering"
                className={`page-link`}
                disabled={!table.getCanPreviousPage()}
                onClick={() => table.previousPage()}
              >
              <i className="bi bi-chevron-left"></i>
              </button>
            </li>

            {showPaginationNumbers && <Paginator totalPages={pageCount} />}

            <li className={`paginate_button page-item next ${!table.getCanNextPage() && 'disabled'}`}>
              <button
                aria-controls="kt_datatable_column_rendering"
                className={`page-link`}
                disabled={!table.getCanNextPage()}
                onClick={() => table.nextPage()}
              >
              <i className="bi bi-chevron-right"></i>
              </button>
            </li>
            <li className={`paginate_button page-item next ${!table.getCanNextPage() && 'disabled'}`}>
              <button
                aria-controls="kt_datatable_column_rendering"
                className={`page-link`}
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              >
              <i className="bi bi-chevron-double-right"></i>
              </button>
            </li>
          </ul>

        </div>
        <div className='text-sm d-flex align-items-center justify-contents-center text-gray-700 gap-2'>
          <span>Show</span>
          <select
            className='rounded border-secondary'
            value={pagination.pageSize}
            onChange={(e) => {
              setPagination({ pageIndex: 0, pageSize: Number(e.target.value) })
              onPageSizeChangeCallback(Number(e.target.value))
            }}
          >
            {[10, 20, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span>
            entries.{' '}
            {`Showing ${numStart} to ${totalData < numEnd ? totalData : numEnd
              } of ${totalData} entries`}
          </span>
        </div>
      </div>
    </>
  )
}
