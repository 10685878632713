import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getGeofenceList, addGeofence, deleteGeofenceById, getGeofencebyId, getGeofenceLogs, setActiveGeofence, getGeofence } from '../api/geofence'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Base, BaseQuery } from '../models/base'
import { GeofenceType } from '../models/geofence'
import { useGetListData } from './helpers/useGetData'

export const _KEY = 'geofence-list'

export const useGetGeofence = () => {
  return useQuery([_KEY], getGeofence, { staleTime: 300000 })
}

export const useGetGeofenceList = (query: BaseQuery) => {
  return useGetListData({
    queryFn: getGeofenceList,
    queryKey: _KEY,
    query,
  })
}

export const useAddGeofence = () => {
  const queryClient = useQueryClient()
  return useMutation(addGeofence, {
    onSuccess: () => {
      toast.success('Geofence Berhasil Ditambahkan')
      queryClient.invalidateQueries([_KEY])
    },
    onError: (err: AxiosError<Base<GeofenceType>>) => {
      toast.error(`${err.response?.data.message}`)
      console.error(err)
    },
  })
}

export const useDeleteGeofence = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id }: { id: string }) => {
      const result = await deleteGeofenceById(id)
      return result
    },
    {
    onSuccess: () => {
      toast.success('Geofence berhasil dihapus')
      queryClient.invalidateQueries([_KEY])
    },
    onError: (err: AxiosError<Base<GeofenceType>>) => {
      toast.error('Geofence gagal dihapus')
      queryClient.invalidateQueries([_KEY])
    },
  })
}

export const useGetGeofenceRow = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id }: { id: string }) => {
      const result = await getGeofencebyId(id)
      return result.data
    },
   
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_KEY])
      },
      onError: (err: AxiosError<Base<GeofenceType>>) => {
        queryClient.invalidateQueries([_KEY])
      }
    }
  )
}


export const useGetGeofenceById = (id: string) => {
  return useQuery([_KEY, id],
    async () => {
      return await getGeofencebyId(id)
    })
}


export const useSetActiveGeofenceById = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }: { id: string }) => {
      if(id) {
        return await setActiveGeofence(id);
      } else {
        return null;
      }
    },
    {
    onSuccess: () => {
      toast.success('Geofence Berhasil Diaktivasi')
      queryClient.invalidateQueries([_KEY]);
      
    },
    onError: (err: AxiosError<Base<GeofenceType>>) => {
      toast.error('Geofence Gagal Diaktivasi');
      queryClient.invalidateQueries([_KEY])
    },
  })
}

export const useGetAllGeofence = (id: number, ) => {
return useQuery([_KEY],
  async () => {
    return await getGeofence();
  })
}


export const useGetGeofenceLogs = (id: number, date: string,hajj_info: string, search: any) => {
  return useQuery([_KEY, id, date, search],
    async () => {
      return await getGeofenceLogs(id, date,hajj_info, search)
    })
}

