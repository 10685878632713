import axios from "axios";
const Endpoint = "/api/device_personal_info";

/**
 * Fungsi untuk melakukan multi-delete jamaah ke backend.
 *
 * @param {string[]} selectedIds - Array dari ID jamaah yang akan dihapus.
 * @returns {Promise<any>} - Promise yang akan menyelesaikan respons dari API.
 * @throws {Error} - Jika terjadi kesalahan saat memanggil API.
 */

export const multiDeleteJamaahAPI = async (selectedIds: number[]) => {

    try {
        let data = {
            device_personal_info_ids: selectedIds
        }
        const res = await axios.delete(`${Endpoint}/selection`, { data});
        return res.data;
    } catch (error) {
        throw error;
    }
};


