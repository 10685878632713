// Di dalam komponen LogGeofence.tsx

import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { KTCard, KTIcon } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import DatePicker from "./component/DatePicker";
import { useGetGeofenceById, useGetGeofenceLogs } from "../../hooks/geofence";
import BadgeAlarm from "./component/BadgeAlarm";
import { GeofenceLogType } from "../../models/geofence";
import moment from "moment";
import GeofenceLogModal from "./component/GeofenceLogModal";
import { dateFormat } from "../../utils";
import { useAppContext } from "../../../_metronic/helpers/AppContext";
import { PersonalInfoType } from "../../models/personalInfo";

interface Props {
  lat: number;
  lng: number;
}

const LogGeofence = () => {
  const [search, setSearch] = useState<string>("");
  const idGeofence = useParams().id;
  const [dateTime, setDateTime] = useState<Date | null | undefined>();
  const { data: geofenceLogList } = useGetGeofenceLogs(
    parseInt(idGeofence ?? ""),
    dateTime ? moment(dateTime).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
    "",
    search
  );
  const { data: listGeofenceById } = useGetGeofenceById(idGeofence ?? "");
  console.log(listGeofenceById);
  const [modaLatLng, setModalLatLng] = useState<Props[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { role , organizationName, subOrganizationName , nameGroup } = useAppContext();
  const [valueModal, setValueModal] = useState({
    personalInfo: {} as PersonalInfoType,
    group_id: "",
    alarmType: "",
  });

  const handleDateTime = (date: Date | null | undefined) => {
    setDateTime(date);
  };

  const handleShowModal = () => {
    setShowModal(false);
  };

  const handleResetFilter = () => {
    setDateTime(null);
    setSearch(""); 
  };

  const groupByHour = (geofenceLogList : GeofenceLogType[]) => {
    return geofenceLogList.reduce((acc : { [hour: number]: GeofenceLogType[] }, item) => {
      const hour = new Date(item.created_at).getHours(); 
      if (!acc[hour] ) {
        acc[hour] = [];
      }
      acc[hour].push(item);
      return acc;
    }, {});
  };

  const groupedLogs = groupByHour(geofenceLogList ?? []);
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
            path: "/geofence",
            description:"Geofence",
            isActive: true,
            isSeparator: false,
          },
        ]}
      >
        Konfigurasi Grup
      </PageTitle>
      <KTCard className="p-5 shadow-sm">
        <div className="card-title d-flex justify-content-between">
          <div className="card-title w-100">
            <div className="d-flex justify-content-between">
              <div >
                <h3 className="text-black-100 mt-3">
                  Log Aktifitas Jamaah
                </h3>
                <h4 className="text-black-50 fw-normal m-0 p-0">
                  {listGeofenceById?.data?.data?.name}
                </h4>
                <h4 className="text-black-50 fw-normal m-0 p-0">
                  {listGeofenceById?.data?.data?.organization_unit?.name} - {listGeofenceById?.data?.data?.sub_organization_unit?.name}
                </h4>
                <h4 className="text-black-50 fw-normal m-0 p-0">
                  {listGeofenceById?.data?.data?.group?.code}
                </h4>
              </div>
              <div >
                <Link
                  to={"/geofence"}
                  className="btn btn-secondary mt-3"
                  onClick={() =>
                    handleResetFilter()}
                    >
                      <i className="bi bi-chevron-left"></i> Kembali
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex-gap3">
              <div className="mt-2 col">
                <div className="row">
                  <div className="col">
                    <div className="d-flex align-items-center position-relative my-1 mt-8">
                      <KTIcon
                        iconName="magnifier"
                        className="fs-1 position-absolute ms-6"
                      />
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-14"
                        placeholder="Cari"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-end pt-5">
                    <DatePicker onSetDate={handleDateTime} title={"Filter"} />
                  </div>
                </div>
              </div>
              <div className="table-responsive border boreder-secondary border border-1 border-gray-500 rounded mt-5">
              <table className="table caption-top table-border-bottom margin-bottom-0 ">
                <thead>
                  <tr className="fw-bold text-body-tertiary">
                    {[
                      "#",
                      "NAMA",
                      "ID JAMAAH",
                      "JENIS ALARM",
                      "WAKTU KEJADIAN",
                      "LOKASI",
                      "",
                    ].map((item, i) => (
                      <th key={i} className="text-start ps-4 border-bottom border-gray-500">{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedLogs).reverse().map((hour, index) => (
                    <React.Fragment key={index}>
                      {/* Header hour group */}
                      <tr className="table-secondary">
                        <td colSpan={7} className="text-start fw-bold px-4 text-primary">{hour}:00</td>
                      </tr>
                      {/* row data */}
                      {groupedLogs[parseInt(hour, 10)].map((item: GeofenceLogType, i: number) => (
                        <tr className="fw-bold text-black-100" key={i}>
                          <td className="ps-4">{i + 1}</td>
                          <td>{item?.device?.personal_info?.name}</td>
                          <td>{item?.device?.personal_info?.hajj_info}</td>
                          <td><BadgeAlarm alarm={item.action} /></td>
                          <td>{dateFormat(item.created_at)}</td>
                          <td>
                            <button
                              className="btn btn-icon btn-sm btn-light-primary"
                              onClick={() => {
                                setShowModal(true);
                                setValueModal({
                                  personalInfo: item?.device?.personal_info?? {} as PersonalInfoType,
                                  alarmType: item?.action ?? "",
                                  group_id: item?.device?.personal_info?.group?.code ?? "",
                                });
                                setModalLatLng([
                                  {
                                    lng: item.last_long,
                                    lat: item.last_lat,
                                  },
                                ]);
                              }}
                            >
                              <i className="bi bi-geo-alt"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              </div>
            </div>
          </KTCard>
          <GeofenceLogModal
            latlng={modaLatLng}
            radiusLat={listGeofenceById?.data?.data?.latitude ?? 0}
            radiusLng={listGeofenceById?.data?.data?.longitude ?? 0}
            radius={listGeofenceById?.data?.data?.radius}
            personalInfo={valueModal.personalInfo}
            geofenceName={listGeofenceById?.data?.data?.name}
            geofenceStatus={listGeofenceById?.data?.data?.fence_alarm}
            group_id={valueModal.group_id}
            show={showModal}
            alarmType={valueModal.alarmType}
            onClose={handleShowModal}
          />
        </>
      );
    };
    
    export default LogGeofence;
    
