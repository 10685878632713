import { Badge, Modal } from "react-bootstrap";
import { useGetUserByID } from "../../hooks/users";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import moment from "moment";
import ListItem from "../../components/ListItem";

interface Props {
    show: boolean,
    onClose: () => void,
    id: number
}

const ModalDetailUser = ({ show, id, onClose }: Props) => {
    const { data: dataUserById } = useGetUserByID(id);
    const borderStyle = 'border-bottom border-2 border-gray-200';

    
    const getRoleName = (role: number) => ({
        1: "Superadmin",
        2: "Admin",
        3: "Supervisor",
        4: "Operator"
    }[role] || "-");
    

    return (
        <div>
            <Modal show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Informasi Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-4">
                            <div className="row m-0 p-0">
                                <div className="col-12 p-0">
                                    <div className="card-personal-info-photo">
                                        <img src={dataUserById?.photo_url ? dataUserById?.photo_url : toAbsoluteUrl('/assets/profile.jpg')} alt="User" 
                                         onError={(e) => (e.currentTarget.src = toAbsoluteUrl('/assets/profile.jpg'))} />
                                        <div className="d-flex aling-items-center">
                                            { dataUserById?.status === "Activated" ?
                                                <Badge bg="light-success" style={{ color: '#3bc72e', fontWeight: "bold" }}>
                                                    Aktif
                                                </Badge>
                                                :
                                                <Badge bg="light-danger" style={{ color: '#f0406c', fontWeight: "bold" }}>
                                                    Tidak Aktif
                                                </Badge>
                                            }
                                        </div>
                                        <div className="row mt-3">
                                            <p className="text-black-100 fw-bold">
                                                {dataUserById?.name ?? '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">    
                                    <div className="card-personal-info-photo">
                                        <div className="row mt-3">
                                            <p className="text-black-50 fw-bold text-center">
                                                Detail Akun
                                            </p>
                                            <div className="row float-left w-100 mt-3">
                                            <p className="text-black-50 fw-bold">
                                                Terakhir Aktif
                                            </p>
                                            <p className="text-black-100 fw-bold">
                                                {dataUserById?.last_active ? moment(dataUserById?.last_active).format("DD.MM.YYYY HH:mm") : '-'}
                                            </p>
                                            <p className="text-black-50 fw-bold">
                                                Akun Diperbarui
                                            </p>
                                            <p className="text-black-100 fw-bold">
                                                {dataUserById?.updated_at ? moment(dataUserById?.updated_at).format("DD.MM.YYYY HH:mm") : '-'}
                                            </p>
                                        
                                            <p className="text-black-50 fw-bold">
                                                Dibuat
                                            </p>
                                            <p className="text-black-100 fw-bold">
                                                {dataUserById?.updated_at ? moment(dataUserById?.created_at).format("DD.MM.YYYY HH:mm") : '-'}
                                            </p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="col-12">
                                <p className="fw-bold fs-6" style={{ color: '#009ff7', textDecoration: 'underline' }}>Data Pengguna</p>
                                <div className="card-personal-info-detail">
                                    <div className="d-flex flex-column w-100 border border-2 border-gray-200 rounded-2 p-6">
                                            <ListItem className={borderStyle} label="Unit Organisasi" data={dataUserById?.organization_unit?.name ? dataUserById?.organization_unit?.name : '-'} />
                                            <ListItem className={borderStyle} label="Sub Unit Organisasi" data={dataUserById?.sub_organization_unit?.name ? dataUserById?.sub_organization_unit?.name : '-'} />
                                            <ListItem className={borderStyle} label="Kloter / Group" data={dataUserById?.group?.code ? dataUserById?.group?.code : '-'} />
                                            <ListItem label="Peran" data={getRoleName(dataUserById?.role_id ?? 0)} className={borderStyle}/>
                                            <ListItem className={borderStyle} label="Nama" data={dataUserById?.name ? dataUserById?.name : '-'} />
                                            <ListItem className={borderStyle} label="Username" data={dataUserById?.username ? dataUserById?.username : '-'} />
                                            <ListItem className={borderStyle} label="Email" data={dataUserById?.email ? dataUserById?.email : '-'} />
                                            <ListItem className={borderStyle} label="Nomor Telepon" data={dataUserById?.phone_number ? dataUserById?.phone_number : '-'} />
                                            <ListItem className={borderStyle} label="Jenis Kelamin" data={dataUserById?.gender === 'male' ? 'Laki-laki' : 'Perempuan'} />
                                            <ListItem label="Detail" data={dataUserById?.detail ? dataUserById?.detail : '-'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalDetailUser