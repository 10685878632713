import axios from 'axios'
import { AddDeviceBatchType, AddDeviceType, ConfigIntervalType, DeviceType, ListDeviceHistoryRespBody, UpdateConfigIntervalRequest } from '../models/device'
import {
  Endpoint, 
  EndpointConfigRemoteDevice, 
  EndpointGetDevicesByCriteria, 
  EndpointGetDeviceByID, 
  endpointHistory, 
  EndpointConfigIntervalDevice, 
  EndpointBatch, 
  endPointUnbind
} from '../../_metronic/helpers/crud-helper/EndpointDevice'
import { Base, BaseQuery, Pagination } from '../models/base'

export const getDeviceList = async () => {
  const total = await axios.get(Endpoint);
  const res = await axios.get(`${Endpoint}?size=${total.data.data.total_rows}`);
  return res?.data?.data?.rows
}
export const getDevicesList = async (query: string = '') => {
  const res = await axios.get(Endpoint + '?' + query)
  return await res.data.data as Pagination<DeviceType[]>
}

export const getDeviceByIDList = async (id: number) => {
  const res = await axios.get(EndpointGetDeviceByID(id))
  return await res?.data.data
}

export const getDeviceByCriteria = async (query: BaseQuery) => {
  const total = await axios.get(Endpoint);
  const res = await axios.get(EndpointGetDevicesByCriteria(
    total.data.data.total_rows,query))
  return await (res?.data?.data?.rows) as DeviceType[]
}

export const configRemoteDevice= async (id: number | 0, command: string | '') => {
  const res = await axios.get(EndpointConfigRemoteDevice(id, command) || '')
  return res
}

export const addDevice = async (device: AddDeviceType) => {
  let mappedValues
  if (device?.type === "single"){
    mappedValues = {
      ...device,
      device_model_id: parseInt(device?.device_model_id ?? '0'),
      organization_unit_id: parseInt(device?.organization_unit_id ?? '0'),
    }
    delete (mappedValues?.type);
    return await axios.post<Base<DeviceType>>(Endpoint, mappedValues);
  } else {
    let data = new FormData();
    data.append('organization_unit_id', String(device?.organization_unit_id ?? '0'));
    data.append('file', device?.file as File);
    const result = await axios.post<Base<AddDeviceBatchType>>(EndpointBatch(), data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return result
  }
}

export const addDeviceBatch = async (data: AddDeviceBatchType) => {
  const resData = await axios.post<Base<AddDeviceBatchType>>(EndpointBatch(), data)
  return resData
}

export const getDeviceHistoryById = async ({ id, date }: { id: number | string; date?: string }) => {
  const query = typeof date !== 'undefined' ? `?date=${date}` : ''
  const res = await axios.get<ListDeviceHistoryRespBody>(`${endpointHistory}/${id}${query}`)
  return res.data.data
}

export const updateDeviceConfigById = async (id: string, data: UpdateConfigIntervalRequest) => {
  const res = await axios.put<Base<ConfigIntervalType>>(EndpointConfigIntervalDevice(id), data)
  return res
}

export const getDeviceConfigById = async (id: string): Promise<ConfigIntervalType> => {
  const res = await axios.get(`${EndpointConfigIntervalDevice(id)}`)
  return res.data.data as  ConfigIntervalType
}

export const deleteDeviceById = async (id: number ) => {
  const res = await axios.delete(`${Endpoint}/${id}`)
  return res
}

export const updateDevice = async (id: number, device: AddDeviceType) => {
  let mappedValues = {
    ...device,
    device_model_id: parseInt(device?.device_model_id ?? '0'),
    organization_unit_id: parseInt(device?.organization_unit_id ?? '0'),
    group_id: parseInt(device?.group_id ?? '0'),
  }
    return await axios.put<Base<AddDeviceType>>(`${Endpoint}/${id}`, mappedValues); 
}

export const unbindDeviceById = async (id: number) => {
  const res = await axios.put(`${endPointUnbind}/${id}`)
  return res
}