import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { addEditConfig, getConfigKloterByID, getConfigurationKloter } from "../api/configurationKloter"
import { AddConfigKloterType } from "../schemas/configGroup"
import { listConfigurationKloterRespData } from "../models/configurationKloter"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { BaseQuery } from '../models/base'
import { useGetListData } from './helpers/useGetData'

export const _CONF_KLOTER_LIST = 'configuration-group-list'

export const useGetConfigurationKloterList = (query : BaseQuery) => {
  return useGetListData({
    queryFn: getConfigurationKloter,
    queryKey: _CONF_KLOTER_LIST,
    query,
  })
}

export const useGetConfigurationKloterByID = (id: number) => {
  return useQuery(
    [_CONF_KLOTER_LIST, id],
    async () => {
      if (!id) return
      const data = await getConfigKloterByID(id)
      return data
    }
  )
}

export const useAddConfigKloter = () => {
  let showToast: string
  const queryClient = useQueryClient()
  return useMutation(
    async ({ command, data, id }: { command: string, data: AddConfigKloterType, id?: number }) => {
      showToast = command
      return await addEditConfig(command, data, id)
    },
    {
      onSuccess: () => {
        showToast === "create" ? toast.success('Add Configuration Success') : toast.success('Edit Configuration Success')
        queryClient.invalidateQueries([_CONF_KLOTER_LIST])
      },
      onError: (err: AxiosError<listConfigurationKloterRespData>) => {
        toast.error(`${err.response?.data.error}`)
        console.error(err)
      },
    }
  )
}