import { Link } from 'react-router-dom'
import { KTCard, KTIcon } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useGetGeofenceRow } from '../../hooks/geofence'
import Swal from 'sweetalert2'
import { Button, Spinner } from 'react-bootstrap'
import BadgeAlarm from './component/BadgeAlarm'
import GeofenceMapByID from './component/GeofenceMapByID'
import { useEffect, useState } from 'react'
import { useServerTable } from '../../components/table/ServerTable'
import { GeofenceType } from '../../models/geofence'
import { DeviceManagementFilter } from '../../modules/filter/DeviceManagementFilter'
import { useGetDeviceList } from '../../hooks/device'
import { DeviceType } from '../../models/device'
import { useAppContext } from '../../../_metronic/helpers/AppContext'
import { deleteGeofenceById, getGeofenceListQuery, setActiveGeofence } from '../../api/geofence'
import { useInView } from 'react-intersection-observer'
import { toast } from 'react-toastify'


const GeofencePage = () => {
  const pageSize = 25;
  const {
    filterValue,
    setFiltering,
  } = useServerTable();
  const [ selectedEmbarkation, setSelectedEmbarkation ] = useState<number>(0);
  const [ selectedGroup, setSelectedGroup ] = useState<number>(0);
  const [ selectedStatus, setSelectedStatus] = useState<string>('');
  const [ checkDisabilityStatus, setCheckDisabilityStatus] = useState<string>('');
  const [ checkElderyStatus, setCheckElderyStatus] = useState<string>('');
  const [ checkRistiStatus, setCheckRistiStatus] = useState<string>('');
  const [ checkMale, setCheckMale] = useState<string>('');
  const [ checkFemale, setCheckFemale ] = useState<string>('');
  const [ selectedOrganizationId, setSelectedOrganizationId ] = useState<number>(0);
  const [ selectedGroupId, setSelectedGroupId ] = useState<number>(0);
  const [ selectedSubOrganizationId, setSelectedSubOrganizationId ] = useState<number>(0);
  const { role , organizationName, subOrganizationName, nameGroup } = useAppContext();
  const [sorting , setSorting] = useState<{id: string; desc: boolean}>({id: 'created_at', desc: false});
  const [pageIndex, setPageIndex] = useState(0);
  const {inView, ref} = useInView()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [reachedEnd, setReachedEnd] = useState(false)

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  })
  const [geofenceList, setGeofenceList] = useState<GeofenceType[]>([]);
  const query = {
      q: filterValue,
      pageIndex,
      pageSize,
      organization_unit_id: selectedOrganizationId,
      sub_organization_unit_id : selectedSubOrganizationId,
      sortBy: `created_at`,
      embarkation_id: selectedEmbarkation,
      group_id: selectedGroupId,
  }

  const { data: deviceList } = useGetDeviceList({
    status : selectedStatus,
    embarkation_id : selectedEmbarkation,
    disability_status : checkDisabilityStatus,
    elderly_status : checkElderyStatus,
    status_risti : checkRistiStatus,
    genderMale : checkMale,
    genderFemale : checkFemale,
    organization_unit_id: selectedOrganizationId,
    group_id : selectedGroupId,
    sub_organization_unit_id : selectedSubOrganizationId
  });

  const handleSortingChange = (newSorting: string) => {
    if (newSorting === sorting?.id) {
      setSorting({ id: newSorting, desc: !sorting.desc });
    } else {
      setSorting({ id: newSorting, desc: false });
    }
  };

  const filteredDeviceList = deviceList?.filter((device: DeviceType) => device?.last_long !== 0 && device?.last_lat !== 0);

  useEffect(() => {
    if (geofenceList) {
      const firstGeofence = geofenceList[0];
      handleShowGeofence(firstGeofence);
    }
  },[geofenceList])

  useEffect(() => {
    if (inView && !isLoadingMore && !reachedEnd && geofenceList.length > 0) {
      getMoreGeofenceList();
    }
    if (!inView) {
      setReachedEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inView])

  const getNewGeofenceList = async () => {
    setPageIndex(0);
    const data = await getGeofenceListQuery({...query , pageIndex: 0});
    setGeofenceList(data?.rows)
    setReachedEnd(false)
    setIsLoadingMore(false)
  }

  const getMoreGeofenceList =  () => {
    setIsLoadingMore(true)
    setTimeout( async () => {
    const data = await getGeofenceListQuery({...query, pageIndex: pageIndex + 1});
    if (data) {
        setGeofenceList([...geofenceList, ...data?.rows])
        setPageIndex(pageIndex + 1)
      } else {
        console.log('reached end', pageIndex)
        setReachedEnd(false)
        setIsLoadingMore(false)
      }
    }, 500)
    setIsLoadingMore(false)
  }

  useEffect(() => {
    const data = geofenceList;
    if (sorting.id === 'name') {
      if (sorting.desc) {
        data.sort((a, b) => (a.name > b.name ? -1 : 1));
      } else {
        data.sort((a, b) => (a.name < b.name ? -1 : 1));
      }
    }
    setGeofenceList(data);
  }, [sorting])

  useEffect(() => {
    getNewGeofenceList()
  }, [selectedOrganizationId, selectedSubOrganizationId, selectedGroupId, filterValue])

  const { data: geofenceRow, mutate: setGeofenceRow } = useGetGeofenceRow();
  const [selectedGeofence, setSelectedGeofence] = useState<GeofenceType>();
  const [titleGeofence, setTitleGeofence] = useState<string>("")
  const handleShowGeofence = (geofence: GeofenceType) => {
    setSelectedGeofence(geofence)
    setTitleGeofence(geofence?.name)
    setCenter({ lat: geofence?.latitude, lng: geofence?.longitude })
  }

  const handleDeleteGeofence = async (geofence: GeofenceType) => {
    setGeofenceRow({ id: geofence?.id?.toString() })

  if (geofence?.id) {
    Swal.fire({
      title: 'Hapus',
      text: `Apakah Andaha Yakin Ingin Menghapus Geofence ${geofence.name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e60026',
      cancelButtonColor: '#dcdcdc',
      cancelButtonText: 'Kembali',
      confirmButtonText: 'Ya, Hapus',
    }).then( async(result) => {
      if (result.isConfirmed) {
        const result = await deleteGeofenceById( geofence?.id?.toString() )
        if (!result) {
          toast.success('Geofence Berhasil Dihapus')
        } else {
          toast.error('Geofence Gagal Dihapus')
        }
        setGeofenceRow({ id: '' })
        getNewGeofenceList()
      }
    })
  }

}
  const handleToggleActivation = async ( id: number , is_active: boolean) => {
        try {
          await setActiveGeofence(String(id));
          getNewGeofenceList();
          if (!is_active) {
            toast.success('Geofence Berhasil Diaktifkan')
          }
        } catch (error) {
          toast.error("Tidak dapat menonaktifkan geofence")
        } 
};

  return (
    <>
      <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path: '/geofence',
                description: 'Geofence',
                isActive: true,
                isSeparator: false
            }]}>Konfigurasi Grup</PageTitle>
      <KTCard className='p-5 shadow-sm'>
        <div className='card-title d-flex justify-content-between'>
          <div
            data-bs-target='#kt_account_profile_details'
            aria-controls='kt_account_profile_details'
          >
              <h3 className='m-0 fs-6'>Maps Geofence</h3>
              <h3 className='m-0 mt-2 fs-6 text-gray-600'>{titleGeofence}</h3>
          </div>
          <div className=''>
            <DeviceManagementFilter
            onChangeEmbarkation={(embarkation: number) => setSelectedEmbarkation(embarkation)}
            onChangeGroup={(group: number) => setSelectedGroup(group)}
            embarkation={selectedEmbarkation}
            group={selectedGroup}
            onChangeStatus={(status: string) => setSelectedStatus(status)}
            onChangeDisabilityStatus={(status: string) => setCheckDisabilityStatus(status)}
            onChangeOrganizationUnitId={(organizationUnitId: number) => setSelectedOrganizationId(organizationUnitId)}
            onChangeSubOrganizationId={(subOrganizationId: number) => setSelectedSubOrganizationId(subOrganizationId)}
            onChangeGroupId={(groupId: number) => setSelectedGroupId(groupId)}
            onChangeGenderMale={(male: string) => setCheckMale(male)}
            onChangeGenderFemale={(female: string) => setCheckFemale(female)}
            onChangeElderlyStatus={(elderly_status: string) => setCheckElderyStatus(elderly_status)}
            onChangeStatusRisti={(status_risti: string) => setCheckRistiStatus(status_risti)}
            status={selectedStatus}
            disability_status={checkDisabilityStatus}
            organizationUnitId={selectedOrganizationId}
            subOrganizationId={selectedSubOrganizationId}
            groupId={selectedGroupId}
            genderMale={checkMale}
            genderFemale={checkFemale}
            elderly_status={checkElderyStatus}
            status_risti={checkRistiStatus}
            hiddenEmbarkation={true}
            hiddenAvailability={true}
            hiddenRole={true}
            hiddenCategory={true}
            hiddenAlarmType={true}
            hiddenDate={true}
            hiddenOrganizationUnitId={role!==1}
            hiddenSubOrganizationId={role!==1 && role!==2}
            hiddenGroupId={role===4}
            />
            {role !==1 && role !==2 && (
              <Link
              to={"/geofence/add"}
              className={'btn btn-primary me-3'}
            >
              <KTIcon iconName='bi bi-plus-circle-dotted' className='fs-2' />
              Tambah
            </Link>
            ) }
          </div>
        </div>
        <div className='d-flex gap-3'>
          <div className='mt-4 col'>
            <GeofenceMapByID geofence={selectedGeofence} devices={filteredDeviceList} center={center} setCenter={setCenter} />
            <h3 className='m-0 fs-6 mt-8 mb-4'>Daftar Geofence</h3>
            <div className='d-flex align-items-center position-relative my-1 '>

              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Cari'
                onChange={(e) => setFiltering(e.target.value)}
              />
            </div>
            <div className="border border-secondary border border-1 border-gray-500 rounded mt-5">
            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
              <table className="table caption-top table-border-bottom margin-bottom-0">
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                  <tr className="fw-bold text-body-tertiary">
                    <th className="text-start ps-4 border-bottom border-gray-500">#</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-nowrap" onClick={() => handleSortingChange('name')} >NAMA GEOFENCE {sorting?.id === 'name' ? <i className={`bi bi-caret-${sorting?.desc ? 'up' : 'down'}`}></i> : <i className="bi bi-caret-down"></i>}</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-nowrap" hidden={role!==1} >ORGANISASI UNIT </th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-nowrap" hidden={role!==1 && role!==2} >SUB ORGANISASI</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-nowrap" hidden={role === 4}>GRUP / KLOTER</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-nowrap">JENIS ALARM</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 ">RADIUS</th>
                    <th className="text-start ps-4 border-bottom border-gray-500 text-center">KONTROL</th>
                  </tr>
                </thead>
                <tbody>
                  {geofenceList?.map((geofence, index) => (
                    <tr key={geofence?.id} onClick={() => handleShowGeofence(geofence)} className={`${selectedGeofence?.id === geofence?.id ? 'table-primary' : ''} cursor-pointer`}>
                      <td className="fw-bold text-center">{index + 1}</td>
                      <td className="fw-bold text-break mw-25">{geofence?.name}</td>
                      <td className="fw-bold" hidden={role!==1}>{geofence?.organization_unit?.name}</td>
                      <td className="fw-bold" hidden={role!==1 && role!==2}>{geofence?.sub_organization_unit?.name}</td>
                      <td className="fw-bold" hidden={role === 4}>{geofence?.group?.code}</td>
                      <td className="fw-bold">{<BadgeAlarm alarm={geofence.fence_alarm} />}</td>
                      <td className="fw-bold">{Number(geofence?.radius) < 1000 ? `${Number(geofence?.radius)} m` : `${(Number(geofence?.radius) / 1000).toFixed(0)} km`}</td>
                      <td className="fw-bold">
                      <div className="d-flex flex-row align-items-center justify-content-center">
                        <div style={{ marginRight: '5px', padding: '0' }} className={`${role === 1 || role === 2 ? 'd-none' : ''}`} onClick={() => getNewGeofenceList()}>
                          <label style={{cursor: 'pointer'}} className="switch">
                              <input type="checkbox" id={String(geofence?.id)} checked={geofence?.is_active} onClick={() => handleToggleActivation(geofence?.id, geofence?.is_active)} readOnly />
                              <span className="slider round"></span>
                          </label>
                        </div>
                        <Link
                          to={"/geofence/log/" + geofence?.id}
                          className={'btn btn-icon btn-light-info btn-sm mx-1'}
                        >
                          <KTIcon iconName='bi bi-people' className='fs-2' />
                        </Link>
                        <Button
                          onClick={() => {
                            handleDeleteGeofence(geofence)
                          }}
                          size='sm'
                          className={`btn btn-icon  btn-sm mx-1 ${geofence?.is_active ? ' btn-secondary' : 'btn-light-danger'}`}
                          hidden={role === 1 || role === 2 }
                          disabled={geofence?.is_active}
                        >
                          <i className='bi bi-trash fs-4'></i>
                        </Button>
                      </div>
                      </td>
                    </tr>

                    
                  ))}
                    <div className=' p-0 m-0 pb-1' ref={ref}>
                    {isLoadingMore && (
                      <div className='d-flex justify-content-center items-center gap-3'>
                      <Spinner animation="border" role="status">
                      </Spinner> 
                      <p>Loading...</p>
                      </div>
                    )}
                  </div>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </KTCard>
    </>
  )
}

export default GeofencePage
