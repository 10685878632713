import React from "react";
import { useGetDataDashboard } from "../../../../app/hooks/dashboard";
import { CustomPieChart } from "../../../../app/components/CustomPieChart";

type Props = {
  className: string
}

export const PieCharts3: React.FC<Props> = ({className}) => {
  const { data : dataDashboard } = useGetDataDashboard();
  const totalMaleRistiPersonalInfo = dataDashboard?.personal_info.risti_personal_info_male ?? 0;
  const totalFemaleRistiPersonalInfo = dataDashboard?.personal_info.risti_personal_info_female ?? 0;
  const totalPersonalInfo = (totalMaleRistiPersonalInfo + totalFemaleRistiPersonalInfo);
  const percentageRistiMale = ((totalMaleRistiPersonalInfo / totalPersonalInfo) * 100).toFixed(2);
  const percentageRistiFemale = ((totalFemaleRistiPersonalInfo / totalPersonalInfo) * 100).toFixed(2);

  const data = [
    {
      id: 1,
      value: totalMaleRistiPersonalInfo ?? 0,
      name: `Laki-laki`,
      percentageValue: `${!isNaN(parseInt(percentageRistiMale)) ? percentageRistiMale : 0}%`
    },
    {
      id: 2,
      value: totalFemaleRistiPersonalInfo ?? 0,
      name: `Perempuan`,
      percentageValue: `${!isNaN(parseInt(percentageRistiFemale)) ? percentageRistiFemale : 0}%`
    }
  ]

  return (
    <CustomPieChart data={data} totalData={totalPersonalInfo} type='risti'/>
  );
}