import { Modal } from "react-bootstrap"
import "./reddot.css"
import GeofenceMapLogs from "./GeofenceMapLogs"
import { RowCoordinate } from "../../../components/RowCoordinate"
import { PersonalInfoType } from "../../../models/personalInfo"
interface Props {
    lat: number
    lng: number
}
const GeofenceLogModal: React.FC<{
    personalInfo: PersonalInfoType
    latlng : Props[]
    show: boolean
    radiusLat: number
    radiusLng: number
    radius: number
    geofenceName: string
    geofenceStatus: string
    group_id: string
    alarmType: string
    onClose: () => void
}> = ({ latlng, personalInfo, show, radiusLat, radiusLng, radius, geofenceName, geofenceStatus, group_id, alarmType, onClose }) => {
    return (
        <>
            <Modal show={show} onHide={onClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Lokasi Terakhir - {personalInfo.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-2">
                            <h5 className="text-black-50">NAMA GEOFENCE</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-50">RADIUS</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-50">JENIS ALARM</h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-50">GRUP / KLOTER</h5>
                        </div>
                        <div className="col-3">
                            <h5 className="text-black-50">KOORDINAT</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <h5 className="text-black-109">
                                {geofenceName}
                            </h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-109">
                            {/* {(Number(radius) / 1000).toFixed(2)} KM */}
                            { (Number(radius)) > 1000 ? (Number(radius) / 1000).toFixed(0) + " KM" : Number(radius) + " M" }
                            </h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-109">
                                {alarmType === "in" && "Masuk Zona" }
                                {alarmType === "out" && "Keluar Zona" }
                                {alarmType === "inout" && "Masuk Keluar Zona" }
                            </h5>
                        </div>
                        <div className="col-2">
                            <h5 className="text-black-109">
                                {group_id}
                            </h5>
                        </div>
                        <div className="col-3">
                            <h5 className="text-black-109">
                                <RowCoordinate position={latlng[0]} />
                            </h5>
                        </div>
                    </div>
                    <div className="row mt-5">
                    <GeofenceMapLogs
                        data={latlng}
                        radiusLat={radiusLat}
                        radiusLng={radiusLng}
                        radius={radius}
                        center={{ lat: latlng[0]?.lat, lng: latlng[0]?.lng }}
                        personalInfo={personalInfo}
                    />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GeofenceLogModal