interface IFormHeader {
  title: string
  subtitle?: string
}

export const FormHeader = ({title, subtitle = ''}: IFormHeader) => {
  return (
    <div
      className='card-header border-0 mt-6'
      data-bs-target='#kt_account_profile_details'
      aria-expanded='true'
      aria-controls='kt_account_profile_details'
    >
      <div className='card-title m-0 d-flex flex-column'>
        <h3 className='fw-bolder m-0'>{title}</h3>
        {subtitle !== '' && <p className='text-black-50 fs-6'>{subtitle}</p>}
      </div>
    </div>
  )
}
