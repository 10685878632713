import { useState } from 'react';
import { KTCard } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { useGetDeviceList } from '../../hooks/device';
import { DeviceManagementFilter } from '../../modules/filter/DeviceManagementFilter';
import  Notification from './component/Notification';
import { DeviceType } from '../../models/device';
import { useAppContext } from '../../../_metronic/helpers/AppContext';
import MapBoxGL from '../../components/map-box/MapBoxGl';
import { Marker, Popup } from 'react-map-gl';
import PopPupDevice from '../../components/map-box/PopPupDevice';

export default function TrackingMapsPage() {
  const {role, organizationName, subOrganizationName, nameGroup } = useAppContext()
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedEmbarkation, setSelectedEmbarkation] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [checkDisabilityStatus, setCheckDisabilityStatus] = useState<string>('');
  const [checkElderyStatus, setCheckElderyStatus] = useState<string>('');
  const [checkRistiStatus, setCheckRistiStatus] = useState<string>('');
  const [checkMale, setCheckMale] = useState<string>('');
  const [checkFemale, setCheckFemale] = useState<string>('');
  const [selectedOrganizationId, setSelectedOrganizationId ] = useState<number>(0);
  const [selectedGroupId, setSelectedGroupId ] = useState<number>(0);
  const [selectedSubOrganizationId, setSelectedSubOrganizationId ] = useState<number>(0);
  const [selectedGroup, setSelectedGroup] = useState<number>(0);

  const { data: deviceList } = useGetDeviceList({
    status : selectedStatus,
    embarkation_id : selectedEmbarkation,
    category : selectedCategory,
    disability_status : checkDisabilityStatus,
    elderly_status : checkElderyStatus,
    status_risti : checkRistiStatus,
    genderMale : checkMale,
    genderFemale : checkFemale,
    organization_unit_id: selectedOrganizationId,
    group_id : selectedGroupId,
    sub_organization_unit_id : selectedSubOrganizationId
  });

  const filteredDeviceList = deviceList?.filter((device: DeviceType) => device?.last_long !== 0 && device?.last_lat !== 0);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  let circleBeep = "circleBeepGreen";

  const handleMarkerClick = (device: DeviceType, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    setSelectedDevice(device);
  };


  return (
    <>
      <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path: '/tracking',
                description: '',
                isActive: true,
                isSeparator: false
            }]}>Pusat Monitoring</PageTitle>
      <KTCard className='p-8'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='gap-2'>
            <h5>Jumlah Perangkat : {filteredDeviceList?.length}</h5>
          </div>
          <div className='d-flex align-items-center gap-2'>
            <div>
              <DeviceManagementFilter
                    onChangeEmbarkation={(embarkation: number) => setSelectedEmbarkation(embarkation)}
                    onChangeGroup={(group: number) => setSelectedGroup(group)}
                    onChangeCategory={(category: string) => setSelectedCategory(category)}
                    onChangeDisabilityStatus={(disability_status: string) => setCheckDisabilityStatus(disability_status)}
                    onChangeElderlyStatus={(eldery_status: string) => setCheckElderyStatus(eldery_status)}
                    onChangeStatusRisti={(status_risti: string) => setCheckRistiStatus(status_risti)}
                    onChangeGenderFemale={(female: string) => setCheckFemale(female)}
                    onChangeGenderMale={(male: string) => setCheckMale(male)}
                    onChangeOrganizationUnitId={(organizationUnitId: number) => setSelectedOrganizationId(organizationUnitId)}
                    onChangeGroupId={(groupId: number) => setSelectedGroupId(groupId)}
                    onChangeSubOrganizationId={(subOrganizationId: number) => setSelectedSubOrganizationId(subOrganizationId)}
                    onChangeStatus={(status: string) => setSelectedStatus(status)}
                    embarkation={selectedEmbarkation}
                    group={selectedGroup}
                    groupId={selectedGroupId}
                    category={selectedCategory}
                    elderly_status={checkElderyStatus}
                    status_risti={checkRistiStatus}
                    disability_status={checkDisabilityStatus}
                    genderMale={checkMale}
                    genderFemale={checkFemale}
                    organizationUnitId={selectedOrganizationId}
                    subOrganizationId={selectedSubOrganizationId}
                    status={selectedStatus}
                    hiddenAvailability={true}
                    hiddenEmbarkation={true}
                    hiddenRole={true}
                    hiddenDate={true}
                    hiddenAlarmType={true}
                    hiddenOrganizationUnitId={role !== 1}
                    hiddenCategory={true}
                    hiddenSubOrganizationId={role !== 1 && role !== 2}
                    hiddenGroupId={role !== 1 && role !== 2 && role !== 3}
              />
            </div>
          </div>
        </div>
        <div className='w-100 h-500px mt-5'>
          <MapBoxGL searchBox >
            {filteredDeviceList?.map((device: DeviceType, idx: number) => {
              const devicePosition = {
                lat: device.last_lat ?? 0,
                lng: device.last_long ?? 0
              };
              circleBeep = device.handle_state === "sos" ? "circleBeepRed" : device.handle_state === "in_progress" ? "circleBeepYellow" : "circleBeepGreen"
              return (
                <div key={idx}>
                <Marker
                  latitude={devicePosition.lat}
                  longitude={devicePosition.lng}
                  anchor="bottom"
                >
                <div className={`${circleBeep}` }
                    onClick={(e) => handleMarkerClick(device, e)}
                >
                {selectedDevice?.id === device?.id && (
                  <Popup
                  anchor="top-left"
                  longitude={devicePosition.lng}
                  latitude={devicePosition.lat}
                  onClose={() => setSelectedDevice(null)}
                  style={{ maxWidth: 'none', width: 'auto', padding: '0', margin: '0' }}         
                >
                  <PopPupDevice device={device} />
                </Popup>
                )}

                </div>
                </Marker>
              </div>
              );
            })}
         </MapBoxGL>
        </div>
      </KTCard>
      <Notification />
    </>
  );
}