import "./iconStyle.css";

type Props = {
    status: string
    type: string
}

const IconNotification = ({ status}: Props) => {

    let classBackgroundColor = "red-circle";
    let iconType = "bi bi-chat-left-fill";
    let badgeStatus = "bi bi-person-fill";

     //define icon to show
     if (status === 'geofence_in' || status === 'geofence_out') {
        iconType = "bi bi-geo-fill";
    }
     // Define  badge and color 
     if (status === 'geofence_in') {
        classBackgroundColor = "green-circle";
        badgeStatus = "bi bi-arrow-right";
    }
    else if (status === 'geofence_out') {
        classBackgroundColor = "red-circle";
        badgeStatus = "bi bi-arrow-left";
    } else if (status === 'in_progress') {
        classBackgroundColor = "yellow-circle";
        badgeStatus = "bi bi-clock";
    } else if (status === 'done') {
        badgeStatus = "bi bi-check2";
        classBackgroundColor = "green-circle";
    }
    return (
        <>
            <div className="container-icon">
                <div className={classBackgroundColor}>
                    <i 
                    className={badgeStatus}
                    style={{ 
                        marginLeft: '3px', 
                        fontSize: '14px', 
                        color: 'white'}}
                    ></i>
                </div>
                <div className="gray-circle">
                    <i 
                    className={iconType}
                    style={{fontSize: '19px', color: 'white'}}
                    ></i>
                </div>
            </div>
        </>
    );
};

export default IconNotification
