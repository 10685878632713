import axios from "axios"
import { ChangePasswordType, ProfileType } from "../models/profile"
const endpoint = 'api/users'
const endpointPassword = '/api/private/auth'

export const getProfile = async () => {
  const res = await axios.get(`${endpoint}/my_profile`)
  return res.data.data
}

export const updateProfile = async (data: ProfileType) => {
  const res = await axios.put(`${endpoint}/${data.id}`, data)
  return res.data.data
}

export const changePassword = async (data: ChangePasswordType) => {
  const res = await axios.post(`${endpointPassword}/change_password`, data)
  return res.data.data
}