import { SignJWT } from "jose";
import { LoginType } from "../models/auth";

const KUNCI = process.env.REACT_APP_JWT_SECRET_KEY

export const createSignature = async (data : LoginType, client : string) => {
    const identifier = data.email;
    const password = data.password;
    const payload = {
      identifier,
      password,
      client
    }
    try {
    const token = await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(new TextEncoder().encode(KUNCI))
    return token
    } catch (error) {
      console.log(error)
    }
  } 