import { BaseQuery } from "../../../app/models/base"

const Endpoint = '/api/devices'
const EndpointConfig = '/api/device'
const endpointHistory = '/api/device_history'
const endPointUnbind = '/api/device_personal_info/unbind_device'

const EndpointConfigIntervalDevice = (id : string) => {
    return `${EndpointConfig}/${id}/configuration`
}

const EndpointBatch = () => {
    return `${Endpoint}/batch`
}

const EndpointGetDeviceByID = (id : number) => {
    return `${Endpoint}/${id}`
}
const EndpointConfigRemoteDevice = (id : number, command : string) => {
    if (command === 'RESET') {
        return `${EndpointConfig}/${id}/configuration/RESET`

    } else if (command === 'POWEROFF') {
        return `${EndpointConfig}/${id}/configuration/POWEROFF`
    } else if (command === 'CR') {
        return `${EndpointConfig}/${id}/configuration/CR`
    }
}


const EndpointGetDevicesByCriteria = (total_rows : string, query : BaseQuery) => {
        const {status, embarkation_id, category, disability_status, elderly_status, status_risti, genderMale, genderFemale, organization_unit_id, group_id, sub_organization_unit_id} = query
        let strQuery = ''
        if (total_rows) strQuery += `size=${total_rows}&`
        if (status) strQuery += `handle_state=${status}&`
        if (embarkation_id) strQuery += `embarkation_id=${embarkation_id}&`
        if (group_id) strQuery += `group_id=${group_id}&`
        if (category) strQuery += `category=${category}&`
        if (elderly_status) strQuery += `elderly_status=${elderly_status}&`
        if (status_risti) strQuery += `status_risti=${status_risti}&`
        if (disability_status) strQuery += `disability_status=${disability_status}&`
        if (organization_unit_id) strQuery += `organization_unit_id=${organization_unit_id}&`
        if (sub_organization_unit_id) strQuery += `sub_organization_unit_id=${sub_organization_unit_id}&`
        let gender
        if (genderMale === '0' && genderFemale === '0') {
        gender = '';
        } 
        if (genderMale === '0' && genderFemale === '1') {
        gender = 'female';
        } 
        if (genderMale === '1' && genderFemale === '0') {
        gender = 'male';
        } 
        if (genderMale === '1' && genderFemale === '1') {
        gender = '';
        }
        if (genderMale === '1' && !genderFemale) {
        gender = 'male'
        }
        if (!genderMale && genderFemale === '1') {
        gender = 'female'
        }
        if (gender) strQuery += `gender=${gender}&` 
    return `${Endpoint}?${strQuery}`;
}

export {
    Endpoint, 
    EndpointConfig, 
    endpointHistory,
    endPointUnbind, 
    EndpointGetDevicesByCriteria, 
    EndpointGetDeviceByID, 
    EndpointConfigRemoteDevice,
    EndpointConfigIntervalDevice,
    EndpointBatch
}