import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import { CustomMessagePayload } from '../models/notification';
import registerToken from '../api/registerToken';
const firebaseConfig = require(`../../config_files/${process.env.REACT_APP_ENVIRONMENT === 'main' ? 'firebase-dev.json' : 'firebase-demo.json'}`);

initializeApp(firebaseConfig)
export const messaging = getMessaging()

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
    if (currentToken) {
      console.log('current token for client: ', currentToken)
      // Perform any other neccessary action with the token
      registerToken({ token: currentToken, user_id: 123 }) //TODO : Change user id later
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.')
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ')
  }
}

export const onMessageListener = () : Promise<CustomMessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      return resolve(payload as CustomMessagePayload)
    })
  })
