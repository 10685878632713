import { forwardRef, useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import "./datepicker.css"

interface Props {
    onSetDate: (date: Date | null | undefined) => void
    title?: string
}

const DatePicker = ({ onSetDate, title }: Props) => {
    const formattedDate = new Date()
    const [selectedDate, setSelectedDate] = useState<Date | undefined | null>()
    const [datepickerOpen, setDatepickerOpen] = useState(false)


    const CustomButton = forwardRef(({ onClick }: any, ref: any) => (
        <Button variant="light-primary" onClick={() => {
            onClick();
            setDatepickerOpen(true);
        }} ref={ref}>
            <i className="bi bi-calendar-date"></i> {title ? title : "Atur Waktu"}
        </Button>
    ));


    const handleToday = () => {
        setSelectedDate(new Date())
        onSetDate(new Date())
        setDatepickerOpen(false)
    }

    const CustomInputTime = () => (
        <div className="row pe-7" style={{ width: "250px" }}>
            <div className="col-12 d-flex">
                <button className="btn btn-primary w-100" onClick={() => handleToday()}>Hari Ini</button>
            </div>
        </div>
    )
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
        onSetDate(date)
    }

    useEffect(() => {
        setSelectedDate(selectedDate)
        onSetDate(selectedDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSetDate])
    return (
        <>
            <ReactDatePicker
                calendarClassName="custom-datepicker"
                selected={selectedDate}
                onChange={handleDateChange}
                startDate={formattedDate}
                open={datepickerOpen}
                onCalendarClose={() => setDatepickerOpen(false)}
                popperPlacement="bottom"
                popperClassName="date-picker-container"
                showTimeInput
                timeInputLabel=""
                fixedHeight
                customInput={<CustomButton />}
                customTimeInput={<CustomInputTime />}
                shouldCloseOnSelect={false}
            />
        </>

    )
}

export default DatePicker