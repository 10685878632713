import React from "react";
import { Chart } from "react-google-charts";
import { useGetDataDashboard } from "../../../../app/hooks/dashboard";
import { Card } from "react-bootstrap";

type Props = {
  className: string;
};

export const DataSekolah = {
  title: 'Data Pendidikan Terakhir',
  textPosition: 'out',
  legend: 'none',
  titleTextStyle: {
    fontSize: 16,
    bold: false,
  },
  chartArea: { width: '60%' },
  series: {
    0: { color: '#019EF6' },
  },
  annotations: {
    alwaysOutside: true,
    textStyle: {
      fontSize: 10,
      color: '#000',
    },
    stem: {
      color: 'none',
    },
  },
  hAxis: {
    minValue: 0,
    textPosition: 'out',
    textStyle: {
      fontSize: 10,
      color: '#000',
      italic: false,
      bold: false,
    },
    format: '0',
  },
  vAxis: {
    textPosition: 'out',
    textStyle: {
      fontSize: 10,
      color: '#000',
      italic: false,
    },
  },
}

export const ChartsWidget6: React.FC<Props> = ({ className }) => {
  const { data: dataDashboard } = useGetDataDashboard();
  const dataEnam = [
    ["Pendidikan", "Total Jamaah", { role: "annotation" , textPosition: 'out' , textStyle: { fontSize: 8 } }],
    ["SD", dataDashboard?.personal_info_by_last_education?.sd, dataDashboard?.personal_info_by_last_education?.sd],
    ["SMP / Sederajat", dataDashboard?.personal_info_by_last_education?.sltp, dataDashboard?.personal_info_by_last_education?.sltp],
    ["SMA / Sederajat", dataDashboard?.personal_info_by_last_education?.slta, dataDashboard?.personal_info_by_last_education?.slta],
    ["D1 / D2 / D3 ", dataDashboard?.personal_info_by_last_education?.D1D2D3SM, dataDashboard?.personal_info_by_last_education?.D1D2D3SM],
    ["S1", dataDashboard?.personal_info_by_last_education?.D4S1, dataDashboard?.personal_info_by_last_education?.D4S1],
    ["S2", dataDashboard?.personal_info_by_last_education?.S2, dataDashboard?.personal_info_by_last_education?.S2],
    ["S3", dataDashboard?.personal_info_by_last_education?.S3, dataDashboard?.personal_info_by_last_education?.S3],
    ["Lain-lain", dataDashboard?.personal_info_by_last_education?.lainnya, dataDashboard?.personal_info_by_last_education?.lainnya],
  ]

  return (
    <Card className='px-4'>    
      <Chart
        chartType="BarChart"
        width="100%"
        height="338px"
        data={dataEnam}
        options={DataSekolah}
      />
      </Card>
  );
}
