import { useMutation, useQueryClient } from '@tanstack/react-query'
import {getUserNotificationList, updateUserNotification} from '../api/userNotifications'
import {useGetListData} from './helpers/useGetData'

const _KEY = 'user-notification'
export const useGetUserNotificationList = (message?: string) => {
  return useGetListData({
    queryFn: getUserNotificationList,
    queryKey: _KEY,
  })
}

export const useReadUserNotification = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return await updateUserNotification(id)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_KEY])
      },
    }
  )
}