/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import {AuthModel} from '../core/_models'
import {parseJwt} from '../../../utils/converter'
import {useAuth} from '../core/Auth'
import {AxiosError, isAxiosError} from 'axios'
import { loginV2 } from '../../../api/auth'

const initialValues = {
  email: '',
  password: '',
  // captcha: "",
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(6, 'Minimal 6 karakter')
    .max(50, 'Maksimal 30 karakter')
    .required('Harap masukan email atau username'),
  password: Yup.string()
  .min(6, 'Minimal 6 karakter')
  .max(50, 'Maksimal 30 karakter')
  .required('Harap masukan password'),
  // captcha: Yup.string()
  //   .required("Please complete the captcha verification"),
})

export function Login() {
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [recaptchaResponse, setRecaptchaResponse] = useState('');

  useEffect(() => {
    if (recaptchaResponse) {
      // The reCAPTCHA token is now set, you can proceed with it
      console.log('recaptchaResponse:', recaptchaResponse);
    }
  }, [recaptchaResponse]);

  const handleReCaptchaVerify = (token : string) => {
    if (!recaptchaResponse) { // Ensure it's not already set
      setRecaptchaResponse(token);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      try {
      const {data} = await loginV2(values);
        const authInfo: AuthModel = {
          ...data.data,
          email: values.email,
      };
        saveAuth(authInfo)
        const parseData = parseJwt(authInfo)
        setCurrentUser(parseData)
      } catch (error : AxiosError<any> | any) {
        if (isAxiosError(error) && error.response) {
          const message = error.response?.data.error
          formik.setErrors({email: message, password: message})
        } else {
          setLoginError('Terjadi kesalahan saat login. Silahkan coba beberapa saat lagi.')
        }
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 h-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='mb-11'>
        <h1 className='text-dark fw-bolder'>Selamat Datang</h1>
        {loginError && <div className='text-danger fw-bold fs-6'>{loginError}</div>}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}
      
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username / Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'border border-danger': formik.touched.email && formik.errors.email},
            {
              'border border-success': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Kata Sandi</label>
        <div className='position-relative'>
          <input
            type={`${showPassword ? 'text' : 'password'}`}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border border-danger': formik.touched.password && formik.errors.password,
              },
              {
                'border border-success': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div
            role='button'
            onClick={() => setShowPassword(!showPassword)}
            className='position-absolute end-0 top-25 mx-5'
          >
            <KTIcon className='fs-2' iconName={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} />
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          // TODO : Check Validation
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Masuk</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* TODO: Wait for register */}
      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
