import * as yup from 'yup'

export const embarkationSchema = yup.object().shape({
    id: yup.number(),
    code: yup.string(),
    created_at: yup.string(),
    updated_at: yup.string()
})

export const embarkationListSchema = yup.array().of(embarkationSchema)
