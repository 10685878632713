import { getUserByEmail } from "../api/users"

export const isEmailExist =  async (email: string , setFieldError: any) => {
  try {
    const userByEmail = await getUserByEmail(email)
    if (userByEmail?.email === email) setFieldError('email', 'Email sudah terdaftar')
    return true
  } catch (error) {
    console.log(`${email} belum terdaftar`)
  }
}

export const isUsernameExist =  async (username: string , setFieldError: any) => {
  try {
    const userByUsername = await getUserByEmail(username)
    if (userByUsername?.username === username) setFieldError('username', 'Username sudah terdaftar')
    return true
  } catch (error) {
    console.log(`${username} belum terdaftar`)
  }
}