import axios from 'axios'
import {CreateGeofenceType, GeofenceLogType, GeofenceType} from '../models/geofence'
import { BaseQuery, Pagination } from '../models/base'
import { convertQueryToString } from '../utils/queryToString'

const Endpoint = '/api/geofences'
const EndpointUpdateGeofence = '/api/geofence'
const EndpointLog = '/api/geofence_logs'
export const getGeofenceList = async (query: string = '') => {
  const res = await axios.get(Endpoint + '?' + query);
  return await res.data.data as Pagination<GeofenceType[]>
}

export const getGeofenceListQuery = async (query: BaseQuery) => {
  const queryString = convertQueryToString(query)
  const res = await axios.get(Endpoint + '?' + queryString);
  return await res.data.data as Pagination<GeofenceType[]>
}


export const getGeofence = async () => {
  const total = await axios.get(Endpoint);
  const res = await axios.get(`${Endpoint}?size=${total.data.data.total_rows}`);
  return await res?.data?.data?.rows as GeofenceType[]
}

export const addGeofence = async (data: CreateGeofenceType) => {
  return await axios.post(Endpoint, data)
}

export const deleteGeofenceById = async (id: string) => {
  const res = await axios.delete(`${Endpoint}/${id}`)
  return res.data.error
}

export const getGeofencebyId = async (id: string) => {
  return await axios.get(`${Endpoint}/${id}`)
}

export const setActiveGeofence = async (id: string) => {
  return await axios.get(`${EndpointUpdateGeofence}/activate/${id}`)
}

export const getGeofenceLogs = async (id: number, date: string, hajj_info: string, search: any) => {
  const res = await axios.get(`${EndpointLog}?geofence_id=${id}&date=${date}&q=${search}`);
  return (res.data.data as GeofenceLogType[])
}


