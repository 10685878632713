import { useEffect, useState } from "react";
import { useSetActiveGeofenceById } from "../../hooks/geofence";
import './toggle.css';
import { useSetStatusOU } from "../../hooks/ou";

interface Props {
    is_active: boolean;
    id: string | number;
    type: string;
}

const ToggleButton: React.FC<Props> = ({ is_active, id, type }) => {
    const { mutate: setActiveToggleGeofence } = useSetActiveGeofenceById();
    const { mutate: setActiveToggleOU } = useSetStatusOU();
    const [isActiveGeofence, setIsActiveGeofence] = useState<boolean>(is_active);
    const [isActiveOU, setIsActiveOU] = useState<boolean>(is_active);

    useEffect(() => {
        const handleToggleActivation = () => {
            if (type === 'geofence') {
                setIsActiveGeofence(prevIsActive => !prevIsActive);
                if (!isActiveGeofence) {
                    setActiveToggleGeofence({ id: String(id) });
                } else {
                    setActiveToggleGeofence({ id: '' });
                }
            } else {
                setIsActiveOU(!is_active);
                setActiveToggleOU({ id: String(id), status: String(!is_active === true ? 'activate' : 'deactivate') });
            }
        };
        const toggleElement = document.getElementById(String(id));
        if (toggleElement) {
            toggleElement.addEventListener('click', handleToggleActivation);
        }

        return () => {
            if (toggleElement) {
                toggleElement.removeEventListener('click', handleToggleActivation);
            }
        };
    }, [id, isActiveGeofence, isActiveOU, setActiveToggleGeofence, setActiveToggleOU, type, is_active]);

    return (
        <label style={{cursor: 'pointer'}} className="switch">
            <input type="checkbox" id={String(id)} checked={isActiveOU || isActiveGeofence} readOnly />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleButton;
