import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slider, Typography } from '@mui/material';
import React, { useState } from 'react';
import  Cropper from 'react-easy-crop';
import getCroppedImg from './utils/cropImage';

interface Props {
  photoUrl: string
  cropImageUrl: string
  openCrop: boolean
  setOpenCrop: React.Dispatch<React.SetStateAction<boolean>>
  setPhotoUrl: React.Dispatch<React.SetStateAction<string | null>>
  setFile: React.Dispatch<React.SetStateAction<File | null>>
}

interface CroppedArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

const CropEasy = ({openCrop, setOpenCrop,setPhotoUrl, setFile , cropImageUrl} : Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedArea | null>(null);

  const cropComplele = (croppedArea : CroppedArea, croppedAreaPixels: CroppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const cropImage = async () => {
    try {
      const { file, url }  = await getCroppedImg(cropImageUrl, croppedAreaPixels, rotation) as {
        file: File
        url: string
      }
      setPhotoUrl(url)
      console.log(url)
      setFile(file)
      console.log(file)
      setOpenCrop(false)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <Dialog
      open={openCrop}
      onClose={() => setOpenCrop(false)}
      >
      <DialogTitle>Sesuaikan Gambar</DialogTitle>
      <IconButton
          aria-label="close"
          onClick={() => setOpenCrop(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <i className="bi bi-x-lg h-10"></i>
        </IconButton>
      <DialogContent dividers
      sx={{
        background: "#333",
        position: "relative",
        height: 400,
        width: "auto",
        minWidth: {sm:500},
      }}>
        <Cropper 
        image={cropImageUrl}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        aspect={1}
        onZoomChange={setZoom}
        onRotationChange={setRotation}
        onCropChange={setCrop}
        onCropComplete={cropComplele}
        />
      </DialogContent>
      <DialogActions sx={{flexDirection: "column", mx: 3, my: 2}}>
        <Box sx={{width: "100%", mb: 1}}>
          <Typography>Zoom : {zoomPercent(zoom)}</Typography>
          <Slider 
            valueLabelDisplay="auto"
            valueLabelFormat={zoomPercent}
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(event, zoom) => setZoom(zoom as number)}
          />
        </Box>
        <Box sx={{width: "100%", mb: 1}}>
          <Typography>Rotation : {rotation}</Typography>
          <Slider 
            valueLabelDisplay="auto"
            min={1}
            max={360}
            value={rotation}
            onChange={(event, rotation) => setRotation(rotation as number)}
          />
        </Box>
        <Box 
          sx={{
            display: "flex",
            gap:2, 
            flexWrap: "wrap",
          }}
        >
          <button className="btn btn-secondary"
           onClick={() => setOpenCrop(false)}>
            <i className="bi bi-x-lg"></i>
            Batal
          </button>
          <button className='btn btn-primary'
            onClick={cropImage}>
            <i className='bi bi-check2'></i>
            Simpan
          </button>
        </Box>
      </DialogActions>
      </Dialog>
    </>
  )
}

export default CropEasy

const zoomPercent = (value: number) => {
  return `${Math.round (value * 100)}%`
}