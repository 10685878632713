import { useEffect} from 'react';
import { roleUser } from '../../../../app/libs/function';
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import { useAppContext } from '../../../helpers/AppContext';
import {HeaderUserMenu} from '../../../partials'
import { useGetProfile } from '../../../../app/hooks/profile';

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const {
    fullname, 
    setFullname,  
    setOrganizationName,  
    setEmail, 
    role, 
    setRole,
    setStatus,
    setOrganizationID,
    setSubOrganizationName,
    setSubOrganizationID,
    setNameGroup,
    setGroupId,
    organizationName,
    subOrganizationName,
    nameGroup,
  } = useAppContext();
  const {data : dataUser } = useGetProfile();
  
  //Set Data User To Local Storage
  useEffect(() => {
    if (dataUser) {
      const storeDataUser = {
        fullname: dataUser?.name,
        organizationName: dataUser?.organization_unit?.name,
        email: dataUser?.email,
        role: dataUser?.role_id,
        status: dataUser?.status,
        organization_unit: dataUser?.organization_unit,
        sub_organization_unit: dataUser?.sub_organization_unit
      };

      setFullname(storeDataUser.fullname);
      setOrganizationName(storeDataUser.organizationName);
      setEmail(storeDataUser.email);
      setRole(storeDataUser.role);
      setStatus(storeDataUser.status);
      setOrganizationID(storeDataUser.organization_unit?.id);
      setSubOrganizationID(storeDataUser?.sub_organization_unit?.id);
      setSubOrganizationName(storeDataUser?.sub_organization_unit?.name);
      setNameGroup(dataUser?.group?.code);
      setGroupId(dataUser?.group?.id);

      localStorage.setItem('data_user', JSON.stringify(storeDataUser));
    }
  }, [dataUser, setFullname, setOrganizationName, setEmail, setRole, setStatus, setOrganizationID, setSubOrganizationID, setSubOrganizationName, setNameGroup, setGroupId]);
  
  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img src={dataUser?.photo_url} alt='' 
              onError={(e) => { 
                e.currentTarget.src = toAbsoluteUrl('/media/svg/humans/custom-2.svg');
              }}
          />
        </div>
        {/*end::Symbol*/}

        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              

              {/*begin::Name*/}
              <span className='text-white fw-bold d-block fs-8 mb-1'>{fullname}</span>
              {/*end::Name*/}

              {/*begin::Role*/}
              <span className='text-gray-400 fw-bold d-block fs-8 mb-1'>
                {roleUser(role)}
              </span>
              {/*end::Role*/}

              {/*begin::Label*/}
              <div className='d-flex align-items-center text-success fs-9'>
                <span className='text-gray-400 fw-bold d-block fs-8 mb-1'>
                {role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM'}
              </span>
              </div>
              {/*end::Label*/}
            </div>
            {/*end::Info*/}

            {/*begin::User menu*/}
            <div className='me-n2'>
              {/*begin::Action*/}
              <a
                href='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <KTIcon iconName='setting-2' className='text-muted fs-1' />
              </a>

              <HeaderUserMenu />
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}
    </>
  )
}

export {AsideToolbar}
