import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addUser, deleteUserByID, getUserByEmail, getUserByID, getUsers, updateUser } from "../api/users";
import { AddUserType, ListUserRespBody, UserType } from "../models/users";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Base, BaseQuery } from '../models/base';
import { useGetListData } from './helpers/useGetData';
import Swal from 'sweetalert2';

const _USERS = 'users';
export const useGetUsers = (query: BaseQuery) => {
  return useGetListData({
    queryFn: getUsers,
    queryKey: _USERS,
    query,
  })
}

export const useGetUserByID = (id: number) => {
  return useQuery([_USERS, id],
    async () => {
      if (!id) return null
      return await getUserByID(id)
    })
}

export const useGetUserByEmail = (email: string | undefined | null) => {
  return useQuery([_USERS, email],
    async () => {
      if (!email || email === '' || email === null || email === undefined) return 
      return await getUserByEmail(email)
    })
}

export const useAddUser = () => {
  const queryClient = useQueryClient()
  let result
  return useMutation(
    async ({ data }: {data: AddUserType }) => {
      result = await addUser(data)
      return result
    },
    {
      onSuccess: () => {
        toast.success('Akun Berhasil Ditambahkan')
        queryClient.invalidateQueries([_USERS])
      },
      onError: (err: AxiosError<ListUserRespBody>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
      },
    }
  )
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  let result
  return useMutation(
    async ({ id, data }: { id: number, data: AddUserType }) => {
      result = await updateUser(id, data)
      console.log(id)
      return result
    },
    {
      onSuccess: () => {
        toast.success('Akun Berhasil Diubah')
        queryClient.invalidateQueries([_USERS])
      },
      onError: (err: AxiosError<ListUserRespBody>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
      },
    }
  )
}

export const useDeleteUserByID = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id }: { id: number }) => {
      return await deleteUserByID(id)
    },
    {
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          title: `Akun berhasil dihapus`,
          showConfirmButton: false,
          timer: 1500
        })
        queryClient.invalidateQueries([_USERS])
      },
      onError: (err: AxiosError<Base<UserType>>) => {
        Swal.fire({
          icon: 'error',
          title: "Akun Gagal Dihapus",
          text: `${err.response?.data.error}`,
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  )
}