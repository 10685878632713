import axios from "axios";
import { Base, Pagination } from "../models/base";
import { AddOUType, OUType } from "../models/ou";

const Endpoint = '/api/organization_units';
/**
 * Retrieves the OU list by making an asynchronous GET request to the specified endpoint.
 *
 * @return {OUType[]} The OU list data returned from the server.
 */
export const getOUList = async (query: string = '') => {
    const res = await axios.get(Endpoint + '?' + query)
    return await res.data.data as Pagination<OUType[]>
}

/**
 * Retrieves the OU list by making an asynchronous GET request to the specified endpoint.
 *
 * @param {string} q - The query string used to filter the OU list.
 * @return {OUType[]} The OU list data returned from the server.
 */
export const getOU = async (q: string) => {
    const res = await axios.get(`${Endpoint}?name=${q}&size=10&`)
    return (res.data.data.rows as OUType[])
}

/**
 * Retrieves the parent OU by making an asynchronous GET request to the specified endpoint.
 *
 * @return {OUType[]} The parent OU data returned from the server.
 */
export const getParentOU = async () => {
    const res = await axios.get(`${Endpoint}/parent`)
    return res.data.data
}


/**
 * Retrieves the sub OU by OU ID
 *
 * @return {OUType[]} The sub OU data returned from the server.
 */

export const getSubOUByOUId = async (id: string) => {
    const res = await axios.get(`${Endpoint}/parent?ou_id=${id}`)
    return res.data.data
}
// export const getSubOUByOUId = async (id: string) => {
//     const res = await axios.get(`${Endpoint}?organization_unit_id=${id}`)
//     return res.data.data
// }


/**
 * Sets the status of an organization unit.
 *
 * @param {string} id - The ID of the organization unit.
 * @param {string} status - The new status of the organization unit.
 * @return {Promise<any>} - A promise that resolves to the updated data of the organization unit.
 */

export const setStatusOU = async (id: string, status: string) => {
    const res = await axios.put(`${Endpoint}/${id}/${status}`);
    return (res.data.data)
}

/**
 * Retrieves an OU object by its ID.
 *
 * @param {string} id - The ID of the OU.
 * @return {OUType} The OU object.
 */
export const getOUById = async (id: string) => {
    const res = await axios.get<Base<OUType>>(`${Endpoint}/${id}`)
    return (res.data.data as OUType)
}

/**
 * Creates a new OU (Organizational Unit) using the provided data.
 *
 * @param {AddOUType} data - The data used to create the OU.
 * @return {Promise<OUType>} - A promise that resolves to the created OU.
 */
export const createOU = async (data: AddOUType, role: number) => {
    delete data.name_ou
    if (role !== 1) {
        delete data.type;
    }
    return await axios.post<Base<OUType>>(Endpoint, data)
}

/**
 * Updates the OU with the specified id.
 *
 * @param {string} id - The id of the OU to update.
 * @param {AddOUType} data - The updated OU data.
 * @return {OUType} The updated OU data.
 */
export const updateOU = async (id: string, data: AddOUType) => {
    const res = await axios.put<Base<OUType>>(`${Endpoint}/${id}`, data)
    return (res)
}

/**
 * Deletes an OU with the specified ID.
 *
 * @param {string} id - The ID of the OU to delete.
 * @return {OUType} The deleted OU.
 */
export const deleteOU = async (id: string) => {
    const res = await axios.delete<Base<OUType>>(`${Endpoint}/${id}`)
    return (res.data.data)
}


