import React, { useEffect, useState } from "react"
import GoogleMaps from "../../../components/GoogleMaps"
import { Circle, OverlayView } from "@react-google-maps/api"
import './reddot.css';
import { PersonalInfoType } from "../../../models/personalInfo";
import { DeviceType } from "../../../models/device";
import { useGetDeviceByID } from "../../../hooks/device";

interface Props {
    lat: number
    lng: number
}
const GeofenceMapLogs: React.FC<{
    data: Props[]
    radiusLat: number
    radiusLng: number
    radius: number
    center?: {
        lat: number
        lng: number
    }
    personalInfo: PersonalInfoType
}> = ({ data, radiusLat, radiusLng, radius, center, personalInfo }) => {
    const [centerMaps, setCenterMaps] = useState< { lat: number; lng: number } >({ lat: 0, lng: 0 })
    const [popupContent, setPopupContent] = useState<JSX.Element | null>(null);
    const { data: device } = useGetDeviceByID(personalInfo?.device_id ?? 0)

    useEffect(() => {
        if (center?.lat && center?.lng) {
            setCenterMaps(center)
        } else if (radiusLat && radiusLng) {
            setCenterMaps({ lat: radiusLat, lng: radiusLng })
        }
    }, [center, radiusLat, radiusLng])

    const handleMouseOver = (device: DeviceType) => {
        const popupCard = (
          <div className="popupCard card">
            <div className="card-body p-5">
              <div className="row">
                <div className="col-4">
                  <div className="photoContainer">
                    <img className='profilePicture' src="./assets/profile.jpg" alt='Profile' />
                  </div>
                </div>
                <div className="col-8">
                  <div className="personalInfo">
                    <table className='tablePersonalInfo'>
                      <tbody>
                        <tr>
                          <td className='titleProfile'>Nama</td>
                          <td className='profileValue'>{device && device.personal_info?.name ? device.personal_info?.name : '-'}</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>Usia</td>
                          <td className='profileValue'>{device && device.personal_info?.age ? device.personal_info?.age : '-'}</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>Jenis Kelamin</td>
                          <td className='profileValue'>{device && device.personal_info?.gender === 'male' ? 'Laki-laki' : device.personal_info?.gender === 'female' ? 'Perempuan' : '-' }</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>Sisa Baterai</td>
                          <td className='profileValue'>{device && device.battery ? device?.battery + ' %' : '-'}</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>ID Jama'ah / Paspor</td>
                          <td className='profileValue'>{device && device.personal_info?.hajj_info ? device?.personal_info?.hajj_info : '-'}</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>Kloter</td>
                          <td className='profileValue'>{device && device.personal_info?.group?.code ? device?.personal_info.group?.code : '-'}</td>
                        </tr>
                        <tr>
                          <td className='titleProfile'>Kontak Jamaah</td>
                          <td className='profileValue'>{device && device.sim_number ? device?.sim_number : '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        setPopupContent(popupCard);
      };

    return (
        <div className="w-100 h-350px">
            <GoogleMaps center={centerMaps} >
                {radiusLat && radiusLng && (
                    <Circle
                    center={{ lat: radiusLat, lng: radiusLng }}
                    radius={radius}
                    options={{
                        strokeColor: "#FF0000",
                    }} 
                    draggable={false}
                    editable={false}
                    />
                )}
                {data.map((item: Props, index) => {
                    return (
                        <OverlayView
                            key={index}
                            position={item}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <div className="d-flex align-items-left justify-content-center">
                                <div
                                    className="redDots"
                                    onClick={() => handleMouseOver(device)}
                                    onMouseOut={() => setPopupContent(null)}
                                ></div>
                                {popupContent && (
                                <div className="popupContent">{popupContent}</div>
                                 )}
                            </div>
                        </OverlayView>
                    )
                })}
            </GoogleMaps>
        </div>
    )
}

export default GeofenceMapLogs