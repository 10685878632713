import { useQuery } from '@tanstack/react-query'
import { getGroupByEmbarkasiId, getGroupList } from "../api/group"

export const _GROUP_LIST = 'group-list'
const STALE_TIME = 60000; // 1 minute (in milliseconds)

/**
 * Custom React query hook for fetching group list based on the provided category.
 *
 * @param {string | null | undefined} category - The category for filtering the group list
 * @return {QueryResult} The result of the query, containing the group list data
 */
export const useGetGroupList = (category?: string | null | undefined, q? : string | null | undefined) => {
    return useQuery([_GROUP_LIST, category],
        async () => {
            return await getGroupList(category, q)
        },
        {
            staleTime: STALE_TIME, // Setting the stale time
            refetchOnWindowFocus: false
        }
    );
};

/**
 * Retrieves the group by embarkasi ID.
 *
 * @param {number | undefined} id - The ID of the embarkasi
 * @return {Promise<any>} The result of the query
 */
export const useGetGroupByEmbarkasiId = (id: number | undefined) => {
    return useQuery([_GROUP_LIST, id],
        async () => {
            if (!id || id === 0) {
                return null
            } else {
                return await getGroupByEmbarkasiId(id)
            } 
            
        },{
            staleTime: STALE_TIME,
            refetchOnWindowFocus: false
        }
    )
}