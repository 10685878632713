import { useGetDataGeofence } from "../../../../app/hooks/statistik";

type Props = {
  className: string
}

const ChartsWidget2: React.FC<Props> = ({className}) => {
  const {data: dataGeofence} = useGetDataGeofence();

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-title align-items-start flex-column'>
          <span className='card-label fw-normal fs-4 mb-1'>Data Aktifitas Geofence</span>

          <span className='text-muted fw-semibold fs-7'>Geofence Aktif : {dataGeofence?.geofence_active_total?.geofence_active_total}</span>
        </div>
      </div>
          <div className='card-body d-flex flex-column align-items-start justify-content-start'>
            <h3 className='text-left text-muted mb-2'>Jumlah Keluar Zona</h3>
            <div className='d-flex align-items-center mb-2'>
              <img src="/media/svg/shapes/rectangle-red.svg" alt="keluar-zona" />
              <h4 className='mb-0 fs-2x px-3'>{dataGeofence?.geofence_activity?.zone_out_total}</h4>
            </div>
            <h3 className='text-left text-muted mb-2 mt-2'>Jumlah Dalam Zona</h3>
            <div className='d-flex align-items-center'>
             <img src="/media/svg/shapes/rectangle-green.svg" alt="dalam-zona" />
              <h4 className='mb-0 fs-2x px-3'>{dataGeofence?.geofence_activity?.zone_in_total}</h4>
            </div>
          </div>
    </div>
  )
}

export {ChartsWidget2}

