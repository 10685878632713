import axios from 'axios'

interface PresignedURLResponse {
    data: string
    error: string
    message: string
}

/**
 * Retrieves a presigned URL for uploading a file to AWS S3.
 * @param fileName - The name of the file to upload.
 * @returns A presigned URL for uploading a file to AWS S3.
 */
export const getPresignedURL = async (fileName: string) => {
    const res = await axios.post<PresignedURLResponse>(`/api/presigned-url`, { file_name: fileName })
    return res?.data as PresignedURLResponse
}