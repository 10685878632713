import { Link } from "react-router-dom";
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import { Badge } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDeleteUserByID, useGetUsers } from "../../hooks/users";
import ServerTable, { useServerTable } from "../../components/table/ServerTable";
import { createColumnHelper } from "@tanstack/react-table";
import { UserType } from "../../models/users";
import { DeviceManagementFilter } from "../../modules/filter/DeviceManagementFilter";
import ModalDetailUser from "./ModalDetailUser";
import { roleUser } from "../../libs/function";
import { useAppContext } from "../../../_metronic/helpers/AppContext";

export const UsersPage: FC = () => {
    const { role, organizationName, subOrganizationName, nameGroup } = useAppContext();
    const {
        pageIndex,
        pageSize,
        filterValue,
        sorting,
        pagination,
        setFiltering,
        setPagination,
        setSorting,
    } = useServerTable();
    const [ selectedRole, setSelectedRole ] = useState<string>('');
    const { data: listUsers } = useGetUsers({ 
        q: filterValue,
        pageIndex, 
        pageSize, 
        sortBy: sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(),
        role: selectedRole
    });
    const { mutate: deleteUser } = useDeleteUserByID();
    const [ showModalDetailUser, setShowModalDetailUser ] = useState<boolean>(false);
    const [ selectedUserId, setSelectedUserId ] = useState<number>(0);

    useEffect(() => {
        setPagination({pageIndex: 0, pageSize: 10})
    },[filterValue, selectedRole, setPagination])
    
    const handleDeleteUser = (id: number, name: string, role_id: number) => {
        Swal.fire({
            title: 'Konfirmasi Hapus User',
            text: `Apakah Anda Yakin Ingin Menghapus User ${name} - ${roleUser(role_id)}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#e60026',
            cancelButtonColor: '#dcdcdc',
            cancelButtonText: 'Kembali',
            confirmButtonText: 'Ya, Hapus',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser({ id: id });
            }
        })
    }

    const handleShowModalDetailUser = (id : number) => {
        setShowModalDetailUser(true);
        setSelectedUserId(id);
    }

    const columnHelper = createColumnHelper<UserType>();
    const columns = [
        columnHelper.accessor(() => 'itemNumber', {
            id: 'itemNumber',
            header: () => <div className="text-left">#</div>,
            cell: info => (
                <div className="text-left">{pageSize * pageIndex + info.row.index + 1}</div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'name', {
            id: 'name',
            header: () => <div className="text-left">Nama</div>,
            cell: info => (
                <div className="text-start">{info.row.original.name ?? ''}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'username', {
            id: 'username',
            header: () => <div className="text-left">Username</div>,
            cell: info => (
                <div className="text-start">{info?.row?.original?.username ? info?.row?.original?.username : '-'}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'email', {
            id: 'email',
            header: () => <div className="text-left">Email</div>,
            cell: info => (
                <div className="text-start">{info.row.original.email}</div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'role', {
            id: 'role',
            header: () => <div className="text-left">Peran</div>,
            cell: info => (
                <div className="text-start">{roleUser(info.row.original.role_id)}</div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'ou_name', {
            id: 'ou_name',
            header: () => role === 1 ? <div className="text-left">Unit Organisasi</div> : <></>,
            cell: info => (
                role === 1 ?<div className="text-start">{info.row.original.organization_unit?.name ?? "-"}</div> : <></>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'sub_ou_name', {
            id: 'sub_ou_name',
            header: () => role === 2 || role === 1 ? <div className="text-left">Sub Organisasi</div> : <></>,
            cell: info => (
                role === 2 || role === 1 ? <div className="text-start">{info.row.original.sub_organization_unit?.name ?? "-"}</div> : <></>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'group', {
            id: 'group',
            header: () => role !== 1 ? <div className="text-left">Kloter / Group</div> : <></> ,
            cell: info => (
                role !== 1 ? <div className="text-start">{info.row.original.group?.code ?? "-"}</div> : <></>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'status', {
            id: 'status',
            header: () => <div className="text-left">Status</div>,
            cell: info => (
                <div className="text-left">
                    {info.row.original.status === "Activated" ?
                        <Badge bg="light-success" style={{ color: '#34bb74', fontWeight: "bold" }}>
                            Aktif
                        </Badge>
                        :
                        <Badge bg="light-danger" style={{ color: '#f0406c', fontWeight: "bold" }}>
                            Tidak Aktif
                        </Badge>
                    }
                </div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'action', {
            id: 'action',
            header: () => <div className="text-center">Kontrol</div>,
            cell: info => {
            const dataRole = info.row.original.role_id;
            const access = role === 1 && dataRole === 2 ? true :
                            role === 2 && dataRole === 3 ? true :
                            role === 3 && dataRole === 4 ? true : false;
            return (
                <div className="text-center d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-icon btn-light-info btn-sm me-1"
                        onClick={() => handleShowModalDetailUser(parseInt(info.row.original.id))}
                    >
                        <i className="bi bi-eye fs-2"></i>
                    </button>
                    { role !== 4 ?
                    <>
                    <button
                        type="button"
                        className={`btn btn-icon ${!access ? "btn-light-base" : "btn-light-primary"} btn-sm me-1`}
                        style={access ? { color: "#808080" } : {}}
                    >
                        {!access ? (
                        <div className="text-secondary bg-light p-2 rounded">
                          <i className="bi bi-pencil-square fs-2"></i>
                        </div>
                      ) : (
                        <Link to={`/users/update/${info.row.original.id}`}>
                          <i className="bi bi-pencil-square fs-2"></i>
                        </Link>
                      )}
                    </button>
                    <button
                        type="button"
                        className={`btn btn-icon ${!access ? "btn-light-base bg-light" : "btn-light-danger"} btn-sm me-1`}
                        style={!access ? { color: "#808080" } : {}}
                        onClick={() => handleDeleteUser(parseInt(info.row.original.id), info.row.original.name, info.row.original.role_id)}
                        disabled={!access}
                    >
                        <i className='bi bi-trash fs-4' style={!access ? { color: "#808080" } : {}}></i>
                    </button>
                    </> : 
                    <></>
                    }
                </div>
            )        
        },
            enableSorting: false
        })
    ]
    


    return (
        <>
            <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path: '/users',
                description: role === 2 ? "Organisasi Unit" : role === 3 ? "Sub Organisasi" : '',
                isActive: true,
                isSeparator: false
            }
            ]}>Manajemen Akun</PageTitle>
            <KTCard className="p-5 shadow-sm">
                <div className="card-title d-flex justify-content-between">
                    <div className="d-flex align-items-center position-relative my-1">
                        <KTIcon iconName="magnifier" className="fs-1 position-absolute ms-6" />
                        <input
                            type="text"
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder="Cari"
                            onChange={(e) => setFiltering(e.target.value)}
                        />
                    </div>
                    <div>
                        <DeviceManagementFilter
                        onChangeRole={(role: string) => setSelectedRole(role)}
                        role={selectedRole}
                        hiddenAvailability={true}
                        hiddenCategory={true}
                        hiddenStatus={true}
                        hiddenEmbarkation={true}
                        hiddenAlarmType={true}
                        hiddenDate={true}
                        hiddenStatusJamaah={true}
                        hiddenGender={true}
                        hiddenOrganizationUnitId={true}
                        hiddenSubOrganizationId={true}
                        hiddenGroupId={true}
                        />
                        { role !== 4 ? <>
                        <Link to={'add'} className="btn btn-primary me-3">
                            <KTIcon iconName="bi bi-plus-circle-dotted" className="fs-2" />
                            Tambah
                        </Link>
                        </> : <></>}
                    </div>
                </div>
                <KTCardBody>
                    <ServerTable
                        data={listUsers?.rows ?? []}
                        columns={columns}
                        totalData={listUsers?.total_rows ?? 0}
                        pageCount={listUsers?.total_pages ?? 0}
                        pagination={pagination}
                        setPagination={setPagination}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </KTCardBody>
            </KTCard>
            <ModalDetailUser show={showModalDetailUser} onClose={() => setShowModalDetailUser(false)} id={selectedUserId}/>
        </>
    )
}