import {DeviceType} from '../../models/device'
import './MapBox.css'

export default function PopPupDevice({device}: {device: DeviceType}) {
  return (
    <div className='popup-device'>
      <div className='card-body p-2'>
        <div className='row'>
          <div className='col-4'>
            <div className='photoContainer'>
              <img className='profilePicture' src='./assets/profile.jpg' alt='Profile' />
            </div>
          </div>
          <div className='col-8'>
            <div className='personalInfo'>
              <table className='tablePersonalInfo'>
                <tbody>
                  <tr>
                    <td className='titleProfile'>Nama</td>
                    <td className='profileValue'>
                      {device && device.personal_info?.name ? device.personal_info?.name : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>Usia</td>
                    <td className='profileValue'>
                      {device && device.personal_info?.age ? device.personal_info?.age : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>Jenis Kelamin</td>
                    <td className='profileValue'>
                      {device && device.personal_info?.gender
                        ? device.personal_info?.gender === 'male'
                          ? 'Laki-laki'
                          : 'Perempuan'
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>Sisa Baterai</td>
                    <td className='profileValue'>
                      {device && device.battery ? device?.battery + ' %' : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>ID Jamaah / Paspor</td>
                    <td className='profileValue'>
                      {device && device.personal_info?.hajj_info
                        ? device?.personal_info?.hajj_info
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>Kloter</td>
                    <td className='profileValue'>
                      {device && device.personal_info?.group?.code
                        ? device?.personal_info.group?.code
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className='titleProfile'>Kontak Jamaah</td>
                    <td className='profileValue'>
                      {device && device.sim_number ? device?.sim_number : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
