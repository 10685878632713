import * as yup from 'yup'

export const createGeofenceSchema = yup.object().shape({
  name: yup.string().required("Nama Geofence Perlu Diisi").max(30, "Maksimal 30 karakter"),
  latitude: yup.number().required("Latitude Perlu Diisi"),
  longitude: yup.number().required("Longitude Perlu Diisi"),
  radius: yup.number().required("Radius Perlu Diisi"),
  group_id: yup.number().required("Grup Perlu Diisi"),
  fence_alarm: yup.string().oneOf(['in','out','both']).required("Fence Alarm Perlu Dipilih"),
})

export const geofenceSchema = createGeofenceSchema.shape({
  id : yup.number().required(),
  created_at : yup.string().required(),
  updated_at : yup.string().required(),
  group_id : yup.number().required(),
})

export const geofenceListSchema = yup.array().of(geofenceSchema)
