import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createOU, deleteOU, getOU, getOUById, getOUList, getParentOU, getSubOUByOUId, setStatusOU, updateOU } from "../api/ou"
import { AddOUType, OUType } from "../models/ou"
import Swal from "sweetalert2"
import { AxiosError } from "axios"
import { Base, BaseQuery } from "../models/base"
import { useGetListData } from "./helpers/useGetData"

const _OU = 'organization-units'

export const useGetOUList = (query : BaseQuery) => {
    return useGetListData({
        queryFn: getOUList,
        queryKey: _OU,
        query,
    })
}

/**
 * Retrieves the OU list using the useQuery hook.
 *
 * @return {QueryResult} The result of the query containing the OU list.
 */
export const useGetOU = (q: string) => {
    return useQuery(
        [_OU, q],
        async () => {
            return await getOU(q)
        },
        {
            refetchOnWindowFocus: false,
        }
    )
}

/**
 * Retrieves the parent OU using the useQuery hook.
 *
 * @return {QueryResult} The result of the query containing the parent OU.
 */
export const useGetParentOU = () => {
    return useQuery(
        [_OU],
        async () => {
            return await getParentOU()
        },
        {
            refetchOnWindowFocus: false,
        }
    )
}


/**
 * Retrieves Sub OU by OU ID using the useQuery hook.
 * 
 * 
 */
export const useGetSubOUByOUId = (id: string) => {
    return useQuery(
        [_OU, id],
        async () => {
            return await getSubOUByOUId(id)
        },
        {
            refetchOnWindowFocus: false,
        }
    )
}
/**
 * Returns a QueryClient hook that fetches an OU by its ID.
 *
 * @param {string} id - The ID of the OU to fetch.
 * @return {QueryObserverResult} The result of the query, containing the OU data.
 */
export const useGetOUById = (id?: string) => {
    return useQuery(
        [_OU, id],
        async () => {
            if (!id || id === '0') {
                return null
            } else {
                return await getOUById(id)
            }
        },
        {
            refetchOnWindowFocus: false,
        }
    )
}

/**
 * Creates a custom hook that can be used to create a new object in the API.
 *
 * @returns {MutationFunction} The mutation function.
 */
export const useCreateOU = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async ({ data, role }: { data: AddOUType, role : number }) => {
            return await createOU(data, role)
        },
        {
            /**
             * Executes the onSuccess function.
             *
             * @param {type} paramName - description of the parameter
             * @return {type} description of the return value
             */
            onSuccess: () => {
                Swal.fire({
                    icon: 'success',
                    text: "Data Berhasil Ditambahkan",
                    showConfirmButton: false,
                    timer: 1500,
                })
                queryClient.invalidateQueries([_OU])
            },
            /**
             * Handles the error that occurs during the API request.
             *
             * @param {AxiosError<Base<OUType>>} err - The error object containing information about the error.
             */
            onError: (err: AxiosError<Base<OUType>>) => {
                Swal.fire({
                    icon: 'error',
                    title: "Data Gagal Ditambahkan",
                    text: `${err.response?.data.error}`,
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.error('ERROR CREATE: ', err)
            }
        }
    )
}

/**
 * Generates a function comment for the given function body.
 *
 * @return {type} description of return value
 */
export const useUpdateOU = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async ({ id, data }: { id: string, data: AddOUType }) => {
            return await updateOU(id, data)
        },
        {
            /**
             * Executes the onSuccess function and invalidates the specified query.
             *
             * @param {} - no parameters
             * @return {} - no return value
             */
            onSuccess: () => {
                Swal.fire({
                    icon: 'success',
                    title: "Data Berhasil Diubah",
                    showConfirmButton: false,
                    timer: 1500,
                })
                queryClient.invalidateQueries([_OU])
            },
            /**
             * Handles the error that occurs during the API call.
             *
             * @param {AxiosError<Base<OUType>>} err - The error object containing the response data.
             */
            onError: (err: AxiosError<Base<OUType>>) => {
                Swal.fire({
                    icon: 'error',
                    title: "Data Gagal Diubah",
                    text: `${err.response?.data.error}`,
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.error('ERROR UPDATE STATUS: ', err)
            }
        }
    )
}

/**
 * Generates a custom hook for deleting an OU by its ID.
 *
 * @returns {MutationFunction} The mutation function for deleting an OU.
 */
export const useDeleteOUById = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async ({ id }: { id: string }) => {
            return await deleteOU(id)
        },
        {
            /**
             * Executes the onSuccess callback function.
             *
             * @param {Function} onSuccess - The callback function to be executed.
             * @return {void} This function does not return a value.
             */
            onSuccess: () => {
                Swal.fire({
                    icon: 'success',
                    text: "Data Berhasil Dihapus",
                    showConfirmButton: false,
                    timer: 1500,
                })
                queryClient.invalidateQueries([_OU])
            },
            /**
             * A callback function to handle errors.
             *
             * @param {AxiosError<Base<OUType>>} err - The error object.
             */
            onError: (err: AxiosError<Base<OUType>>) => {
                Swal.fire({
                    icon: 'error',
                    title: "Data Gagal Dihapus",
                    text: `${err.response?.data.error}`,
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.error('ERROR DELETE: ', err)
            }
        }
    )
}

/**
 * Returns a mutation hook that sets the status of an organization unit.
 *
 * @return {MutationHook<void, { id: string, status: string }, unknown>} A mutation hook that takes an object with an `id` and `status` property, and returns a promise that resolves to `void`.
 * 
 * @throws {AxiosError<Base<OUType>>} If an error occurs during the API call, an `AxiosError` object containing the response data is thrown.
 */
export const useSetStatusOU = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async ({ id, status }: { id: string, status: string }) => {
            return await setStatusOU(id, status);
        },
        {
            /**
             * Executes the onSuccess function and invalidates the specified query.
             *
             * @param {} - no parameters
             * @return {} - no return value
             */
            onSuccess: () => {
                Swal.fire({
                    icon: 'success',
                    text: "Status Berhasil Diubah",
                    showConfirmButton: false,
                    timer: 1500,
                })
                queryClient.invalidateQueries([_OU])
            },
            /**
             * Handles the error that occurs during the API call.
             *
             * @param {AxiosError<Base<OUType>>} err - The error object containing the response data.
             */
            onError: (err: AxiosError<Base<OUType>>) => {
                Swal.fire({
                    icon: 'error',
                    title: "Status Gagal Diubah",
                    text: `${err.response?.data.error}`,
                    showConfirmButton: false,
                    timer: 1500,
                })
                console.error('ERROR UPDATE: ', err)
            }
        }
    )
}