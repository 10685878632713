import * as yup from 'yup'

export const addOUSchema = yup.object().shape({
    name: yup.string().required("Nama Perlu Diisi"),
    pic: yup.string().required("Nama Penanggung Jawab Perlu Diisi"),
    email: yup.string().required("Email Perlu Disi").email("Email tidak valid"),
    phone_number: yup.string()
        .matches(/^08\d{10,}$/, 'Nomor Telepon harus dimulai dengan 08 dan memiliki 10 digit atau lebih')
        .required("Nomor Telepon Perlu Diisi"),
    address: yup.string().required("Alamat Perlu Diisi"),
    type: yup.string().required("Tipe Perlu Dipilih").oneOf(["hajj", "umrah"]),
})

export const addSubOUSchema = yup.object().shape({
    name: yup.string().required("Nama Perlu Diisi"),
    pic: yup.string().required("Nama Penanggung Jawab Perlu Diisi"),
    email: yup.string().required("Email Perlu Disi").email("Email tidak valid"),
    phone_number: yup.string()
        .test('valid-phone', 'Nomor Telepon harus berupa angka, memulai dengan 08 dan memiliki 12 digit atau lebih', (value) => {
            if (typeof value === 'string' && value.length > 9 && value.match(/^08\d+$/)) {
                return true;
            } else {
                return false;
            }
        })
        .required("Nomor Telepon Perlu Diisi"),
    address: yup.string().required("Alamat Perlu Diisi"),
})