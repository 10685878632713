import "./IconNotif.css";

type Props = {
    status_description: string
    
}

const IconNotification = ({ status_description }: Props) => {

    let classBackgroundColor = "red-circle";
    let iconType = "bi bi-chat-left-fill";
    let badgeStatus = "bi bi-person-fill";

    //define icon to show
    if (status_description === 'geofence_in' || status_description === 'geofence_out') {
        iconType = "bi bi-geo-fill";
    }

   // Define  badge and color 
    if (status_description === 'geofence_in') {
        classBackgroundColor = "green-circle";
        badgeStatus = "bi bi-arrow-right";
    }
    else if (status_description === 'geofence_out') {
        classBackgroundColor = "red-circle";
        badgeStatus = "bi bi-arrow-left";
    } else if (status_description === 'in_progress') {
        classBackgroundColor = "yellow-circle";
        badgeStatus = "bi bi-clock";
    } else if (status_description === 'done') {
        badgeStatus = "bi bi-check2";
        classBackgroundColor = "green-circle";
    }

    return (
        <>
        
            <div className="container-icon">
                <div className={classBackgroundColor}>
                    <i 
                    className={badgeStatus}
                    style={{ 
                        marginLeft: '3px', 
                        fontSize: '14px', 
                        color: 'white'}}
                    ></i>
                </div>
                <div className="gray-circle">
                    <i 
                    className={iconType}
                    style={{fontSize: '19px', color: 'white'}}
                    ></i>
                </div>
            </div>
        </>
    );
};

export default IconNotification
