import {convertDDToDMS} from '../utils/converter'

interface RowCoordinateProps {
  position: {
    lat: number
    lng: number
  }
}

export const RowCoordinate = ({position}: RowCoordinateProps ) => {
  const {lat, lng} = position
  const latDMS = convertDDToDMS(lat, true);
  const lngDMS = convertDDToDMS(lng);

  return (
    <p>
      {`${latDMS} ${lngDMS}`}
    </p>
  )
}
