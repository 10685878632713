import * as yup from 'yup'
import {InferType} from 'yup'
import { groupSchema } from './group'
import { embarkationSchema } from './embarkation'

const requiredStringWithMessage = (msg?: string) => yup.string().required(msg || '')
export const personalInfoSchema = yup.object().shape({
  id: yup.number().required(),
  device_id: yup.number().nullable(),
  nik: yup.string(),
  member_id: yup.string(),
  name: yup.string(),
  gender: yup.string(),
  place_of_origin: yup.string(),
  age: yup.number().required(),
  medical_notes: yup.string(),
  blood_type: yup.string(),
  height: yup.number().required(),
  weight: yup.number().required(),
  date_of_birth: yup.string(),
  address: yup.string(),
  province_id: yup.number().required(),
  regency_id: yup.number().required(),
  district_id: yup.number().required(),
  subdistrict_id: yup.number().required(),
  embarkation: embarkationSchema.required(),
  group: groupSchema.required(),
  hajj_info: yup.string(),
  travel_name: yup.string(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
  disability_status: yup.string().required(),
  elderly_status: yup.string().required(),
  file_url: yup.mixed().test('file_url', 'File harus diisi', (value) => {
    if (value) {
      return typeof value === 'string';
    }
    return true;
  }),
})

export const createPersonalInfoSchema = yup.object().shape({
  name: requiredStringWithMessage('Nama lengkap harus diisi'),
  age: yup.number().required().min(1, "Umur harus lebih dari 0"),
  gender: requiredStringWithMessage('Jenis kelamin harus diisi'),
  place_of_origin: requiredStringWithMessage('Asal daerah harus diisi'),
  nik: requiredStringWithMessage('NIK harus diisi'),
  date_of_birth: yup.string(),
  blood_type: yup.string(),
  height: yup.number().min(1, "Tinggi badan harus lebih dari 0").nullable(),
  weight: yup.number().min(1, "Berat badan harus lebih dari 0").nullable(),
  medical_notes: yup.string(),
  address: yup.string(),
  member_id: yup.string(),
  travel_name: yup.string(),
})

export const addPersonalInfoSchema = yup.object().shape({
  name: requiredStringWithMessage('Nama lengkap harus diisi'),
  gender: requiredStringWithMessage('Jenis kelamin harus diisi'),
  place_of_origin: requiredStringWithMessage('Asal daerah harus diisi'),
  status_risti: yup.number().required('Status Risti Harus Diisi'),
  disability_status: yup.number().required('Status Disabilitas Harus Diisi'),
  job: requiredStringWithMessage('Pekerjaan harus diisi'),
  last_education: requiredStringWithMessage('Pendidikan terakhir harus diisi'),
  emergency_contact_1: yup.number().typeError('Harap isi dengan angka')
    .required('Kontak darurat 1 harus diisi'),
  emergency_contact_2: yup.number().typeError('Harap isi dengan angka')
    .required('Kontak darurat 2 harus diisi')
    .test('not-same', 'Kontak darurat 2 tidak boleh sama dengan Kontak darurat 1', function(value) {
      return value !== this.parent.emergency_contact_1;
    }),
    file_url: yup.string()
    .nullable()
    .required('Harap unggah file'), 
  passport_number: requiredStringWithMessage('Nomor passport harus diisi'),
  departure_date: yup.date().typeError('Tanggal keberangkatan harus diisi').required('Tanggal keberangkatan harus diisi'),
  blood_type: requiredStringWithMessage('Golongan darah harus diisi'),
  address: yup.string().required('Alamat harus diisi'),
  hajj_info: yup.string().required('Informasi Hajj harus diisi'),
  group_id: yup.number().required('Kloter Perlu Dipilih'),
  date_of_birth: yup.date()
    .typeError('Tanggal lahir harus diisi')
    .required('Tanggal lahir harus diisi'),
})

export const updatePersonalInfoSchema = yup.object().shape({
  name: requiredStringWithMessage('Nama lengkap harus diisi'),
  gender: requiredStringWithMessage('Jenis kelamin harus diisi'),
  place_of_origin: requiredStringWithMessage('Asal daerah harus diisi'),
  job: requiredStringWithMessage('Pekerjaan harus diisi'),
  last_education: requiredStringWithMessage('Pendidikan terakhir harus diisi'),
  emergency_contact_1: yup.number().typeError('Harap isi dengan angka')
    .required('Kontak darurat 1 harus diisi'),
  emergency_contact_2: yup.number().typeError('Harap isi dengan angka')
    .required('Kontak darurat 2 harus diisi')
    .test('not-same', 'Kontak darurat 2 tidak boleh sama dengan Kontak darurat 1', function(value) {
      return value !== this.parent.emergency_contact_1;
    }),
  passport_number: requiredStringWithMessage('Nomor passport harus diisi'),
  departure_date: yup.date().typeError('Tanggal keberangkatan harus diisi').required('Tanggal keberangkatan harus diisi'),
  blood_type: requiredStringWithMessage('Golongan darah harus diisi'),
  address: yup.string().required('Alamat harus diisi'),
  hajj_info: yup.string().required('Informasi Hajj harus diisi'),
  group_id: yup.number().required('Kloter Perlu Dipilih'),
  date_of_birth: yup.date()
    .typeError('Tanggal lahir harus diisi')
    .required('Tanggal lahir harus diisi'),
})

export const updateDeviceIdSchema = yup.object().shape({ //bind device
  device_id: requiredStringWithMessage('Mohon pilih salah satu device'),
  name: requiredStringWithMessage('Nama lengkap harus diisi'),
  age: yup.number().required(),
  gender: requiredStringWithMessage('Jenis kelamin harus diisi'),
  place_of_origin: requiredStringWithMessage('Asal daerah harus diisi'),
  nik: requiredStringWithMessage('NIK harus diisi'),
  date_of_birth: yup.string(),
  blood_type: yup.string(),
  height: yup.number(),
  weight: yup.number(),
  medical_notes: yup.string(),
  address: yup.string(),
  member_id: yup.string(),
  travel_name: yup.string(),
})

export const unbindDeviceIdSchema = yup.object().shape({ //unbind device
  device_id: yup.string().nullable(),
  name: requiredStringWithMessage('Nama lengkap harus diisi'),
  age: yup.number().required(),
  gender: requiredStringWithMessage('Jenis kelamin harus diisi'),
  place_of_origin: requiredStringWithMessage('Asal daerah harus diisi'),
  nik: requiredStringWithMessage('NIK harus diisi'),
  date_of_birth: yup.string(),
  blood_type: yup.string(),
  height: yup.number(),
  weight: yup.number(),
  medical_notes: yup.string(),
  address: yup.string(),
  member_id: yup.string(),
  travel_name: yup.string(),
})

export const importPersonalInfoSchema = yup.object().shape({
  category: requiredStringWithMessage('Kategori Perlu Dipilih'),
  departure_date: yup.date().typeError('Tanggal keberangkatan harus diisi').required('Tanggal keberangkatan harus diisi').typeError('Tanggal keberangkatan harus diisi'),
  group_id: requiredStringWithMessage('Grup Perlu Dipilih'),
  file_url: yup.string().required('Harap unggah file'),
})

export const personalInfoListSchema = yup.array().of(personalInfoSchema)
export type CreatePersonalInfoType = InferType<typeof createPersonalInfoSchema>
export type PersonalInfoType = InferType<typeof personalInfoSchema>
export type UpdateDevicePersonalType = InferType<typeof updateDeviceIdSchema>
export type UnbindDevicePersonalType = InferType<typeof unbindDeviceIdSchema>
