import axios from "axios"
import { groupListSchema } from "../schemas/group"
import { GroupType } from "../models/group";

const Endpoint = "/api/groups"

export const getGroupList = async (category?: string | null | undefined, q?: string | null | undefined) => {
    const res = await axios.get(`${Endpoint}${category && category === null && category === undefined ? "" : `?category=${category}`}${!q && q !== null && q !== undefined ? "" : `?code=${q}`}&size=10&`);
    return await res.data.data as GroupType[]
}

export const getGroupByEmbarkasiId = async (id: number) => {
    const res = await axios.get(`${Endpoint}/${id}`)
    return await groupListSchema.validate(res.data.data)
}