import { Form, Formik, FormikHelpers } from "formik"
import { PageTitle } from "../../../_metronic/layout/core"
import { Accordion } from "react-bootstrap"
import { FormBody } from "../../components/form/FormBody"
import { FormInputAsyncSearch, FormInputDate, FormInputImageV2, FormInputRadio, FormInputSelect, FormInputText, FormInputTextArea } from "../../components/form/FormInputs"
import { AddPersonalInfoType, PersonalInfoType } from "../../models/personalInfo"
import { addPersonalInfoSchema, updatePersonalInfoSchema } from "../../schemas/personalInfo"
import "./buttonadd.css"
import { useGetGroupList } from "../../hooks/groups"
import { useEffect, useState } from "react"
import { optionJob, optionLastEducation } from "../../libs/variables"
import { useNavigate, useParams } from "react-router-dom"
import { useAddPersonalInfo, useGetPersonalInfoById, useUpdatePersonalInfo } from "../../hooks/personalInfo"
import { FormFooterBase } from "../../components/form/FormFooters"
import { OptionType } from "../../models/base"
import { calculateAge, uploadWithPresignedUrl } from "../../libs/function"
import { useAppContext } from "../../../_metronic/helpers/AppContext"
import { getPresignedURL } from "../../api/presignedURL"

/**
 * Render a form for managing Jamaah data.
 *
 * @return {void}
 */

interface QueryInterface {
    query_ou: string
    query_group: string
}

const FormJamaah = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: dataJamaahById } = useGetPersonalInfoById(id);
    const [query, setQuery] = useState<QueryInterface>({
        query_ou: '',
        query_group: '',
    });
    const { data: groupList } = useGetGroupList('umrah', query.query_group);
    const [dataJamaah, setDataJamaah] = useState<PersonalInfoType>();
    const { mutate: addPersonalInfo } = useAddPersonalInfo();
    const { mutate: updatePersonalInfo } = useUpdatePersonalInfo();
    const [optionsGroup, setOptionsGroup] = useState<OptionType[]>([]);
    const [age, setAge] = useState<string>('');
    const [statusElder, setStatusElder] = useState<number>(0);
    const [hajjInfoInput, setHajjInfoInput] = useState<string>('');
    const [filePhoto, setFilePhoto] = useState<File | null>(null);
    const { role, organizationName, subOrganizationName, nameGroup } = useAppContext();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const initFormValue: AddPersonalInfoType = {
        name: '',
        gender: '',
        place_of_origin: '',
        age: '',
        medical_notes: '',
        status_risti: '',
        job: '',
        emergency_contact_1: '',
        emergency_contact_2: '',
        passport_number: '',
        departure_date: '',
        sector: '',
        blood_type: '',
        height: '',
        weight: '',
        address: '',
        hajj_info: '',
        date_of_birth: '',
        last_education: '',
        disability_status: '',
        elderly_status: '',
        file_url: null,
        photo_url: ''
    }

    const filterOptions = async (selectedOption: string, type: string) => {
        const option = type === "job" ? optionJob : optionLastEducation
        option.filter((items) => (items.label.toUpperCase().includes(selectedOption.toUpperCase())));
    }

    const handleValueAsyncSearch = ( type : string, value : string | number ) => {
        setQuery({
            ...query,
            [type]: value as string
        })
    }

    useEffect(() => {
        if (id) {
            setDataJamaah(dataJamaahById ?? undefined);
            setHajjInfoInput(dataJamaahById?.hajj_info ?? '');
            setStatusElder(Number(dataJamaahById?.elderly_status) ?? 0 );
        }
        
    }, [id, dataJamaahById])
    
    

    useEffect(() => {
        if (groupList) {
            const options: OptionType[] = groupList.map((group: any) => ({
                value: group.id,
                label: group.code,
            }));
            setOptionsGroup(options);
        }
    }, [groupList]);

    
    /**
     * Executes when the form is submitted.
     *
     * @param {AddPersonalInfoType} values - The form values.
     * @param {FormikHelpers<AddPersonalInfoType>} action - Formik form helpers.
     * @return {void} No return value.
     */
    const onFormSubmit = async (values: AddPersonalInfoType, action: FormikHelpers<AddPersonalInfoType>) => {
        setIsUploading(true);
        const ageBeforeEdit = await calculateAge(dataJamaahById?.date_of_birth ?? '0');
        const tempAge = ageBeforeEdit.age.toString();
        const rootFolder = process.env.REACT_APP_AWS_S3_FOLDER;
        try {
            const fileExtension = values.file_url ? values.file_url.name.split('.').pop()?.toLowerCase() : 'jpg';
            let photoUrl = dataJamaahById?.photo_url ?? '';
            if (filePhoto !== null) {
                const nameFile = `${rootFolder}/personal_info/${organizationName.toLowerCase().replace(/\s+/g, '-')}/${subOrganizationName.toLowerCase().replace(/\s+/g, '-')}/${hajjInfoInput}.${fileExtension}`;
                const presignedURL = await getPresignedURL(nameFile);
                const url = presignedURL.data.split('.com')[0] + '.com'; 
                await uploadWithPresignedUrl(filePhoto, presignedURL.data );
                photoUrl = (`${url}/${nameFile}`);
            }
            const mappedValues: AddPersonalInfoType = {
                ...values,
                age: age !== '' ? age : tempAge,
                elderly_status: statusElder.toString(),
                file_url: filePhoto,
                photo_url: photoUrl
            }
            id ? updatePersonalInfo({ id: id, data: mappedValues }) : addPersonalInfo({ data: mappedValues });
        } catch (error) {
            console.log(error)
        } finally {
            setIsUploading(false);
            action.resetForm();
            navigate('/jamaah')
        }
    }
    return (
        <>
            <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path:  '/jamaah',
                description: !id ? "Tambah Jamaah" : "Ubah Jamaah",
                isActive: true,
                isSeparator: false,
            }]}>{!id ? "Tambah Jamaah" : "Ubah Jamaah"}</PageTitle>
            <Formik
                initialValues={initFormValue}
                validationSchema={id ? updatePersonalInfoSchema : addPersonalInfoSchema}
                onSubmit={onFormSubmit}
            >
                <Form className=' mb-5 mb-xl-10 '>
                    <Accordion defaultActiveKey={'0'} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Data Haji / Umroh</Accordion.Header>
                            <Accordion.Body className="card ">
                                <FormBody>
                                    <FormInputDate name="departure_date" labelName="Tgl. Keberangkatan" value={dataJamaah?.departure_date ?? ''} required={true} />
                                    <FormInputAsyncSearch
                                        name="group_id"
                                        labelName={'Kloter / Grup'}
                                        value={dataJamaah?.group?.id}
                                        label={dataJamaah?.group?.code}
                                        options={optionsGroup}
                                        onValueChange={(e) => handleValueAsyncSearch("group", e)}
                                        required={true}
                                    />
                                </FormBody>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey={'1'} className="mt-6" alwaysOpen>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Data Jamaah</Accordion.Header>
                            <Accordion.Body className="card">
                                <FormBody>
                                    <FormInputText name="name" labelName="Nama" value={dataJamaah?.name ?? ''} required={true} />
                                    <FormInputText 
                                    name="hajj_info" 
                                    labelName="ID Jamaah" 
                                    value={dataJamaah?.hajj_info ?? ''} 
                                    required={true} 
                                    onValueChange={(e) => {
                                        setHajjInfoInput(e as string);
                                    }}
                                    />
                                    <FormInputText name="passport_number" labelName="No. Paspor" value={dataJamaah?.passport_number ?? ''} required={true} />
                                    <FormInputImageV2 name="file_url" labelName="Foto" typeInput="image" required={true} photoValue={dataJamaah?.photo_url ?? ''} setFile={setFilePhoto} />

                                    <FormInputRadio
                                        name="gender"
                                        labelName="Jenis Kelamin"
                                        value={dataJamaah?.gender ?? ''}
                                        values={['Pria', 'Wanita']}
                                        valuesLabel={['Pria', 'Wanita']}
                                        checkedValue={!dataJamaah?.gender ? '' : dataJamaah?.gender === 'male' ? 'Pria' : 'Wanita'}
                                        required={true}
                                    />
                                    <FormInputDate 
                                    name="date_of_birth" 
                                    labelName="Tanggal Lahir" 
                                    value={dataJamaah?.date_of_birth ?? ''} 
                                    onValueChange={async (e) => {
                                        const tempAge = await calculateAge(String(e)); 
                                        setAge(tempAge?.age?.toString() ?? '0');
                                        setStatusElder(tempAge?.elder);
                                    }}
                                    required={true}
                                    />
                                    <FormInputRadio
                                        name="blood_type"
                                        labelName="Golongan Darah"
                                        value={dataJamaah?.blood_type ?? ''}
                                        values={['A', 'B', 'AB', 'O']}
                                        valuesLabel={['A', 'B', 'AB', 'O']}
                                        checkedValue={dataJamaah?.blood_type ?? ''}
                                        required={true}
                                    />
                                    <FormInputText name="place_of_origin" labelName="Asal kota / Daerah" value={dataJamaah?.place_of_origin ?? ''} required={true}/>
                                    <FormInputTextArea name="address" labelName="Alamat" value={dataJamaah?.address ?? ''} required={true}/>
                                    <FormInputAsyncSearch
                                        name="job"
                                        labelName="Pekerjaan"
                                        value={dataJamaah?.job}
                                        label={dataJamaah?.job}
                                        options={optionJob}
                                        onValueChange={(e) => {
                                            filterOptions(e as string, "job")
                                        }}
                                        required={true}
                                    />
                                    <FormInputAsyncSearch
                                        name="last_education"
                                        labelName="Pendidikan Terakhir"
                                        value={dataJamaah?.last_education}
                                        label={dataJamaah?.last_education}
                                        options={optionLastEducation}
                                        onValueChange={(e) => {
                                            filterOptions(e as string, "education")
                                        }}
                                        required={true}
                                    />
                                    <FormInputText name="emergency_contact_1" labelName="Kontak Darurat 1" value={dataJamaah?.emergency_contact_1 ?? ''} required={true}/>
                                    <FormInputText name="emergency_contact_2" labelName="Kontak Darurat 2" value={dataJamaah?.emergency_contact_2 ?? ''} required={true}/>
                                </FormBody>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Accordion defaultActiveKey={'2'} className="mt-6" alwaysOpen>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Data Kesehatan</Accordion.Header>
                            <Accordion.Body className="card">
                                <FormBody>
                                    <FormInputTextArea name="medical_notes" labelName="Catatan Dokter" value={dataJamaah?.medical_notes ?? ''} required={false} />
                                    <FormInputSelect name="status_risti" labelName="Status Risti" value={dataJamaah?.status_risti ?? ''} required={true}>
                                        <option value={''}>Pilih...</option>
                                        <option value={0}>Tidak Risti</option>
                                        <option value={1}>Risti</option>
                                    </FormInputSelect>
                                    <FormInputSelect name="disability_status" labelName="Status Disabilitas" value={dataJamaah?.disability_status ?? ''} required={true}>
                                        <option value={''}>Pilih...</option>
                                        <option value={0}>Tidak</option>
                                        <option value={1}>Disabilitas</option>
                                    </FormInputSelect>
                                    <FormInputText name="weight" labelName="Berat Badan" value={dataJamaah?.weight ?? ''} required={false} />
                                    <FormInputText name="height" labelName="Tinggi Badan" value={dataJamaah?.height ?? ''} required={false} />
                                </FormBody>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="mt-5">
                        <FormFooterBase linkTo={"/jamaah"} confirmText={`${isUploading ? 'Loading...' : 'Simpan'}`} isloading={isUploading}/>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

export default FormJamaah
