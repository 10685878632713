const Download = async (fileName: string, typeInput: string) => {
    const fileUrl = `https://hija.s3.ap-southeast-1.amazonaws.com/${fileName}`
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName); // Tentukan nama file di atribut 'download'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

export default Download