import React from 'react';
import './Battery.css';


interface BatteryIconProps {
  battery: number;
}

const BatteryIcon: React.FC<BatteryIconProps> = ({ battery }) => {
  let batteryIcon = '';
  let batteryColor = '';
  if (battery >= 80) {
    batteryIcon = 'bi-battery-full'; 
    batteryColor = 'text-black';
  } else if (battery >= 21) {
    batteryIcon = 'bi-battery-half'; 
  } else if (battery >= 19) {
    batteryIcon = 'bi-battery'; 
    batteryColor = 'text-black';
  } else {
    batteryIcon = 'bi-battery text-danger battery-blink'; 
    batteryColor = 'text-danger ';
  }

   return (
  <>
      <i className={`bi ${batteryIcon} fs-3 fw-bold ml-2`}></i>&nbsp;
      <div className={`fw-bold mr-2 ${batteryColor}`}>{battery}%</div>
  </>
  );
};

export default BatteryIcon;
