import axios from 'axios'
import {Base, BaseQuery} from '../models/base'
import {DeviceHistoryType} from '../models/deviceHIstory'

const Endpoint = '/api/device_histories'
export const getDeviceHistories = async (query: BaseQuery) => {
  const { device_id, alarm_type, date, search, page, limit, time_zone } = query
  const statusQueryMap: { [key: string]: string } = {
    sos: 'alarm_type=sos&status=new',
    handled: 'alarm_type=sos&status=in_progress',
    done: 'status=done&status=canceled',
    geofence: 'status=geofence_out',
    donesos: 'status=done&status=canceled&alarm_type=sos',
    donegeofence: 'alarm_type=geofence_out&status=done&status=canceled',
    sossos: 'alarm_type=sos&status=new',
    sosgeofence: 'alarm_type=geofence_out&status=new',
    handledsos: 'alarm_type=sos&status=in_progress',
    handledgeofence: 'alarm_type=geofence_out&status=in_progress'
  };
  
  let statusQuery: string = '';
  
  if (alarm_type && statusQueryMap.hasOwnProperty(alarm_type)) {
    statusQuery = statusQueryMap[alarm_type];
  }
  const dateStart = `${date} 00:00:00`;
  const dateEnd = `${date} 23:59:59`;
  const res = await axios.get(
    `${Endpoint}?${device_id !== '' && device_id !== undefined ? `device_id=${device_id}&` : ''}${statusQuery? `${statusQuery}&` : ''}${
      date !== '' && date !== undefined ? `date=${dateStart},${dateEnd}&` : ''
    }${time_zone ? `time_zone=${time_zone}&` : ''}${search ? `q=${search}&` : ''}${page? `page=${page}&` : ''}${limit?`limit=${limit}&` : ''}`
  )
  return res.data.data.rows as DeviceHistoryType[]
}


export const openDeviceHistories = async (q: string) => {
  const data = {
    is_open: true,
  }
  return await axios.put<Base<DeviceHistoryType>>(
    `${Endpoint}/opened${q !== '' ? `?status=${q}` : ''}`,
    data
  )
}
export const updateDeviceNotification = async (id: number) => {
  return await axios.put(`${Endpoint}/${id}`);
}

