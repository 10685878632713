import { Form, Formik, FormikHelpers } from "formik"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageTitle } from "../../../_metronic/layout/core"
import { FormBody } from "../../components/form/FormBody"
import { FormInputDate, FormInputFile, FormInputKloter, FormInputRadio } from "../../components/form/FormInputs"
import { ImportPersonalInfoType } from "../../models/personalInfo"
import { importPersonalInfoSchema } from "../../schemas/personalInfo"
import { FormFooterBase } from "../../components/form/FormFooters"
import { useAddJamaahMassal } from "../../hooks/personalInfo"
import { useEffect, useState } from "react"
import { useGetGroupList } from "../../hooks/groups"
import { useNavigate } from "react-router-dom"
import { OptionType } from "../../models/base"
import { uploadWithPresignedUrl } from "../../libs/function"
import { useAppContext } from "../../../_metronic/helpers/AppContext"
import { getPresignedURL } from "../../api/presignedURL"

/**
 * Component for importing Jamaah data in bulk.
 */
const ImportDataJamaah = () => {
    const navigate = useNavigate();
    const { mutate: addJamaahMassal, isLoading } = useAddJamaahMassal();
    const [ selectedCategory, setSelectedCategory ] = useState<string>('');
    const [ optionsGroup, setOptionsGroup ] = useState<OptionType[]>([]);
    const { data: groupList } = useGetGroupList(selectedCategory);
    const [ selectedFile, setSelectedFile ] = useState<File | null>(null);
    const { organizationName, subOrganizationName } = useAppContext();
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const initFormValue: ImportPersonalInfoType = {
        file_url: null,
        category: '',
        departure_date: '',
        group_id: 0
    }

    // Update group options when groupList changes
    useEffect(() => {
        if (groupList) {
            const options: OptionType[] = groupList.map((group: any) => ({
                value: group.id,
                label: group.code,
            }));
            setOptionsGroup(options);
        }
    }, [groupList]);

    // Handle category selection change
    const handleInputCategory = (value: string) => {
        setSelectedCategory(value);
    } 

    const onFormSubmit = async (values: ImportPersonalInfoType, action: FormikHelpers<ImportPersonalInfoType>) => {
        setIsUploading(true);
        const rootFolder = process.env.REACT_APP_AWS_S3_FOLDER;
        const fileExtension = selectedFile ? selectedFile.name.split('.').pop()?.toLowerCase() : 'xlsx';
        const fileName = `${rootFolder}/import_logs/${organizationName.toLowerCase().replace(/\s+/g, '-')}/${subOrganizationName.toLowerCase().replace(/\s+/g, '-')}/Upload.${fileExtension}`;
        const presignedURL = await getPresignedURL(fileName);
        await uploadWithPresignedUrl(selectedFile, presignedURL.data );
        const url = presignedURL.data.split('.com')[0] + '.com'; 
        const fileUrl = (`${url}/${fileName}`);
        delete values.file;
        const mappedValues = {
            ...values,
            file_url: fileUrl
        };
        
        try {
            await addJamaahMassal({ data: mappedValues });
            action.resetForm();
            navigate('/jamaah');
            setIsUploading(false);
        } catch (error) {
            console.log(error);
            setIsUploading(false);
        }
    };
    
    
    return (
        <>
            <PageTitle breadcrumbs={[{
                title: 'Jamaah',
                path: '/jamaah',
                description: "Tambah Jama'ah",
                isSeparator: false,
                isActive: true
            }]}>Tambah Jama'ah</PageTitle>
            <KTCard className='p-5 shadow-sm'>
                <div className="card-title d-flex justify-content-between">
                    <div
                        data-bs-target='#kt_account_profile_details'
                        aria-controls="kt_account_profile_details">
                        <div className="card-title m-0 d-flex flex-column">
                            <div className='d-flex align-items-center position-relative my-1'>
                                <h3 className='m-0'>Tambah Import Masal</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <KTCardBody>
                    <Formik
                        initialValues={initFormValue}
                        validationSchema={importPersonalInfoSchema}
                        onSubmit={onFormSubmit}
                    >
                        <Form>
                            <FormBody>
                                <FormInputRadio
                                        name="category"
                                        labelName="Kategori"
                                        values={['hajj', 'umrah']}
                                        valuesLabel={['Haji', 'Umroh']}
                                        checkedValue={'hajj'}
                                        onSelectedValueChange={handleInputCategory}
                                    />
                                    <FormInputDate name="departure_date" labelName="Tgl. Keberangkatan" value='' required={true}/>
                                     <FormInputKloter
                                        name="group_id"
                                        labelName={selectedCategory === 'hajj' ? 'Kloter' : 'Group'}
                                        options={optionsGroup}
                                        required={true}
                                    >
                                    </FormInputKloter>               
                                <FormInputFile name="file_url" labelName="File" meta={{ error: 'File', touched: false }} typeInput="jamaah" onValueChange={(e) => setSelectedFile(e)}/>
                            </FormBody>
                            <FormFooterBase linkTo="/jamaah" confirmText={isUploading ? 'Loading...' : 'Simpan'} isloading={isUploading || isLoading}/>
                        </Form>
                    </Formik>
                </KTCardBody>
            </KTCard>
        </>
    )
}

export default ImportDataJamaah
