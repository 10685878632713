import React, {useEffect, useState} from 'react'
import Map, {Marker, Source, Layer} from 'react-map-gl'
import {Feature, Polygon} from 'geojson'
import MapBoxGL from '../../../components/map-box/MapBoxGl'
import createGeofenceCircle from '../../../components/map-box/createGeofenceCircle'

type GeofenceCircle = Feature<
  Polygon,
  {
    radius: number
    center: [number, number]
  }
>

interface CreateGeofenceMapsProps {
  lat: number
  lng: number
  onPositionChange: (lat: number, lng: number, radius: number) => void
}

const CreateGeofenceMaps = ({lng, lat, onPositionChange}: CreateGeofenceMapsProps) => {
  const [center, setCenter] = useState<[number, number]>([lng, lat]) // Pusat awal lingkaran
  const [radius, setRadius] = useState<number>(250) // Radius dalam meter
  const [geoJsonCircle, setGeoJsonCircle] = useState<GeofenceCircle | null>(null)

  useEffect(() => {
    setCenter([lng, lat])
  }, [lng, lat])

  useEffect(() => {
    const circle = createGeofenceCircle(center, radius)
    setGeoJsonCircle(circle)
  }, [center, radius])

  // Fungsi untuk menangani perubahan posisi marker
  const handleMarkerDrag = (event: any) => {
    const newCenter: [number, number] = [event.lngLat.lng, event.lngLat.lat]
    onPositionChange(event.lngLat.lat, event.lngLat.lng, radius)
    setCenter(newCenter)
  }

  const handleRadiusChange = (value: number) => {
    const allowedValues = [100, 250, 500, 750, 1000]
    const closestValue = allowedValues.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    )
    onPositionChange(center[1], center[0], closestValue)
    setRadius(closestValue)
  }

  const increaseRadius = () => {
    const allowedValues = [100, 250, 500, 750, 1000]
    const currentIndex = allowedValues.indexOf(radius)
    if (currentIndex < allowedValues.length - 1) {
      setRadius(allowedValues[currentIndex + 1])
    }
  }

  const decreaseRadius = () => {
    const allowedValues = [100, 250, 500, 750, 1000]
    const currentIndex = allowedValues.indexOf(radius)
    if (currentIndex > 0) {
      setRadius(allowedValues[currentIndex - 1])
    }
  }
  return (
    <div className='w-100 h-350px'>
      <MapBoxGL center={{lat: center[1], lng: center[0]}} searchBox>
        {geoJsonCircle && (
          <Source id='geofence' type='geojson' data={geoJsonCircle}>
            <Layer
              id='geofence-layer'
              type='fill'
              paint={{
                'fill-color': '#FF0000',
                'fill-opacity': 0.4,
              }}
            />
          </Source>
        )}

        <Marker
          longitude={center[0]}
          latitude={center[1]}
          draggable
          onDragEnd={handleMarkerDrag} // Memperbarui center saat marker digeser
        >
          <div className='bg-white bg-opacity-50 rounded-circle p-2'>
            <i className='bi bi-arrows-move text-black fs-1 fw-bold'></i>
          </div>
        </Marker>
        {/* Kontrol untuk memperbesar atau memperkecil radius */}
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            right: '50%',
            transform: 'translateX(-50%)',
            width: '300px',
            borderRadius: '10px',
            background: 'white',
            padding: '10px',
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <label className='me-2'>
            {radius >= 1000 ? `Radius: ${radius / 1000} km` : `Radius: ${radius} m`}
          </label>
          
          <input
            type='range'
            min='100'
            max='1000'
            value={radius}
            onChange={(e) => handleRadiusChange(Number(e.target.value))}
          />
          <i className="bi bi-dash-lg mx-2 text-black" onClick={decreaseRadius}></i>
          <i className="bi bi-plus-lg me-2 text-black" onClick={increaseRadius}></i>
        </div>
      </MapBoxGL>
    </div>
  )
}

export default CreateGeofenceMaps
