import React from "react";
import { Badge } from "react-bootstrap";

const BadgeAlarm: React.FC<{ alarm: string }> = ({ alarm }) => {
   return (
      <>
         {alarm === 'in' ? <>
            <Badge bg='light-success'>
               <div style={{ color: '#58d18f', fontWeight: "bold" }}>
                  Masuk Zona
               </div>
            </Badge>
         </> : alarm === 'out' ? <>
            <Badge bg='light-danger'>
            <div style={{ color: '#f0406c', fontWeight: "bold" }}>
                  Keluar Zona
               </div>
            </Badge>
         </> : <Badge bg="light-warning" color="#f0bd01">
         <div style={{ color: '#F7cb13', fontWeight: "bold" }}>
                  Masuk Keluar Zona
               </div>
         </Badge>
         }
      </>
   )
}
export default BadgeAlarm