import axios from "axios"
import { DashboardDataGeofenceType, DashboardDataSOSType } from "../models/dashboard"
import { Base } from "../models/base"

const endpoint = "/api/data_statistic"

export const getDataSOS = async () => {
    const res = await axios.get<Base<DashboardDataSOSType>>(`${endpoint}/sos`)
    return res.data.data as DashboardDataSOSType
}

export const getDataStatisticGeofence = async () => {
    const res = await axios.get<Base<DashboardDataGeofenceType>>(`${endpoint}/geofence_activity`)
    return res.data.data as DashboardDataGeofenceType
}