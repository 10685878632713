import axios from "axios"
import { Base } from "../models/base"
import { UserNotificationType } from "../models/userNotifications"

const Endpoint = '/api/user_notifications'

export const getUserNotificationList = async () => {
    const res = await axios.get<Base<UserNotificationType[]>>(
        `${Endpoint}`
    )
    return (res.data.data as UserNotificationType[])
}

export const updateUserNotification = async (id: number) => {
    return await axios.put(`${Endpoint}/${id}`)
}