import * as yup from 'yup'
export const groupSchema = yup.object().shape({
    id: yup.number(),
    embarkation_id: yup.number(),
    code: yup.string(),
    created_at: yup.string(),
    updated_at: yup.string()
})

export const groupListSchema = yup.array().of(groupSchema)
