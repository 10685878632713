import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {IControl, MapInstance, useControl} from 'react-map-gl'

const Geocoder = ({onResult}: {onResult: (result: any) => void}) => {
  const ctrl = new MapBoxGeocoder({
    accessToken: process.env.REACT_APP_MAPS_KEY as string,
    marker: false,
    collapsed: false,
    types: 'country,region,place,postcode,locality,neighborhood',
    language: 'id',
    reverseGeocode: true,
    zoom: 14,
    placeholder: 'Cari',
  })

  useControl(() => ctrl as IControl<MapInstance>)
  ctrl.on('result', (e) => {
    const coords = e.result.geometry.coordinates
    onResult({
      lat: coords[1],
      lng: coords[0],
    })
  })
  return null
}

export default Geocoder
