import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { parseJwt } from '../../../utils/converter'
import { AuthModel, UserModel } from './_models'
import axios from 'axios'
import { userLogout } from '../../../api/auth'
import { issueAccessToken } from '../../../api/refreshToken'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async() => {
    if (auth && auth?.refresh_token) {
      try {
        await userLogout(auth?.refresh_token)
      } catch (error) {
        localStorage.removeItem('auth')
        console.error(error)
      }
    }
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (auth && auth?.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth?.token}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

// ...

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, saveAuth } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (auth: AuthModel) => {
      try {
        if (!didRequest.current) {
          const data = parseJwt(auth)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          try {
            const { data } = await issueAccessToken(auth?.refresh_token || '')
            const authInfo: AuthModel = { ...data.data }
            saveAuth(authInfo)
            setCurrentUser(parseJwt(authInfo))
          } catch (error) {
            console.error('Failed to refresh token', error)
            logout()
          }
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth?.token) {
      requestUser(auth)
    } else {
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

// ...

export { AuthProvider, AuthInit, useAuth }
