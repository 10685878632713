import axios from "axios";
import { AuthResponseModel, CustomAxiosRequestConfig } from "../modules/auth";
const API_URL = process.env.REACT_APP_TRACKER_API_URL


export async function issueAccessToken(refreshToken: string) {
    return await axios.post<AuthResponseModel>(`${API_URL}/api/auth/issue_access_token`, 
      { refresh_token: refreshToken },
      { skipAuthRefresh: true } as CustomAxiosRequestConfig
    )
  }