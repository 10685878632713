import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {
  addPersonalInfo,
  deletePersonalInfoById,
  getPersonalInfoById,
  getPersonalInfoList,
  unbindDevicesByGroupId,
  unbindDevicePersonalById,
  updateDevicePersonalById,
  updatePersonalInfoById,
  getAllPersonalInfo,
  addJamaahMassal,
  getLogRows,
  getLogProgress,
} from '../api/personalInfo'
import {AxiosError} from 'axios'
import {AddPersonalInfoType, ImportPersonalInfoType, PersonalInfoType, unbindDevicesByGroupIdType} from '../models/personalInfo'
import {toast} from 'react-toastify'
import {UnbindDevicePersonalType, UpdateDevicePersonalType} from '../schemas/personalInfo'
import {Base, BaseQuery} from '../models/base'
import Swal from 'sweetalert2'
import {useGetListData} from './helpers/useGetData'
import {useState} from 'react'
import { useAppContext } from '../../_metronic/helpers/AppContext'

const _PERSONAL_INFO = 'personal-info'

// const _IMPORT_JAMAAH_MASSAL = 'personal-info'
/**
 * Fetches personal information list based on the given query.
 *
 * @param {BaseQuery} query - The query object containing parameters for fetching personal information list.
 * @return {unknown} The result of the fetch operation.
 */

export const useGetPersonalInfoList = (query: BaseQuery) => {
  return useGetListData({
    queryFn: getPersonalInfoList,
    queryKey: _PERSONAL_INFO,
    query,
  })
}

export const useGetAllPersonalInfo = () => {
  return useQuery(
    [_PERSONAL_INFO],
    async () => {
      return await getAllPersonalInfo()
    },
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  )
}

/**
 * Retrieves personal information by ID.
 *
 * @param {string} id - The ID of the personal information.
 * @return {Promise<unknown>} - A promise that resolves to the personal information.
 */
export const useGetPersonalInfoById = (id?: string) => {
  return useQuery(
    [_PERSONAL_INFO, id],
    async () => {
      if (!id) {
        return null
      } else {
        return await getPersonalInfoById(id)
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )
}

/**
 * This function is a custom React hook that is used to add personal information.
 *
 * @return {MutationFunction} The mutation function that handles the addition of personal information.
 */
export const useAddPersonalInfo = () => {
  const queryClient = useQueryClient()
  let result
  return useMutation(
    async ({data}: {data: AddPersonalInfoType}) => {
      result = await addPersonalInfo(data)
      return result
    },
    {
      /**
       * Executes the onSuccess callback function.
       *
       * @param {() => void} onSuccess - The callback function to be executed on success.
       * @return {void} This function does not return a value.
       */
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          text: "Data Jama'ah Berhasil Ditambahkan",
          showConfirmButton: false,
          timer: 1500,
        })
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
      /**
       * Handles the error that occurs during the API call.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The error object containing the error response.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        Swal.fire({
          icon: 'error',
          title: "Data Jama'ah Gagal Ditambahkan",
          text: `${err.response?.data.error}`,
          showConfirmButton: false,
          timer: 1500,
        })
        console.error('Error -> ', err)
      },
    }
  )
}

/**
 * A hook that returns a mutation function for updating personal information.
 *
 * @return {Function} The mutation function.
 */
export const useUpdatePersonalInfo = () => {
  const queryClient = useQueryClient()
  let result
  return useMutation(
    async ({id, data}: {id: string; data: AddPersonalInfoType}) => {
      result = await updatePersonalInfoById(id, data)
      return result
    },
    {
      /**
       * Executes the onSuccess callback function.
       *
       * @param {void} - No parameters required
       * @return {void} - No return value
       */
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          text: "Data Jama'ah Berhasil Diubah",
          showConfirmButton: false,
          timer: 1500,
        })
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
      /**
       * Handles the error that occurs during the execution of the function.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The error object containing information about the error.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        Swal.fire({
          icon: 'error',
          title: "Data Jama'ah Gagal Diubah",
          text: `${err.response?.data.error}`,
          showConfirmButton: false,
          timer: 1500,
        })
        console.error('Error -> ', err)
      },
    }
  )
}

/**
 * Creates a custom React hook that updates the personal information of a device by its ID.
 *
 * @returns {MutationFunction} The mutation function.
 */
export const useUpdateDevicePersonalInfoById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({id, data}: {id: string; data: UpdateDevicePersonalType}) => {
      return await updateDevicePersonalById(id, data)
    },
    {
      /**
       * Callback function to handle successful update.
       *
       * @return {void}
       */
      onSuccess: () => {
        toast.success('Update success')
        queryClient.invalidateQueries([_PERSONAL_INFO])
        queryClient.invalidateQueries(['device-list']) //use for refetch api deviceList
      },
      /**
       * Handles the error that occurs during the API call.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The error object that occurred during the API call.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
      },
    }
  )
}

/**
 * Generates a function comment for the given function body in a markdown code block with the correct language syntax.
 *
 * @return {void}
 */
export const useDeletePersonalInfoById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return await deletePersonalInfoById(id)
    },
    {
      /**
       * Executes the onSuccess callback function.
       *
       * @param {function} onSuccess - The callback function to be executed.
       * @return {void} This function does not return anything.
       */
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          text: "Data Jama'ah Berhasil Dihapus",
          showConfirmButton: false,
          timer: 1500,
        })
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
      /**
       * Handles the error response from the API request.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The error object containing the response data.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        Swal.fire({
          icon: 'error',
          title: "Data Jama'ah Gagal Dihapus",
          text: `${err.response?.data.error}`,
          showConfirmButton: false,
          timer: 1500,
        })
        console.error('Error -> ', err)
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
    }
  )
}

/**
 * Generates a function comment for the given function body.
 *
 * @return {MutationFunction} The generated function comment.
 */
export const useUnbindDevicePersonalInfoById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({id, data}: {id: string; data: UnbindDevicePersonalType}) => {
      return await unbindDevicePersonalById(id, data)
    },
    {
      /**
       * Executes the onSuccess callback function. Displays a success toast message,
       * invalidates the queries for personal info and the device list, and triggers a refetch
       * of the device list.
       *
       * @param {type} paramName - description of parameter
       * @return {type} description of return value
       */
      onSuccess: () => {
        toast.success('Update success')
        queryClient.invalidateQueries([_PERSONAL_INFO])
        queryClient.invalidateQueries(['device-list']) //use for refetch api deviceList
      },
      /**
       * Handle the error response from an Axios request.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The Axios error object.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
      },
    }
  )
}

/**
 * Returns a mutation function that unbinds a device by group ID.
 *
 * @return {MutationFunction} The mutation function.
 */
export const useUnbindDevicesByGroupId = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({data}: {data: unbindDevicesByGroupIdType}) => {
      return await unbindDevicesByGroupId(data)
    },
    {
      /**
       * Executes when the API call is successful.
       *
       * @param {type} paramName - description of parameter
       * @return {type} description of return value
       */
      onSuccess: () => {
        toast.success('Perangkat Berhasil Diputus')
        queryClient.invalidateQueries([_PERSONAL_INFO])
        queryClient.invalidateQueries(['device-list']) //use for refetch api deviceList
      },
      /**
       * Handle error callback function.
       *
       * @param {AxiosError<Base<PersonalInfoType>>} err - The error object.
       */
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        toast.error(`${err.response?.data.message}`)
        console.log(err)
      },
    }
  )
}

/**
 * Hook to add Jamaah Massal.
 *
 * @return {Object} The object containing the mutation function, loading state, and import ID.
 */
export const useAddJamaahMassal = () => {
  const { setJamaahImportId } = useAppContext();
  const queryClient = useQueryClient();
  const {mutate, isLoading} = useMutation(
    async ({data}: {data: ImportPersonalInfoType}) => {
      const response = await addJamaahMassal(data)
      setJamaahImportId(response?.data.data?.import_id ?? '');
      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_PERSONAL_INFO]);
      },
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        Swal.fire({
          icon: 'error',
          title: "Data Jama'ah Gagal Di tambahkan",
          text: `${err.response?.data.error}`,
          showConfirmButton: false,
          timer: 1500,
        })
        console.error('ERROR_ADD_JAMAAH_MASSAL: ', err)
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
    }
  )
  return {mutate, isLoading}
}

/**
 * Hook to get log rows.
 *
 * @return {Object} The object containing the mutation function and loading state.
 */
export const useGetLogRows = () => {
  const { setDataJamaahRows } = useAppContext();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    async ({id}: {id: string}) => {
      const result = await getLogRows(id)
      setDataJamaahRows(result?.data?.imported_rows)
      return result
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
      onError: (err: AxiosError<Base<PersonalInfoType>>) => {
        console.error('Error -> ', err)
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
    }
  )
  return { mutate, isLoading }
}

/**
 * Hook to get log progress.
 *
 * @return {Object} The object containing the mutation function, loading state, toast state, and setToast function.
 */
export const useGetLogProgress = () => {
  const queryClient = useQueryClient()
  const [toast, setToast] = useState({isOpen: false, message: '', type: 'pending'})
  const {mutate, isLoading} = useMutation(
    async ({id}: {id: string}) => {
      const result = await getLogProgress(id)
      return result
    },
    {
      onSuccess: (result, {id}) => {
        queryClient.invalidateQueries([_PERSONAL_INFO])
        const { status } = result.data;
        let message: string, type: string;
        if (status === 'in_progress') {
          const interval = setInterval(async () => {
            const updatedResult = await getLogProgress(id)
            const { imported_count, failed_count, total_rows, status } = updatedResult.data
            if (status === 'completed') {
              clearInterval(interval)
              if (failed_count === total_rows) {
                message = `${failed_count} dari ${total_rows} Jamaah Tersimpan`;
                type = 'error'
              } else if (imported_count === total_rows) {
                message = `${imported_count} dari ${total_rows} Jamaah Tersimpan`;
                type = 'success';
              }
              else if (imported_count >= 0) {
                message = `${imported_count} dari ${total_rows} Jamaah Tersimpan`;
                type = 'warning'
              } 
              setToast({
                isOpen: true,
                message: message,
                type: type
              })
            } else {
              setToast({
                isOpen: true,
                message: `${imported_count} dari ${total_rows} Jamaah Tersimpan`,
                type: 'pending',
              })
            }
          }, parseInt(process.env.REACT_APP_INTERVAL_BATCH_UPLOADS as string))
        } else if (status === 'completed') {
          queryClient.invalidateQueries([_PERSONAL_INFO])
          if (result.data.failed_count === result.data.total_rows) {
            setToast({
              isOpen: true,
              message: `0 dari ${result.data.total_rows} Jamaah Tersimpan`,
              type: 'error',
            })
          } else if (result.data.imported_count === result.data.total_rows) {
            setToast({
              isOpen: true,
              message: `${result.data.imported_count} dari ${result.data.total_rows} Jamaah Tersimpan`,
              type: 'success',
            })
          } else if (result.data.imported_count >= 0) {
            setToast({
              isOpen: true,
              message: result.data.total_rows >= 0 ? `${result.data.imported_count} dari ${result.data.total_rows} Jamaah Tersimpan` : `Gagal menyimpan data`,
              type: 'warning'
            })
          }
        }
      },
      onError: (err) => {
        console.error('Error -> ', err)
        queryClient.invalidateQueries(['_PERSONAL_INFO'])
        setToast({
          isOpen: true,
          message: 'Gagal mengimport data',
          type: 'error',
        })
      },
    }
  )
  return {mutate, isLoading, toast, setToast}
}
