import { Link } from "react-router-dom"
import { KTCard, KTCardBody, KTIcon } from "../../../_metronic/helpers"
import { PageTitle } from "../../../_metronic/layout/core"
import { useGetConfigurationKloterList } from "../../hooks/configurationKloter"
import moment from "moment"
import ServerTable, { useServerTable } from "../../components/table/ServerTable"
import { createColumnHelper } from "@tanstack/react-table"
import { ConfigKloterType } from "../../models/configurationKloter"
import { useEffect, useState } from "react"
import { DeviceManagementFilter } from "../../modules/filter/DeviceManagementFilter"
import { useAppContext } from "../../../_metronic/helpers/AppContext"
import ModalOrganizationUnitDetail from "../organizationUnit/modal/ModalOrganizationUnitDetail"

const ConfigurationKloterPage = () => {
    const [selectedEmbarkation, setSelectedEmbarkation] = useState<number>(0);
    const [selectedGroup, setSelectedGroup] = useState<number>(0);
    const [selectedOrganizationId, setSelectedOrganizationId ] = useState<number>(0);
    const [selectedGroupId, setSelectedGroupId ] = useState<number>(0);
    const [selectedSubOrganizationId, setSelectedSubOrganizationId ] = useState<number>(0);
    const [showModalDetailOU, setShowModalDetailOU] = useState<boolean>(false);
    const [IdOU,setIdOU] = useState<number>(0);

    const {
        pageIndex,
        pageSize,
        filterValue,
        sorting,
        pagination,
        setFiltering,
        setPagination,
        setSorting,
    } = useServerTable();
    const { data: listConfigKloter } = useGetConfigurationKloterList({
        q: filterValue,
        pageIndex,
        pageSize,
        sortBy: sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(),
        embarkation_id: selectedEmbarkation,
        group_id: selectedGroupId,
        organization_unit_id: selectedOrganizationId,
        sub_organization_unit_id: selectedSubOrganizationId,

    });
    const { role, organizationName, subOrganizationName, nameGroup } = useAppContext();
    const columnHelper = createColumnHelper<ConfigKloterType>();

    useEffect(() => {
        setPagination({ pageIndex: 0, pageSize: 10 })
    },[filterValue, selectedEmbarkation, selectedGroupId, selectedOrganizationId, selectedSubOrganizationId,setPagination]);

    const columns = [
        columnHelper.accessor(() => 'itemNumber', {
            id: 'itemNumber',
            header: () => <div className="text-center">#</div>,
            cell : info => (
                <div className="text-center">{pageSize * pageIndex + info.row.index + 1}</div>
            ),
            enableSorting: false
        }),
        columnHelper.accessor(() => 'organization_unit' , {
            id: 'organization_unit_id',
            header: () => <div className="text-left" hidden={role !== 1}>UNIT ORGANISASI</div>,
            cell: info => (
                <div className="text-left cursor-pointer text-decoration-underline" hidden={role !== 1} onClick={() => {setShowModalDetailOU(true); setIdOU(info.row.original?.organization_unit?.id ?? 0)}}>{info.row.original?.organization_unit?.name} </div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'sub_organization_unit' , {
            id: 'sub_organization_unit_id',
            header: () => <div className="text-left" hidden={role !== 1 && role !== 2}>SUB ORGANISASI</div>,
            cell: info => (
                <div className="text-left" hidden={role !== 1 && role !== 2}>{info.row.original?.sub_organization_unit?.name}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'group' , {
            id: 'group_id',
            header: () => <div className="text-left" hidden={role !== 1 && role !==2 && role!==3}>KLOTER / GRUP</div>,
            cell: info => (
                <div className="text-left" hidden={role !== 1 && role !==2 && role!==3}>{info.row.original?.group?.code}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'personal_info_totals.personal_info_total' , {
            id: 'personal_info_totals.personal_info_total',
            header: () => <div className="text-left">JAMAAH</div>,
            cell: info => (
                <div className="text-left">{info.row.original?.personal_info_total}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'update_interval' , {
            id: 'update_interval',
            header: () => <div className="text-left">INTERVAL</div>,
            cell: info => (
                <div className="text-left">{info.row.original?.update_interval} Menit</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'updated_at' , {
            id: 'updated_at',
            header: () => <div className="text-left">DIPERBARUI</div>,
            cell: info => (
                <div className="text-left">{moment(info.row.original?.updated_at).format("DD-MM-YYYY HH:mm")}</div>
            ),
            enableSorting: true
        }),
        columnHelper.accessor(() => 'action' , {
            id: 'action',
            header: () => <div className="text-left" hidden={role === 1 || role === 2} >KONTROL</div>,
            cell: info => (
                <div className="text-left" hidden={role === 1|| role === 2}>
                    <Link
                        className='btn btn-icon btn-light-primary btn-sm mx-1'
                        to={`/configurationKloter/update/${info?.row?.original.id}`}
                    >
                        <i className='bi bi-pencil-square fs-4'></i>
                    </Link>
                </div>
            ),
            enableSorting: false
        })
    ]

    const handleShowModalDetailOU = () => {
        setShowModalDetailOU(false);
      }

    return (
        <>
            <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path: '/configurationKloter',
                description: 'Perangkat',
                isActive: true,
                isSeparator: false
            }]}>Konfigurasi Grup</PageTitle>
            <KTCard className='p-5 shadow-sm'>
                <div className='card-title d-flex justify-content-between'>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Cari'
                            onChange={(e) => setFiltering(e.target.value)}
                        />
                    </div>
                    <div className="">
                        {role !== 4 &&
                            <DeviceManagementFilter
                            onChangeEmbarkation={(embarkation: number) => setSelectedEmbarkation(embarkation)}
                            onChangeGroup={(group: number) => setSelectedGroup(group)}
                            onChangeOrganizationUnitId={(organizationUnitId: number) => setSelectedOrganizationId(organizationUnitId)}
                            onChangeSubOrganizationId={(subOrganizationId: number) => setSelectedSubOrganizationId(subOrganizationId)}
                            onChangeGroupId={(groupId: number) => setSelectedGroupId(groupId)}
                            embarkation={selectedEmbarkation}
                            group={selectedGroup}
                            organizationUnitId={selectedOrganizationId}
                            subOrganizationId={selectedSubOrganizationId}
                            groupId={selectedGroupId}
                            hiddenAvailability={true}
                            hiddenEmbarkation={true}
                            hiddenStatus={true}
                            hiddenRole={true}
                            hiddenCategory={true}
                            hiddenAlarmType={true}
                            hiddenDate={true}
                            hiddenStatusJamaah={true}
                            hiddenGender={true}
                            hiddenOrganizationUnitId={role !== 1}
                            hiddenSubOrganizationId={role !== 1 && role !== 2}
                            />
                        }
                        {role === 3 &&
                            <Link to={'/configurationKloter/add'} className='btn btn-primary' >
                                <KTIcon iconName="bi bi-plus-circle-dotted" className="fs-2" />
                            Tambah
                            </Link>
                        }
                    </div>
                </div>
                <KTCardBody>
                    <ServerTable
                        data={listConfigKloter?.rows ?? []}
                        columns={columns}
                        totalData={listConfigKloter?.total_rows ?? 0}
                        pageCount={listConfigKloter?.total_pages ?? 0}
                        pagination={pagination}
                        sorting={sorting}
                        setPagination={setPagination}
                        setSorting={setSorting}
                    />
                </KTCardBody>
            </KTCard>
            <ModalOrganizationUnitDetail show={showModalDetailOU} onClose={handleShowModalDetailOU} id={IdOU} />
        </>
    )
}

export default ConfigurationKloterPage