import {useEffect, useRef, useState} from 'react'
import {Modal, Spinner} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {isEmpty, getHour, getDate} from '../../../utils'
import BatteryIcon from './BatteraiIcon'
import satelliteIcon from '../../../assets/satellite-solid-1.svg'
import { DeviceHistoryType } from '../../../models/deviceHIstory'
import {useInView} from 'react-intersection-observer'
import { getDeviceHistories } from '../../../api/deviceHistory'
import { useAppContext } from '../../../../_metronic/helpers/AppContext'
import MapBoxGL from '../../../components/map-box/MapBoxGl'
import { Marker, Source } from 'react-map-gl'
import Layer from 'react-map-gl/dist/esm/components/layer'

interface LineString {
  type: "LineString";
  coordinates: [number, number][];
}

interface Feature<T> {
  type: "Feature";
  geometry: T;
  properties?: Record<string, any>;
}

interface GeoJSON<T> {
  type: "FeatureCollection";
  features: Feature<T>[];
}


const months = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]

interface IProps {
  onClose: () => void
  show: boolean
  data: {
    id: number
    name?: string
    last_lat: number
    last_long: number
    targetId?: number
    targetDate?: string
  }
}

type LatlngType = {
  lat: number
  lng: number
}



export default function ModalLocationHistory({onClose, show, data}: IProps) {
  const {ref ,inView} = useInView()
  const {targetId , targetDate} = data
  const [selectedDate, setSelectedDate] = useState<
    | {
        year: number
        month: number
        day: number
      }
    | undefined
  >()
  const [showTimeline, setShowTimeline] = useState<boolean>(true)
  const [centerPosition, setCenterPosition] = useState<LatlngType>()
  const [activeMarker, setActiveMarker] = useState<{location: LatlngType; handle_state: string, alarm_type: string, status: string}>()
  let convertDate : string ;
  const [geoJsonData, setGeoJsonData] = useState<GeoJSON<LineString>>({
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: [],
            },
        },
    ],
});

  

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

const getCurrentPosition = () => {
  navigator.geolocation.getCurrentPosition((position) => {
    setCenterPosition({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  });
};


if (selectedDate?.year && selectedDate?.month && selectedDate?.day) {
  const year = selectedDate.year;
  const month = selectedDate.month.toString().padStart(2, '0');
  const day = selectedDate.day.toString().padStart(2, '0');
  
  convertDate = `${year}-${month}-${day}`;
} else {
  if (targetId && targetDate) {
    convertDate = getDate(targetDate as string);
  } else {
    convertDate = getTodayDate();
  }
}


  const [prevHistory, setPrevHistory] = useState<DeviceHistoryType[]>([])
  const [page, setPage] = useState(0)
  const [limit] = useState(10)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [reachedEnd, setReachedEnd] = useState(false)
  const [timelineData, setTimelineData] = useState<TimelineDataType[]>([])
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollEndRef = useRef<HTMLDivElement | null>(null);
  const {timeZone} = useAppContext()
  const query = {
    device_id : data.id.toString(),
    q : "",
    status : "",
    alarm_type : "",
    date : convertDate,
    search: "",
    hajj_info: "",
    page: page,
    limit: limit,
    time_zone: timeZone
  }

  let lat: number = 0;
  let lng: number = 0;

  if (prevHistory && prevHistory.length > 0) {
    const firstHistory = prevHistory[0];

    lat = data.last_lat;
    lng = data.last_long;
    
    if (firstHistory.gps) {
        lat = firstHistory.gps.location.lat;
        lng = firstHistory.gps.location.lng;
    } else {
      if (firstHistory.geolocation && firstHistory.geolocation.location) {
        lat = firstHistory.geolocation.location.lat;
        lng = firstHistory.geolocation.location.lng;
      }
    } 
  }
  const firstHistoryPosition : LatlngType = { lat, lng } as LatlngType;

  useEffect(() => {
    if (show === true && targetId && data.targetDate) {
      setSelectedDate({year: new Date(data.targetDate).getFullYear(), month: new Date(data.targetDate).getMonth() + 1, day: new Date(data.targetDate).getDate()})
      const intervalId = setInterval(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
          clearInterval(intervalId); 
        } else if (scrollEndRef.current) {
          scrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
      return () => clearInterval(intervalId);
    }
  }, [targetId, show, data.targetDate]);

  useEffect(() => {
    if (show === true) {
      getNewHistory()
    } else if (show === false) {
      resetState()
    }
    // eslint-disable-next-line
  },[data.id,show,selectedDate])

  const resetState = () => {
    setPrevHistory([])
    setPage(0)
    setSelectedDate(undefined)
    setReachedEnd(false)
    setIsLoadingMore(false)
  }
  const getNewHistory = async () => {
    setPage(0)
    setPrevHistory([])
    const data = await getDeviceHistories({...query, page: 0});
    setPrevHistory(data)
    getLocationInfo(data)
    setReachedEnd(false)
    setIsLoadingMore(false)
    return data
  }
  const getMoreHistory = () => {
    setTimeout( async () => {
      const data = await getDeviceHistories({...query, page:page+1});
    if (data) {
      setPrevHistory([...prevHistory, ...data]);
      setPage(page+1)
      if (targetId) {
        const historyitem = data.find((item) => item.id === targetId);
        if (historyitem) {
          const location = historyitem.gps
            ? historyitem.gps.location
            : historyitem.geolocation.location
          setActiveMarker({location, handle_state: historyitem.device?.handle_state as string , alarm_type: historyitem.alarm_type, status: historyitem.status})
          setCenterPosition({lat: location.lat, lng: location.lng})
        }
      }
      getLocationInfo(data)
    } else {
      setReachedEnd(true)
      setIsLoadingMore(false)
    }
      setIsLoadingMore(false)
      
    },500)
  }
  
  useEffect(() => {
    if (inView && !isLoadingMore && !reachedEnd && prevHistory && prevHistory.length > 0) {
      setIsLoadingMore(true)
      getMoreHistory()
    }
    // eslint-disable-next-line
  }, [inView]);

  const getLocationInfo = (data: DeviceHistoryType[]) => {
    if (data) {
      const location = data[0]?.gps 
      ? data[0]?.gps?.location : data[0]?.geolocation.location
      setActiveMarker({location, handle_state: data[0].device?.handle_state as string , alarm_type: data[0].alarm_type, status: data[0].status})
      setCenterPosition({lat: location.lat, lng: location.lng})
    } else {
      getCurrentPosition()
      setActiveMarker({location: {lat: 0, lng: 0}, handle_state: '', alarm_type: '', status: ''})
    }
  }

  type TimelineDataType = {
    id: number
    name: string
    battery: number
    alarm_type: string
    status: string
    location_name: string
    time: string
    address_name: string
    location: {
      lat: number
      lng: number
    }
    gps_fixed: boolean
    handle_state: string
  }

  useEffect(() => {
    const data: TimelineDataType[] =
    (prevHistory &&
      prevHistory.map((device) => {
        return {
          id: device.id,
          name: device.personal_info?.name ?? '',
          battery: device.battery,
          alarm_type: device.alarm_type,
          status: device.status,
          location_name: device?.gps?.location_name
            ? device?.gps?.location_name
            : device?.geolocation?.location_name,
          time: device.location_timestamp,
          location: device?.gps 
            ? device?.gps?.location : device?.geolocation.location,
          address_name: device?.gps?.address_name
            ? device?.gps?.address_name
            : device?.geolocation?.address_name,
          gps_fixed: device?.gps ? true : false,
          handle_state: device?.device?.handle_state as string
        }
      })) ||
    [];
    setTimelineData(data);
  },[data.id, prevHistory]);

  const deviceLineCoordinate = timelineData && timelineData.map((device) => device?.location)

  useEffect(() => {
    setGeoJsonData({
      type: "FeatureCollection",
      features: [
          {
              type: "Feature",
              geometry: {
                  type: "LineString",
                  coordinates: deviceLineCoordinate.map((device) => [device?.lng, device?.lat])
              },
          },
      ],
    })
    // eslint-disable-next-line
  },[timelineData])
  
  const getTitle = () => {
    const firstDevice = timelineData && timelineData.length > 0 ? timelineData[0] : null
    const userName = firstDevice?.name ?? data.name
    return `Detail Riwayat Aktivitas | ${userName}`
  }

  return (
    <>
      <Modal show={show} onHide={onClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{getTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex p-4'>
          <div
            className={`w-${showTimeline ? '650px' : '0'} h-550px overflow-auto position-relative ${
              showTimeline ? '' : 'visually-hidden'
            }`}
          >
            <div className='position-sticky top-0 bg-white z-index-2'>
              <div className='d-flex gap-2 pb-2'>
                <select
                  onChange={(e) => {
                    const selectedYear = Number(e.target.value)
                    setSelectedDate({
                      day: selectedDate?.day ?? new Date().getDate(),
                      month: selectedDate?.month ?? new Date().getMonth(),
                      year: selectedYear,
                    })
                  }}
                  value={selectedDate?.year ?? new Date().getFullYear()}
                  name='year'
                  id='year'
                  className='form-select form-select-sm w-100px'
                >
                  {[2023, 2024, 2025, 2026, 2027, 2028].map((row, idx) => (
                    <option key={idx} value={row} style={{padding: '20px'}}>
                      {row}
                    </option>
                  ))}
                </select>
                <select
                  onChange={(e) => {
                    const selectedMonth = Number(e.target.value)
                    setSelectedDate({
                      day: selectedDate?.day ?? new Date().getDate(),
                      month: selectedMonth,
                      year: selectedDate?.year ?? new Date().getFullYear(),
                    })
                  }}
                  name='month'
                  id='month'
                  value={selectedDate?.month ?? new Date().getMonth() + 1}
                  className='form-select form-select-sm w-125px'
                >
                  {months.map((row, idx) => (
                    <option key={idx} value={idx + 1}>
                      {row}
                    </option>
                  ))}
                </select>
                <div className='d-flex align-items-center'>
                  <DatePicker
                    selected={
                      selectedDate
                        ? new Date(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`)
                        : new Date()
                    }
                    className='form-select w-75px'
                    onChange={(date) => {
                      if (date) {
                        setSelectedDate({
                          year: date.getFullYear(),
                          month: date.getMonth() + 1,
                          day: date.getDate(),
                        })
                      }
                    }}
                    dateFormat={'dd'}
                  />
                </div>
                <button
                  type='button'
                  className='btn btn-outline btn-outline-primary text-nowrap me-2'
                  onClick={() => {
                    const currentDate = new Date()
                    setSelectedDate({
                      year: currentDate.getFullYear(),
                      month: currentDate.getMonth() + 1,
                      day: currentDate.getDate(),
                    })
                  }}
                >
                  Hari ini
                </button>
              </div>
            </div>
            <div>
              {timelineData?.length === 0 ? (
                <>
                  {!isLoadingMore && (
                    <div className='text-center'>Tidak ada riwayat aktivitas</div>
                  )}
                </>
              ) : (
                <div className='pb-5'>
                  {timelineData.map((timeline) => {
                    const {
                      alarm_type,
                      id,
                      address_name,
                      location_name,
                      time,
                      location,
                      status,
                      battery,
                      gps_fixed,
                      handle_state,
                    } = timeline
                    let icon = '';
                    if (alarm_type === 'regular' || status === 'done' || status === 'canceled') {
                        icon = 'bi-check-circle-fill text-success';
                    } else if (alarm_type === 'sos' && status === 'new') {
                        icon = 'bi-exclamation-triangle-fill text-danger';
                    } else if (alarm_type === 'sos' && status === 'in_progress') {
                        icon = 'bi-clock-fill text-warning';
                    } else {
                        icon = 'bi-question-circle-fill';
                    }
                    return (
                      <div
                      key={id}
                      ref={id === targetId ? scrollRef : null}
                      style={id === targetId ? {backgroundColor: '#CCECFD', borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px'} : {}}
                      
                    >
                      <TimelineItem
                        icon={icon}
                        address_name={isEmpty(address_name, '-')}
                        locationName={isEmpty(location_name, '-')}
                        time={getHour(time)}
                        alarm_type={alarm_type}
                        gps_fixed={gps_fixed}
                        status={status}
                        battery={battery}
                        onItemClick={() => {
                          setCenterPosition(location);
                          setActiveMarker({ location, handle_state, alarm_type, status });
                        }}
                      />
                    </div>
                    )
                  })}
                  {/* {pagination} */}
                </div>
              )}
            </div>
            <div className='pb-3' ref={ref}>
            {isLoadingMore && (
              <div className='d-flex justify-content-center items-center gap-3 pb-3'>
              <Spinner animation="border" role="status">
              </Spinner> 
              <p>Loading...</p>
              </div>
            )}
            </div>
            <div ref={scrollEndRef}></div>
          </div>
          <div className='w-100 h-550px position-relative'>
            <div
              className='position-absolute top-50 start-0 mt-n5 z-index-3 bg-white p-2 d-flex flex-column align-items-center shadow-sm rounded-end'
              role='button'
              onClick={() => setShowTimeline(!showTimeline)}
            >
              <p>{showTimeline ? 'Tutup' : 'Buka'}</p>
              <i
                className={`bi bi-arrow-${
                  showTimeline ? 'left' : 'right'
                }-square-fill text-black fs-1`}
              ></i>
            </div>
            <MapBoxGL
              center={centerPosition ?? firstHistoryPosition}
              >
            <Marker
              latitude={activeMarker?.location?.lat ?? 0}
              longitude={activeMarker?.location?.lng ?? 0}
            >
              <div>
                {activeMarker?.alarm_type === 'sos' && activeMarker?.status === 'new' && <div className='circleBeepRed'></div>}
                {activeMarker?.alarm_type === 'sos' && activeMarker?.status === 'in_progress' && <div className='circleBeepYellow'></div>}
                {activeMarker?.alarm_type === 'regular' && <div className='circleBeepGreen'></div>}
              </div>
            </Marker>
              <Source id='lineDeviceHistory' type='geojson' data={geoJsonData}>
                <Layer
                  id='lineDeviceHistory'
                  type='line'
                  paint={{
                    "line-color": "#000",
                    "line-width" : 7,
                  }}
                  />
                </Source>
            </MapBoxGL>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

interface TimelineProps {
  onItemClick: () => void
  icon: string
  locationName: string
  address_name: string
  battery: number
  time: string
  alarm_type: string
  status: string
  gps_fixed: boolean
}
const TimelineItem = ({
  onItemClick,
  icon,
  locationName,
  time,
  alarm_type,
  address_name,
  status,
  battery,
  gps_fixed,
}: TimelineProps) => {
  return (
    <>
      <div
        className='mb-3 position-relative ms-2 bg-hover-light mx-4 rounded'
        onClick={onItemClick}
        role='button'
      >
        <div className='border-start border-5 m-0 ps-4'>
          <div className='border-secondary mt-3 fw-bolder fs-5'>{address_name}</div>
          <div className='border-secondary mt-3'>{locationName}</div>
          <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex align-items-center justify-content-start'>
              <div className='border-white mt-3 fs-6'>{time}</div>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
              <div
                className='d-flex align-items-center p-2 justify-content-center'
                style={{border: '2px solid #f5f5f5', borderRadius: '15px', width: '120px'}}
              >
                {gps_fixed ? (
                  <>
                    <img src={satelliteIcon} alt='Icon Satelit' />
                  </>
                ) : (
                  <>
                    <i className={`bi bi-reception-3 fs-3 fw-bold ml-2`}></i>
                  </>
                )}
                &nbsp;{' '}
                <div className={`fs-8 fw-bold mr-2`}>{`Data ${gps_fixed ? 'GPS' : 'Seluler'}`}</div>
              </div>
              <div
                className='d-flex align-items-center p-2 justify-content-center'
                style={{
                  border: '2px solid #f5f5f5',
                  borderRadius: '15px',
                  width: '80px',
                  marginLeft: '5px',
                }}
              >
                <BatteryIcon battery={battery} />
              </div>
            </div>
          </div>
        </div>
        <i className={`bi ${icon} position-absolute top-0 start-0 fs-2 ms-n2`}></i>
      </div>
    </>
  )
}
