import axios from "axios"
import { ConfigKloterType, listConfigurationKloterRespData } from "../models/configurationKloter"
import { AddConfigKloterType } from "../schemas/configGroup"
import { Pagination } from "../models/base"

const Endpoint = "/api/group_configuration"
const EndpointAddUpdateConfig = "/api/group"
export const getConfigurationKloter = async (query: string = '') => {
    const res = await axios.get(Endpoint + '?' + query)
    return res.data.data as Pagination<ConfigKloterType[]>
}

export const getConfigKloterByID = async (id: number) => {
    const res = await axios.get(`${Endpoint}/${id}`)
    return res.data.data
}

export const addEditConfig = async (command: string, device: AddConfigKloterType, id?: number) => {
    const temporaryData = {
        update_interval : device.update_interval.toString(),
    }
    const data = {
        update_interval : parseInt(temporaryData.update_interval), 
    }
    const addData = {
        group_id : device.group_id,
        update_interval : parseInt(temporaryData.update_interval),
    }
    if(command === 'create'){
        return await axios.post<listConfigurationKloterRespData>(`${EndpointAddUpdateConfig}/configuration/batch`, addData)
    }else{
        return await axios.put<listConfigurationKloterRespData>(`api/group_configuration/${id}`, data)
    }
}