import { useAppContext } from '../../../_metronic/helpers/AppContext';
import { PageTitle } from '../../../_metronic/layout/core';
import { ChartsWidget1, ChartsWidget2, ChartsWidget3, ChartsWidget5, ChartsWidget6, PieCharts1, PieCharts2, PieCharts3 } from '../../../_metronic/partials/widgets';
import { ChartsWidget4 } from '../../../_metronic/partials/widgets/charts/ChartWidget4';

const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <ChartsWidget3 className='card-xl-stretch mb-5 mb-xl-8'/>
      </div>
      <div className='col-xl-4'>
        <ChartsWidget2 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ChartsWidget1 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <PieCharts1 className='card-xl-stretch mb-5 mb-xl-8'/>
      </div>
      <div className='col-xl-4'>
        <PieCharts2 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <PieCharts3 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-8 mt-2'>
      <div className='col-xl-4'>
        <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8'/>
      </div>
      <div className='col-xl-4'>
        <ChartsWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ChartsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
);

const DashboardWrapper = () => {
  const {role, organizationName, subOrganizationName, nameGroup} = useAppContext();
  return (
    <>
      <PageTitle breadcrumbs={[
      {
          title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
          path: '/dashboard',
          description:'',
          isActive: true,
          isSeparator: false
      }]}>Statistik</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
