import { useMutation, useQueryClient } from '@tanstack/react-query';
import { multiDeleteJamaahAPI } from '../api/multipledeleteApi';
import Swal from 'sweetalert2';

const _PERSONAL_INFO = 'personal-info';

export const useMultipleDeletePersonalInfoById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (ids: number[]) => {
      await multiDeleteJamaahAPI(ids);
    },
    {
      onSuccess: () => {
        Swal.fire({
          icon: 'success',
          text: "Data Jama'ah Berhasil Dihapus",
          showConfirmButton: false,
          timer: 1500,
        })
        queryClient.invalidateQueries([_PERSONAL_INFO])
      },
      onError: (error: Error) => {
        Swal.fire({
          icon: 'error',
          title: "Data Jama'ah Gagal Dihapus",
          text: error.message,
          showConfirmButton: false,
          timer: 1500,
        });

        queryClient.invalidateQueries([_PERSONAL_INFO]);
      },
      onMutate: async (ids: number[]) => {
        const result = await Swal.fire({
          text: "Anda Yakin Ingin menghapus jamaah ini",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya, Hapus!",
        });
        if (result.isConfirmed) {
          return { ids };
        } else {
        
          throw new Error('Mutation cancelled');
        }
      },
     
      onSettled: (data, error, variables) => {
        if (error && error.message !== 'Mutation cancelled') {
        
          Swal.fire({
            icon: 'error',
            title: "Data Jama'ah Gagal Dihapus",
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (!error) {
          Swal.fire({
            icon: 'success',
            text: "Data Jama'ah Berhasil Dihapus",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    }
  );
};
