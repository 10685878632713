import {KTCard, KTCardBody, KTIcon} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {Button} from 'react-bootstrap'
import './buttonadd.css'
import {ChangeEvent, useEffect, useState} from 'react'
import {
  useDeletePersonalInfoById,
  useGetPersonalInfoList,
} from '../../hooks/personalInfo'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import ServerTable, {useServerTable} from '../../components/table/ServerTable'
import {createColumnHelper} from '@tanstack/react-table'
import {PersonalInfoType} from '../../models/personalInfo'
import {DeviceManagementFilter} from '../../modules/filter/DeviceManagementFilter'
import { useAppContext } from '../../../_metronic/helpers/AppContext'
import { useMultipleDeletePersonalInfoById } from '../../hooks/usemultipledelete'

/**
 * Renders the Jamaah Page component.
 *
 * @return {void}
 */
const JamaahPage = () => {
  const [selectedEmbarkation, setSelectedEmbarkation] = useState<number>(0)
  const [selectedGroup, setSelectedGroup] = useState<number>(0)
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [checkDisabilityStatus, setCheckDisabilityStatus] = useState<string>('');
  const [checkElderyStatus, setCheckElderyStatus] = useState<string>('');
  const [checkRistiStatus, setCheckRistiStatus] = useState<string>('');
  const [checkMale, setCheckMale] = useState<string>('');
  const [checkFemale, setCheckFemale] = useState<string>('');
  const [selectedOrganizationId, setSelectedOrganizationId ] = useState<number>(0);
  const [selectedGroupId, setSelectedGroupId ] = useState<number>(0);
  const [selectedSubOrganizationId, setSelectedSubOrganizationId ] = useState<number>(0);
  const {
    pageIndex,
    pageSize,
    filterValue,
    sorting,
    pagination,
    setPagination,
    setSorting,
    setFiltering,
  } = useServerTable()
  const {data: jamaahList} = useGetPersonalInfoList({
    q: filterValue,
    pageIndex,
    pageSize,
    sortBy: sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(),
    embarkation_id: selectedEmbarkation,
    group_id: selectedGroupId,
    category: selectedCategory,
    elderly_status: checkElderyStatus,
    status_risti: checkRistiStatus,
    disability_status: checkDisabilityStatus,
    genderFemale: checkFemale,
    genderMale: checkMale,
    organization_unit_id: selectedOrganizationId,
    sub_organization_unit_id: selectedSubOrganizationId
  });

  const [changeButtonSubmit, setChangeButtonSubmit] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<{[key: number]: boolean}>({})
  const {role , organizationName , subOrganizationName , nameGroup} = useAppContext();

  useEffect(() => {
    setPagination({pageIndex: 0, pageSize: 10})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ filterValue])

  useEffect(() => {
    setPagination({pageIndex: 0, pageSize: 10})
  },[filterValue, selectedCategory, selectedGroupId, selectedCategory, checkElderyStatus, checkRistiStatus, checkDisabilityStatus, checkMale, checkFemale, selectedOrganizationId, selectedSubOrganizationId, setPagination])
  
  useEffect(() => {
    const newCheckedItems = Object.fromEntries(jamaahList?.rows?.map((row: { id: any }) => [row.id, false]) ?? []);
    setCheckedItems(newCheckedItems);
    setChangeButtonSubmit(false);
  }, [jamaahList]);
  /**
   * Handles the check event.
   *
   * @param {boolean} e - The boolean value to handle.
   * @return {void}
   */
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const newCheckedItems: { [key: number]: boolean } = {};
    jamaahList?.rows.forEach((row) => {
      newCheckedItems[row.id] = isChecked;
    });

    setCheckedItems(newCheckedItems);
    setChangeButtonSubmit(isChecked);
  };

  const handleOneCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const itemId = parseInt(e.target.value)
    const isChecked = e.target.checked
    // setChangeButtonSubmit(isChecked)
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemId]: isChecked,
    }))
  }
  
  useEffect(() => {
    for (const key in checkedItems) {
      if (checkedItems[key] === true) {
        setChangeButtonSubmit(true)
        return
      } else {
        setChangeButtonSubmit(false)
      }
    }
  }, [checkedItems]);

  
  const exampleHandleDelete = async () => {
    const trueKeys = Object.keys(checkedItems).filter((key: any) => checkedItems[key])
    let parsetonumber = trueKeys.map((key) => parseInt(key))
    if (trueKeys.length > 1) {
      return deleteMultipleJamaah(parsetonumber)
    }
    return deleteJamaah(parsetonumber[0])
  }

  const columnHelper = createColumnHelper<PersonalInfoType>()
  const columns = [
    columnHelper.accessor(() => 'checkbox', {
      id: 'checkbox',
      header: () => {
        const allItemsChecked = jamaahList?.rows.every((row) => checkedItems[row.id]) ?? false;
        return (
          <div className={`${role !== 3  ? 'd-none': 'text-center w-50'}`} >
            <input
              type='checkbox'
              name='all'
              checked={allItemsChecked}
              onChange={handleCheck}
            />
          </div>
        )
      },
      cell: (info) => {
        return (
          <div className={`${role !== 3  ? 'd-none': 'text-center w-50'}`}  >
            <input
              value={info?.row?.original?.id}
              type='checkbox'
              name={'checkbox' + info?.row?.index}
              checked={checkedItems[info?.row?.original?.id] || false}
              onChange={handleOneCheck}
            />
          </div>
        )
      },
      enableSorting: false,
    }),
    columnHelper.accessor(() => 'itemNumber', {
      id: 'itemNumber',
      header: () => <div className='text-center w-80'>#</div>,
      cell: (info) => (
        <div className='text-center w-80'>{pageSize * pageIndex + info?.row?.index + 1}</div>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor(() => 'name', {
      id: 'name',
      header: () => <div className='text-start w-100'>NAMA</div>,
      cell: (info) => <div className='text-start w-100'>{info?.row?.original.name}</div>,
      enableSorting: true,
    }),
    columnHelper.accessor(() => 'hajj_info', {
      id: 'hajj_info',
      header: () => <div className='text-start w-100'>ID JAMAAH</div>,
      cell: (info) => <div className='text-start w-100'>{info?.row?.original?.hajj_info}</div>,
      enableSorting: true,
    }),
    columnHelper.accessor(() => 'category', {
      id: 'category',
      header: () => <div className='text-start w-100' hidden={role !== 1}>KATEGORI</div>,
      cell: (info) => (
        <div className='text-start w-100' hidden={role !== 1}>
          {info?.row?.original?.category === 'hajj' ? 'Haji' : 'Umroh'}
        </div>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor(() => 'organization_unit', {
      id: 'organization_unit',
      header: () => <div className='text-start w-100' hidden={role !== 1} >UNIT ORGANISASI</div>,
      cell: (info) => (
        <div className='text-start w-100' hidden={role !== 1} >{info?.row?.original?.organization_unit?.name}</div>
      )
    }),
   columnHelper.accessor(() => 'sub_organization', {
    id: 'sub_organization',
    header: () => <div className='text-start w-100' hidden={role !== 1 && role !== 2} >SUB ORGANISASI</div>,
    cell: (info) => (
      <div className='text-start w-100' hidden={role !== 1 && role !== 2} >{info?.row?.original?.sub_organization_unit?.name}</div>
    )
  }),
    columnHelper.accessor(() => 'group.code', {
      id: 'group.code',
      header: () => <div className='text-start w-100' hidden={role !== 1 && role !== 2 && role !== 3} >KLOTER / GRUP</div>,
      cell: (info) => <div className='text-start w-100' hidden={role !== 1 && role !== 2 && role !== 3}>{info?.row?.original?.group?.code}</div>,
      enableSorting: true,
    }),
    columnHelper.accessor(() => 'place_of_origin', {
      id: 'place_of_origin',
      header: () => <div className='text-start w-100'>ASAL DAERAH</div>,
      cell: (info) => (
        <div className='text-start w-100'>{info?.row?.original?.place_of_origin}</div>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor(() => 'button-control', {
      id: 'button-control',
      header: () => <div className='text-center'>KONTROL</div>,
      cell: (info) => (
        <div className='text-center'>
          <Link
            className='btn btn-icon btn-light-info btn-sm mx-1'
            to={`/jamaah/detail/${info?.row?.original.id}/${'jamaah'}`}
          >
            <i className='bi bi-eye fs-4'></i>
          </Link>
          <Link
            className='btn btn-icon btn-light-primary btn-sm mx-1'
            to={`/jamaah/update/${info?.row?.original.id}`}
            hidden={role === 1 || role === 2 || role === 4}
          >
            <i className='bi bi-pencil-square fs-4'></i>
          </Link>
          <button
            onClick={() => handleDelete(info?.row?.original.id)}
            type='button'
            className='btn btn-icon btn-light-danger btn-sm mx-1'
            hidden={role === 1 || role === 2 || role === 4}
          >
            <i className='bi bi-trash fs-4'></i>
          </button>
        </div>
      ),
      enableSorting: false,
    }),
  ]
  const {mutate: deleteMultipleJamaah} = useMultipleDeletePersonalInfoById()
  const {mutate: deleteJamaah} = useDeletePersonalInfoById()

  const [showPopUp, setShowPopUp] = useState<boolean>(true)
  const [clickCount, setClickCount] = useState<number>(0)
  /**
   * Handle click event for the "Add" button.
   *
   * @return {void} No return value
   */
  const handleClickAdd = () => {
    setClickCount(clickCount + 1)
    setShowPopUp(clickCount % 2 === 1)
  }

  /**
   * Handles the delete action for a given ID.
   *
   * @param {number} id - The ID of the Jama'ah to be deleted.
   * @return {void} This function does not return anything.
   */
  const handleDelete = (id: number) => {
    Swal.fire({
      text: "Anda yakin ingin menghapus jamaah ini?",
      icon: 'warning',
      iconColor: '#7339eb',
      showCancelButton: true,
      confirmButtonColor: '#dc3233',
      cancelButtonColor: '#207aed',
      confirmButtonText: 'Ya, Hapus!',
      cancelButtonText: 'Batalkan',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteJamaah(id)
      } else {
      }
    })
  }
  const checkedItemsLength = Object.keys(checkedItems).filter((key: any) => checkedItems[key]).length
  return (
    <>
      <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path: '/jamaah',
                description:'',
                isActive: true,
                isSeparator: false
            }]}>Jamaah</PageTitle>
      <KTCard className='p-5 shadow-sm'>
        <div className='card-title d-flex justify-content-between'>
          <div
            data-bs-target='#kt_account_profile_details'
            aria-controls='kt_account_profile_details'
          >
            <div className='card-title m-0 d-flex flex-column'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Cari'
                  onChange={(e) => setFiltering(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
                <div className={`${checkedItemsLength === 0 || role === 1 || role === 2 ? 'd-inline-block' : 'd-none'}`}>
                        <DeviceManagementFilter
                        onChangeEmbarkation={(embarkation: number) => setSelectedEmbarkation(embarkation)}
                        onChangeGroup={(group: number) => setSelectedGroup(group)}
                        onChangeCategory={(category: string) => setSelectedCategory(category)}
                        onChangeDisabilityStatus={(disability_status: string) => setCheckDisabilityStatus(disability_status)}
                        onChangeElderlyStatus={(eldery_status: string) => setCheckElderyStatus(eldery_status)}
                        onChangeStatusRisti={(status_risti: string) => setCheckRistiStatus(status_risti)}
                        onChangeGenderFemale={(female: string) => setCheckFemale(female)}
                        onChangeGenderMale={(male: string) => setCheckMale(male)}
                        onChangeOrganizationUnitId={(organizationUnitId: number) => setSelectedOrganizationId(organizationUnitId)}
                        onChangeGroupId={(groupId: number) => setSelectedGroupId(groupId)}
                        onChangeSubOrganizationId={(subOrganizationId: number) => setSelectedSubOrganizationId(subOrganizationId)}
                        embarkation={selectedEmbarkation}
                        group={selectedGroup}
                        groupId={selectedGroupId}
                        category={selectedCategory}
                        elderly_status={checkElderyStatus}
                        status_risti={checkRistiStatus}
                        disability_status={checkDisabilityStatus}
                        genderMale={checkMale}
                        genderFemale={checkFemale}
                        organizationUnitId={selectedOrganizationId}
                        subOrganizationId={selectedSubOrganizationId}
                        hiddenAvailability={true}
                        hiddenStatus={true}
                        hiddenEmbarkation={true}
                        hiddenRole={true}
                        hiddenDate={true}
                        hiddenAlarmType={true}
                        hiddenOrganizationUnitId={role !== 1}
                        hiddenCategory={role !== 1}
                        hiddenSubOrganizationId={role !== 1 && role !== 2}
                        hiddenGroupId={role !== 1 && role !== 2 && role !== 3}
                      /> 
                </div>

            

                {checkedItems && checkedItemsLength > 0 && role !== 1 && role !== 2 && (
                  <p style={{display: 'inline' , margin: '10px' }}>{checkedItemsLength} item terpilih</p>
                )}

                {changeButtonSubmit && (role === 3 || role === 4) && (
                  <Button
                  className='btn btn-danger me-3'
                  onClick={exampleHandleDelete}
                >
                  Hapus Terpilih
                </Button>
                )}
                {/* <Button className='btn me-3 btn-light-primary' onClick={() => {}}>
                  <KTIcon iconName='bi bi-download' className='fs-2' />
                  Ekspor
                </Button> */}

                {!changeButtonSubmit && (role === 3 || role === 4) && (
                  <Button className='me-3' onClick={handleClickAdd} hidden={role === 1 || role === 2 || role === 4}>
                  <KTIcon iconName='bi bi-plus-circle-dotted' className='fs-2' />
                  Tambah
                </Button>
                )}
 
            </div>
       
            <div className='popupadd m-0' hidden={showPopUp}>
              <ul>
                <li className='titlelist'>Tambah Jamaah</li>
                <Link to={`/jamaah/add`}>
                  <li >Satu Data</li>
                </Link>
                <Link to={`/jamaah/import`}>
                  <li>Import Masal</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <KTCardBody>
          <ServerTable
            data={jamaahList?.rows ?? []}
            columns={columns}
            totalData={jamaahList?.total_rows ?? 0}
            pageCount={jamaahList?.total_pages ?? 0}
            pagination={pagination}
            sorting={sorting}
            setPagination={setPagination}
            setSorting={setSorting}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default JamaahPage
