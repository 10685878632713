import { Suspense, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import { Outlet } from 'react-router-dom'

import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { requestForToken, onMessageListener } from './libs/firebase'
import { _DEVICE_LIST } from './hooks/device'
import { AuthInit } from './modules/auth/core/Auth'
import { AppContext } from '../_metronic/helpers/AppContext'
import { PersonalInfoType } from './schemas/personalInfo'
import { InventoryLog } from './models/inventory'


const App = () => {
  const [jamaahImportId, setJamaahImportId] = useState<string>('');
  const [deviceImportId, setDeviceImportId] = useState<string>('');
  const [inventoryImportId, setInventoryImportId] = useState<string>('');
  const [dataJamaahRows, setDataJamaahRows] = useState<Array<PersonalInfoType>>([]);
  const [dataDeviceRows, setDataDeviceRows] = useState<Array<PersonalInfoType>>([]);
  const [dataInventoryRows, setDataInventoryRows] = useState<InventoryLog>({} as InventoryLog); 
  const [fullname, setFullname] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [organizationID, setOrganizationID] = useState<number>(0);
  const [subOrganizationName, setSubOrganizationName] = useState<string>('');
  const [subOrganizationID, setSubOrganizationID] = useState<number>(0);
  const [nameGroup, setNameGroup] = useState<string>('');
  const [groupId, setGroupId] = useState<number>(0);
  const [role, setRole] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [notifications, setNotifications] = useState({ title: '', body: '' });
  const queryClient = useQueryClient();
  const [timeZone, setTimeZone] = useState<string>('');
  useEffect(() => {
    requestForToken()
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    setTimeZone(timeZone);
  }, [])

  onMessageListener()
    .then((payload) => {
      console.log(payload)
      setNotifications({
        ...notifications,
        title: payload.notification.title,
        body: payload.notification.body,
      })
      queryClient.invalidateQueries([_DEVICE_LIST])
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <AppContext.Provider 
    value={{ 
      dataJamaahRows,
      setDataJamaahRows,
      jamaahImportId,
      setJamaahImportId,
      fullname, 
      setFullname, 
      organizationName, 
      setOrganizationName, 
      subOrganizationID,
      setSubOrganizationID,
      subOrganizationName,
      setSubOrganizationName,
      role, 
      setRole, 
      email, 
      setEmail,
      status,
      setStatus,
      organizationID,
      setOrganizationID,
      nameGroup,
      setNameGroup,
      deviceImportId,
      setDeviceImportId,
      dataDeviceRows,
      setDataDeviceRows,
      timeZone,
      setTimeZone,
      groupId,
      setGroupId,
      inventoryImportId,
      setInventoryImportId,
      dataInventoryRows,
      setDataInventoryRows,
     }}
    >
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </I18nProvider>
    </Suspense>
    </AppContext.Provider>
  )
}

export { App }
