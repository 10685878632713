interface ListItemType {
    label : string,
    data : string,
    className? : string
}

const ListItem : React.FC<ListItemType>  = ({label, data, className }) => {
    return (
        <div className={`d-flex gap-3 justify-content-between align-items-center py-3 ${className}`}>
        <p className='text-black-50 d-block m-0 fw-bold '>{label}</p>
        <p className='text-black-100 d-block m-0 fw-bold text-end'>{data}</p>
    </div>
    )
}

export default ListItem