import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {changePassword, getProfile, updateProfile} from '../api/profile'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Base } from '../models/base'
import { ChangePasswordType, ProfileType } from '../models/profile'
const _PROFILE = 'profile'

export const useGetProfile = () => {
  return useQuery([_PROFILE], async () => getProfile(), {
    refetchOnWindowFocus: false,
  })
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: ProfileType) => {
      const res = await updateProfile(data)
      return res
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_PROFILE])
        toast.success('Tersimpan')
      },
      onError: (err: AxiosError<Base<ProfileType>>) => {
        toast.error(`Gagal menyimpan perubahan`)
        console.error(err)
      },
    })
}


export const useChangePassword = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: ChangePasswordType) => {
      const res = await changePassword(data)
      return res
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_PROFILE])
        toast.success('Tersimpan')
      },
      onError: (err: AxiosError<Base<ChangePasswordType>>) => {
        if (err?.response?.status !== 400) {
          toast.error(`Gagal menyimpan perubahan`)
        }
      },
    })
}