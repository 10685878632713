import { useMutation, useQuery } from "@tanstack/react-query"
import { getDeviceHistories, openDeviceHistories } from "../api/deviceHistory"
import { AxiosError } from "axios"
import { Base, BaseQuery } from "../models/base"
import { DeviceHistoryType } from "../models/deviceHIstory"


export const _KEY = 'geofence-list'

export const useGetDeviceHistories = (query : BaseQuery) => {
    return useQuery(
        [_KEY, query],
        () => {
            return getDeviceHistories(query)
        },
    )
}


export const useUpdateDeviceHistories = () => {
    return useMutation(
        async ({ q }: { q: string }) => {
            return await openDeviceHistories(q)
        },
        {
            onError: (err: AxiosError<Base<DeviceHistoryType>>) => {
                console.error('ERROR_CHANGE_IS_OPEN: ', err)
            }
        }
    )
}