import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { AuthModel, CustomAxiosRequestConfig } from './_models'
import { issueAccessToken } from '../../../api/refreshToken';

const AUTH_LOCAL_STORAGE_KEY = 'auth'
let isRefreshing = false; // Flag to indicate if the token is currently being refreshed
let failedQueue: any[] = []; // Queue to hold failed requests while the token is being refreshed
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return undefined
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return undefined
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.defaults.headers.Accept = 'application/json'
  axiosInstance.defaults.baseURL = process.env.REACT_APP_TRACKER_API_URL

  const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const auth = getAuth();
    if (auth && auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`
    }

    return config;
  };

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  }

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response
  }

  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  }


  const onResponseError = async (error: AxiosError): Promise<any> => {
    const config = error.config as CustomAxiosRequestConfig;
    const auth = getAuth();

    if (config?.skipAuthRefresh) {
      return Promise.reject(error); // Skip handling the 401 error
    }
    
    if (error.response?.status === 401) {
      if (!auth?.refresh_token) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        /// If the token is already being refreshed, queue the request
        return new Promise ((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          if (error?.config?.headers) {
            error.config.headers['Authorization'] = 'Bearer ' + token;
          }
          return axiosInstance(error.config as InternalAxiosRequestConfig);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      isRefreshing = true;

      try {
        const response = await issueAccessToken(auth.refresh_token);
        const authInfo: AuthModel = { ...response.data.data , email: auth.email };
        setAuth(authInfo);
        processQueue(null, authInfo.refresh_token);

        if (error?.config?.headers) {
          error.config.headers['Authorization'] = `Bearer ${authInfo.refresh_token}`;
        }

        return axiosInstance(error?.config as InternalAxiosRequestConfig);
      } catch (err) {
        processQueue(err, null);
        removeAuth();
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
}


  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)
  return axiosInstance
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
