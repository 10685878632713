/**
 *
 * @param value
 * @param returnValueIfEmpty default return is ''
 * @returns
 */
export const isEmpty = (value: any, returnValueIfEmpty: any = '') => {
  if (value === null || typeof value === 'undefined' || value === '') return returnValueIfEmpty
  return value
}
