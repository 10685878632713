import * as yup from 'yup'


export const baseProfileSchema = yup.object().shape({
  id: yup.number(),
  name: yup.string()
    .min(3, "Minimal 3 karakter")
    .required("Nama tidak boleh kosong"),
  username: yup.string()
        .min(6, "Minimal 6 karakter")
        .max(30, "Maksimal 30 karakter")
        .required("Username perlu diisi")
        .test('no-space', 'username tidak boleh mengandung spasi', value => !/ /.test(value))
        .test('non-symbol', 'Username tidak boleh mengandung simbol', value => /^[a-zA-Z0-9]+$/.test(value)),
  email: yup.string().email().required("Email perlu diisi"),
  phone_number: yup.string()
        .matches(/^08\d{8,}$/, 'Nomor Telepon harus dimulai dengan 08 dan memiliki 10 digit atau lebih')
        .required("Nomor Telepon Perlu Diisi"),
})

export const changePasswordSchema = yup.object().shape({
  old_password: yup.string()
    .min(8, "Minimal 8 karakter")
    .required("Password lama perlu diisi"),
  new_password: yup.string()
    .min(8, "Minimal 8 karakter")
    .required("Password baru perlu diisi"),
})