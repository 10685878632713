export const statusText = (status: string) => {
    if (status === 'sos') {
        return 'SOS'
    } else if (status === 'in_progress') {
        return 'Dalam penanganan'
    } else if (status === 'done') {
        return 'Selesai'
    } else if (status === 'geofence_in') {
        return 'Masuk zona'
    }
    return 'Keluar zona'
}