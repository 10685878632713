import { AuthModel, UserModel } from "../modules/auth/core/_models"

export const rupiahFormat = (num: number) =>
  new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'IDR',
  }).format(num)

export const dateFormat = (datetimestr: string | number | Date) => {
  const date = new Date(datetimestr);

  const res = new Intl.DateTimeFormat('id', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(date);

  return res;
}

export const getHour = (dateTimeStr : string | number | Date) => {
  const date = new Date(dateTimeStr);
  const res = new Intl.DateTimeFormat('id', {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(date);
  return res
}



export const getDate = (dateTimeStr: string ) => {
  const date = new Date(dateTimeStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
  const day = String(date.getDate()).padStart(2, '0');
  
  const res = `${year}-${month}-${day}`;
  return res;
}

export const convertFormInputDate = (date?: Date) => {
  return `${date?.getFullYear()}-${('0' + (date?.getMonth()! + 1)).slice(-2)}-${(
    '0' + date?.getDate()!
  ).slice(-2)}`
}

export const convertPackageArrayToString = (arr : (string | undefined)[] | undefined) : string => {
  if (typeof arr === 'undefined') return ''
  return arr.join('\n')
}

export const convertStringToStringArray = (str: string | string[]): string[] => {
  if (typeof str === 'string') return [str]
  return str
}

export const convertDDToDMS = (dd : number, isLat : boolean = false) => {
  const direction = dd < 0
    ? isLat ? 'S' : 'W'
    : isLat ? 'N' : 'E';
  const absoluteDd = Math.abs(dd);
  const deg = Math.floor(absoluteDd);
  const minFloat = (absoluteDd - deg) * 60;
  const min = Math.floor(minFloat);
  const secFloat = (minFloat - min) * 60;
  const sec = secFloat.toFixed(1);

  return `${deg}°${min}'${sec}"${direction}`;
}

export const parseJwt = (authToken: AuthModel): UserModel | undefined => {
  try {
    // let authInfo: AuthInfo = JSON.parse(atob(authToken.api_token.split('.')[1]))
    //  convert authInfo into userModel
    return {
      id: 0,
      username:  '',
      password: '',
      email: '',
      first_name:  '',
      last_name: '',
      fullname: 'admin' || '',
      occupation: '',
      companyName: '',
      phone: '',
      roles: [],
      pic: '',
      language: 'en',
      timeZone: '',
      website: undefined,
      emailSettings: undefined,
      auth: authToken,
      communication: undefined,
      address: undefined,
      socialNetworks: undefined,
    }
  } catch (e) {
    return undefined
  }
}

export const convertToNextDate = (dateString : string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
