import { PropsWithChildren, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { KTSVG } from '../../_metronic/helpers'

interface GoogleMapsProps {
  center: {
    lat: number
    lng: number
  }
  onMapClick?: (e: google.maps.MapMouseEvent) => void
}

let currentLocation = {
  lat: 0,
  lng: 0,
}

const LIBRARIES: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
  "places"
];

export default function GoogleMaps({
  children,
  onMapClick,
  center
}: PropsWithChildren<GoogleMapsProps>) {
  if (center.lat !== 0 && center.lng !== 0) {
    currentLocation = center
  }
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_TRACKER_GOOGLE_MAPS_KEY as string,
    libraries: LIBRARIES,
  })

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const zoom = 15;

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          const newCenter = { lat: latitude, lng: longitude };
          if (map) {
            map.panTo(newCenter);
          }
        },
        () => {
          alert('Gagal mendapatkan lokasi.');
        }
      );
    } else {
      alert('Geolocation tidak didukung oleh browser ini.');
    }
  };

  return !isLoaded ? (
    <></>
  ) : (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <GoogleMap
        mapContainerClassName="w-100 h-100"
        center={currentLocation}
        zoom={zoom}
        mapTypeId="hybrid"
        onClick={onMapClick}
        onLoad={mapInstance => setMap(mapInstance)}
        options={{
          // Options for Google Maps
        }}
      >
        {children}
      </GoogleMap>

      {/* Lokasi Terkini Button */}
      <button
        onClick={handleCurrentLocationClick}
        style={{
          position: 'absolute',
          bottom: '23px',
          right: '70px',
          zIndex: '10',
          padding: '10px 10px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          cursor: 'pointer',
        }}
      >
        <KTSVG
          path="/media/svg/shapes/center-object.svg"
          className="svg-icon-2"
        />
      </button>
    </div>
  );
}
