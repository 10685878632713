import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className='d-flex flex-column w-100 h-100 align-items-center justify-content-center'>
      <h1 className='fw-bolder'>404</h1>
      <p className='fs-3'>Halaman tidak ditemukan</p>
      <Link className='btn btn-secondary' to='/'>
        <i className="bi bi-chevron-compact-left"></i>Kembali
      </Link>
    </div>
  );
}

export default PageNotFound