import { useMutation, useQueryClient } from "@tanstack/react-query"
import { addInventory, getAllInventory, transferInventory } from "../api/inventory"
import { useGetListData } from "./helpers/useGetData"
import { Base, BaseQuery } from "../models/base"
import { CreateInventoryType, InventoryLog, ReturnInventoryType, TransferInventoryType } from "../models/inventory"
import { useAppContext } from "../../_metronic/helpers/AppContext"
import { AxiosError } from "axios"
import Swal from "sweetalert2"
import { useState } from "react"
import { getLogProgress, getLogRows } from "../api/personalInfo"
import { DeviceType } from "../models/device"
const _INVENTORY_LIST = 'inventory-list'

export const useGetAllInventory = (query: BaseQuery) => {
    return useGetListData({
        queryFn: getAllInventory,
        queryKey: _INVENTORY_LIST,
        query,
    })
}

export const UseAddInventoryBatch = () => {
    const { setInventoryImportId } = useAppContext();
    const queryClient = useQueryClient();
    const {mutate, isLoading} = useMutation(
      async ({data}: {data: CreateInventoryType}) => {
        const response = await addInventory(data)
        setInventoryImportId(response?.data?.import_id ?? '');
        console.log(response)
        return response
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([_INVENTORY_LIST]);
        },
        onError: (err: any) => {
          Swal.fire({
            icon: 'error',
            title: "Data Inventory Gagal Di tambahkan",
            text: `${err.response?.data.error}`,
            showConfirmButton: false,
            timer: 1500,
          })
          console.error('ERROR_ADD_Inventory: ', err)
          queryClient.invalidateQueries([_INVENTORY_LIST]);
        },
      }
    )
    return {mutate, isLoading}
  }

  export const useTransferInventoryBatch = () => {
    const { setInventoryImportId } = useAppContext();
    const queryClient = useQueryClient();
    const {mutate, isLoading} = useMutation(
      async ({data}: {data: TransferInventoryType | ReturnInventoryType}) => {
        const response = await transferInventory(data)
        setInventoryImportId(response?.data?.import_id ?? '');
        console.log(response)
        return response
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([_INVENTORY_LIST]);
        },
        onError: (err: any) => {
          Swal.fire({
            icon: 'error',
            title: "Data Inventory Gagal Di Pindahkan",
            text: `${err.response?.data.error}`,
            showConfirmButton: false,
            timer: 1500,
          })
          console.error('ERROR_Transfer_Inventory: ', err)
          queryClient.invalidateQueries([_INVENTORY_LIST]);
        },
      }
    )
    return {mutate, isLoading}
  }

  export const useGetInventoryLogProgress = () => {
    const queryClient = useQueryClient()
    const [toast, setToast] = useState({isOpen: false, message: '', type: 'pending'})
    const {mutate, isLoading} = useMutation(
      async ({id}: {id: string}) => {
        const result = await getLogProgress(id)
        return result
      },
      {
        onSuccess: (result, {id}) => {
          queryClient.invalidateQueries([_INVENTORY_LIST])
          const { status } = result.data;
          let message: string, type: string;
          if (status === 'in_progress') {
            const interval = setInterval(async () => {
              const updatedResult = await getLogProgress(id)
              const { imported_count, failed_count, total_rows, status } = updatedResult.data
              if (status === 'completed') {
                clearInterval(interval)
                if (failed_count === total_rows) {
                  message = `${imported_count} dari ${total_rows} Perangkat Tersimpan`;
                  type = 'error'
                } else if (imported_count === total_rows) {
                  message = `${imported_count} dari ${total_rows} Perangkat Tersimpan`;
                  type = 'success';
                }
                else if (imported_count >= 0) {
                  message = `${imported_count} dari ${total_rows} Perangkat Tersimpan`;
                  type = 'warning'
                } 
                setToast({
                  isOpen: true,
                  message: message,
                  type: type
                })
              } else {
                setToast({
                  isOpen: true,
                  message: `${imported_count} dari ${total_rows} Perangkat Tersimpan`,
                  type: 'pending',
                })
              }
            }, parseInt(process.env.REACT_APP_INTERVAL_BATCH_UPLOADS as string))
          } else if (status === 'completed') {
            queryClient.invalidateQueries([_INVENTORY_LIST])
            if (result.data.failed_count === result.data.total_rows) {
              setToast({
                isOpen: true,
                message: `0 dari ${result.data.total_rows} Perangkat Tersimpan`,
                type: 'error',
              })
            } else if (result.data.imported_count === result.data.total_rows) {
              setToast({
                isOpen: true,
                message: `${result.data.imported_count} dari ${result.data.total_rows} Perangkat Tersimpan`,
                type: 'success',
              })
            } else if (result.data.imported_count >= 0) {
              setToast({
                isOpen: true,
                message: `${result.data.imported_count} dari ${result.data.total_rows} Perangkat Tersimpan`,
                type: 'warning',
              })
            }
          }
        },
        onError: (err) => {
          console.error('Error -> ', err)
          queryClient.invalidateQueries([_INVENTORY_LIST])
          setToast({
            isOpen: true,
            message: 'Gagal mengimport data',
            type: 'error',
          })
        },
      }
    )
    return {mutate, isLoading, toast, setToast}
  }

  export const useGetInventoryLogRow = () => {
    const { setDataInventoryRows } = useAppContext()
    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(
      async ({id}: {id: string}) => {
        const response = await getLogRows(id)
        setDataInventoryRows(response?.data as InventoryLog)
        console.log(response)
        return response
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([_INVENTORY_LIST])
        },
        onError: (err: AxiosError<Base<DeviceType>>) => {
          console.error('Error get device log row', err)
          queryClient.invalidateQueries([_INVENTORY_LIST])
        }
      }
    )
    return { mutate, isLoading}
  }