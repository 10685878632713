/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
import { useEffect, useState } from 'react';
import { UserOnLocalStorageType } from '../../../../app/models/users';
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  // const intl = useIntl()
  const dataUser = localStorage.getItem('data_user');
  const [parseDataUser, setParseDataUser] = useState<UserOnLocalStorageType>({} as UserOnLocalStorageType);

  useEffect(() => {
    if (dataUser) {
    const data: UserOnLocalStorageType = JSON.parse(dataUser);
    setParseDataUser(data);
    }
  }, [dataUser]);

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='bi bi-pie-chart'
        title='Statistik'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      {/* {parseDataUser.role === 1 ? <AsideMenuItem to='/inventory' icon='bi bi-box2' title='Inventory' /> : <></>} */}
      <AsideMenuItem to='/devices' icon='bi bi-phone-vibrate' title='Perangkat' />
      <AsideMenuItem to='/jamaah' icon='bi bi-people' title="Jama'ah" />
      <AsideMenuItem to='/tracking' icon='bi bi-display' title='Pusat Monitoring' />
      <AsideMenuItemWithSub
        to='#'
        icon='bi bi-gear'
        title='Konfigurasi Grup'
      >
        <AsideMenuItem to='/configurationKloter' hasBullet title='Perangkat' />
        <AsideMenuItem to='/geofence' hasBullet title='Geofence' />
      </AsideMenuItemWithSub>
      { parseDataUser.role !== 1 && parseDataUser.role !== 2 ?
      <></>
      :
      <>
      <AsideMenuItem to='/organization-unit' icon='bi bi-building-check' title={ parseDataUser.role === 1 ? 'Unit Organisasi' : parseDataUser.role === 2 ? 'Sub Unit Organisasi' : '' } />
      </>
      }
      <AsideMenuItem to='/users' icon='bi bi-person-gear' title='Manajemen Akun' />
      {/* <AsideMenuItem to='/personalInfo' icon='bi bi-person-gear' title='Pengaturan Akun' /> */}
    </>
  )
}
