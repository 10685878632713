import React, {SetStateAction, useEffect, useState} from 'react'
import {Modal, Table} from 'react-bootstrap'
import './customModal.css'
import 'moment/locale/id'
import {useGetUserNotificationList, useReadUserNotification} from '../../../../../app/hooks/userNotification'
import moment from 'moment'
import './IconNotif.css'
import IconNotification from './IconNotification'
import {statusText} from '../../../../helpers/string'
import { UserNotificationType } from '../../../../../app/models/userNotifications'
import LocationHistoryModal from '../../../../../app/pages/devices/components/LocationHistoryModal'


const NotificationModal = () => {
  const [showModal, setShowModal] = useState(false)
  const {data: notifications} = useGetUserNotificationList()
  const {mutate: readNotif} = useReadUserNotification()
  const [isHovered, setIsHovered] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState<UserNotificationType>()
  const [showPlaybackModal, setShowPlaybackModal] = useState(false);
  const data = {
    id: selectedNotification?.id ?? 0,
    last_lat: 0,
    last_long:0,
    hajj_info_id: selectedNotification?.hajj_info ?? 0,
  }

  const [unreadNotifLength, setUnreadNotifLength] = useState(0);
  const [notificationList, setNotificationList] = useState<UserNotificationType[]>([]);

  useEffect(() => {
    if (notifications) {
      const unreadNotif = notifications.filter((notification) => notification.status === "unread");
      setUnreadNotifLength(unreadNotif.length);
      setNotificationList(notifications);
    }
  }, [notifications]);

  const handleModal = () => {
    setShowModal(!showModal)
  }

  const handlePlaybackModal = (notification: SetStateAction<UserNotificationType | undefined>) => {
    setShowPlaybackModal(true);
    setSelectedNotification(notification);

  }

  const handleClosePlaybackModal = () => {
    setShowPlaybackModal(false);
  };

  const formatTime = (created_at: string) => {
    return moment(created_at).format('HH:mm')
  }

  const handleReadNotification = (id: number) => {
   try {
    readNotif(id)
   } catch (error) {
    console.log(error)
   }
  }

  return (
    <>
      {unreadNotifLength > 0 && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            top: '17px',
            right: '30px',
            height: '17px',
            borderRadius: '5px',
            backgroundColor: 'red',
            cursor: 'pointer',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'white',
            fontWeight: 'bold',
            padding: '3px',
          }}
          onClick={() => handleModal()}
        >
          {unreadNotifLength > 0 && <div className='m-0 p-0'>Baru</div>}
        </div>
      )}
      <div style={{height: '100%', paddingTop: '15px'}}>
        <button onClick={() => handleModal()} className='btn btn-light hover-overlay'>
          <i
            className={`bi ${isHovered ? 'bi-bell-fill' : 'bi-bell'} hover-blue`}
            style={{fontSize: '20px', color: 'white'}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          ></i>
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        aria-labelledby='example-modal-sizes-title-lg'
        dialogClassName='right-side-modal'
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>Notifikasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table hover responsive className='table' style={{cursor: 'pointer'}}>
              {notificationList ? (
                notificationList
                  .reduce((acc: {date: string; notificationList: any}[], notification) => {
                    const currentDate = moment(notification.created_at)
                      .locale('id')
                      .format('DD MMMM YYYY')
                    const existingDateIndex = acc.findIndex((item) => item.date === currentDate)
                    if (existingDateIndex === -1) {
                      acc.push({date: currentDate, notificationList: [notification]})
                    } else {
                      acc[existingDateIndex].notificationList.push(notification)
                    }
                    return acc
                  }, [])
                  .map(({date, notificationList}, index) => (
                    <React.Fragment key={index}>
                   
                      <thead>
                        <tr>
                          <th>
                            <div className='m-0 p-3 bg-gray-200'>
                              {date}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationList.map((notification: UserNotificationType) => (
                          <React.Fragment key={`${notification.id}`} >
                            <tr className={`${notification.status === 'unread' ? 'fw-bold' : ''}`}
                              onClick={() => handleReadNotification(notification.id)}
                              >    
                              <td>
                                <div style={{marginLeft: '10px'}}>
                                  <div>
                                    <div className='row'>
                                      <div className='col-2'>
                                        <IconNotification
                                          status_description={notification.status_description}
                                        />
                                      </div>

                                      <div className='col-8' onClick={() => handlePlaybackModal(notification)}>
                                        <div className='row'>
                                          {' '}
                                          ID Jama'ah : {notification.hajj_info}
                                        </div>
                                        <div className='row'>{notification.message}</div>
                                        <div className='row'>
                                          {
                                            ["geofence_in", "geofence_out"].includes(notification.status_description) ?
                                              <>Geofence: {notification.geofence_name}</>
                                              :
                                              <>Maktab: {notification.maktab_name}</>
                                          }
                                        </div>
                                      </div>
                                      <div className='col-2'>
                                        <div
                                          className='row'
                                          style={{color: 'gray', fontWeight: 'bold'}}
                                        >
                                          Status
                                        </div>
                                        <div className='row'>
                                          {' '}
                                          {statusText(notification.status_description)}
                                        </div>
                                        <div className='row'>
                                          {' '}
                                          {formatTime(notification.created_at)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </React.Fragment>
                  ))
              ) : (
                <tr>
                  <td>Belum ada notifikasi</td>
                </tr>
              )}
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      <LocationHistoryModal
        show={showPlaybackModal}
        onClose={handleClosePlaybackModal}
       data={data}
      />
    </>
  )
}
export default NotificationModal
