import { Modal } from "react-bootstrap"
import { PersonalInfoType } from "../models/personalInfo"
import { useEffect, useState } from "react"
import { DeviceType } from "../models/device"
import { InventoryLog, InventoryType } from "../models/inventory"

interface TableLogRowModalProps {
    openModal: boolean
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    showModal: () => void
    data?: Array<PersonalInfoType>
    dataDevice?: Array<DeviceType>
    dataInventory?: InventoryLog
    dataType: string
}

const TableLogRowModal: React.FC<TableLogRowModalProps> = ({ data, dataDevice, dataInventory, showModal, openModal, setOpenModal, dataType }) => {
    const [dataRowsLog, setDataRowsLog] = useState<Array<PersonalInfoType>>([]);
    const [dataRowsLogDevice, setDataRowsLogDevice] = useState<Array<DeviceType>>([]);
    const [dataRowsLogInventory, setDataRowsLogInventory] = useState<Array<InventoryType>>([]);
    const [ isShowImported, setIsShowImported ] = useState<boolean>(true);
    useEffect(() => {
        if (data) {
            setDataRowsLog(data);
        }
        if (dataDevice) {
            setDataRowsLogDevice(dataDevice);
        } 
        if (dataInventory) {
            if ( isShowImported ) {
                setDataRowsLogInventory(dataInventory.imported_rows);
            } else {
                setDataRowsLogInventory(dataInventory.failed_rows);
            }
        }
    },[data, dataDevice, dataInventory, isShowImported]);


    return (
    <>
    <Modal
        show={openModal}
        onHide={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-lg"
        contentClassName="modal-content"
        closeButton
    >
        <Modal.Body>
            <div className="d-flex justify-content-between mb-4" >
               <div>
               <p className="h3 text-black text-bold">Hasil Unggahan</p>
                { dataRowsLog?.length ? <p className="text-black-50">{dataRowsLog?.length} Data Jamaah</p>: null}
                { dataRowsLogDevice?.length ? <p className="text-muted h5">{dataRowsLogDevice.length} Perangkat </p> : null}
                { dataType === "inventory" ? <p className="text-muted h5">{(dataInventory?.imported_rows?.length ?? 0) + (dataInventory?.failed_rows?.length ?? 0) } Perangkat </p> : null}
               </div>
               <div>
                    <i className="bi bi-x-circle-fill" onClick={() => setOpenModal(false)}></i>
               </div>
            </div>
            {dataType === "inventory" && (
                <div className="d-flex mb-4 gap-4">
                    <div className="d-flex" onClick={() => setIsShowImported(true)}>
                        <div className={`border ${isShowImported ? "border-bottom-4" : ""} border-success rounded ps-4 pe-16 py-2 d-flex align-items-center justify-content-center`}>
                        <i className="bi bi-check-circle-fill text-success me-2"></i> <p className="p-0 m-0">{dataInventory?.imported_rows?.length ?? 0} Berhasil</p>
                        </div>
                    </div>
                    <div className="d-flex" onClick={() => setIsShowImported(false)}>
                        <div className={`border ${!isShowImported ? "border-bottom-4" : ""} border-danger rounded ps-4 pe-16 py-2 d-flex align-items-center justify-content-center`}>
                        <i className="bi bi-x-circle-fill text-danger me-2"></i> <p className="p-0 m-0">{dataInventory?.failed_rows?.length ?? 0} Gagal</p>
                        </div>
                    </div>
                </div>
            )}
            <div className="border border-secondary border border-1 border-gray-500 rounded">
                <div style={{ height: '400px', overflowY: 'auto' }}>
                { dataType === "jamaah" ? (
                    <table className="table caption-top table-border-bottom margin-bottom-0">
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <tr className="border-bottom border-bottom-1 border-gray-500 bg-gray-200">
                            <th className="w-24 ps-4">#</th>
                            <th className="w-24 text-nowarp">NAMA</th>
                            <th className="w-24 text-nowarp">ID HAJI JAMAAH</th>
                            <th className="w-24 text-nowarp">JENIS KELAMIN</th>
                            <th className="w-24 text-nowarp">TANGGAL LAHIR</th>
                            <th className="w-24 text-nowarp">GOLONGAN DARAH</th>
                            <th className="w-24 text-nowarp">ASAL KOTA/DAERAH</th>
                            <th className="w-24 text-nowarp">ALAMAT</th>
                            <th className="w-24 text-nowarp">PEKERJAAN</th>
                            <th className="w-24 text-nowarp">KONTAK DARURAT 1</th>
                            <th className="w-24 text-nowarp">KONTAK DARURAT 2</th>
                            <th className="w-24 text-nowarp">CATATAN KESEHATAN</th>
                            <th className="w-24 text-nowarp">STATUS RISTI</th>
                            <th className="w-24 text-nowarp">BERAT BADAN</th>
                            <th className="w-24 text-nowarp">TINGGI BADAN</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left", justifyContent: "center" }}>
                        {dataRowsLog && dataRowsLog.map((row, index) => (
                            <tr key={index}>
                                <td className="ps-4">{index + 1}</td>
                                <td>{row.name ?? "-"}</td>
                                <td>{row.hajj_info ?? "-"}</td>
                                <td>{row.gender ?? "-"}</td>
                                <td>{row.date_of_birth ?? "-"}</td>
                                <td>{row.blood_type ?? "-"}</td>
                                <td>{row.place_of_origin ?? "-"}</td>
                                <td>{row.address ?? "-"}</td>
                                <td>{row.job ?? "-"}</td>
                                <td>{row.emergency_contact_1 ?? "-"}</td>
                                <td>{row.emergency_contact_2 ?? "-"}</td>
                                <td>{row.medical_notes ?? "-"}</td>
                                <td>{row.status_risti === 0 ? "Tidak Risti" : "Risti"}</td>
                                <td>{row.weight ?? "0"} kg</td>
                                <td>{row.height ?? "0"} cm</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                ): null}
                {dataType === "device" ? (
                    <table className="table caption-top table-border-bottom margin-bottom-0">
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr className="border-bottom border-bottom-1 border-gray-500 bg-gray-200">
                            <th className="text-center ps-4">#</th>
                            <th>ICC ID</th>
                            <th>IMEI / SERIAL NUMBER</th>
                            <th>MODEL PERANGKAT</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left", justifyContent: "center" }}>
                        {dataRowsLogDevice && dataRowsLogDevice.map((row, index) => (
                            <tr key={index}>
                                <td className="text-center ps-4">{index + 1}</td>
                                <td>{row.icc_id ?? "-"}</td>
                                <td>{row.imei ?? "-"}</td>
                                <td>{row.device_model_id ?? "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                ): null}
                { dataType === "inventory" ? (
                    <table className="table caption-top table-border-bottom margin-bottom-0">
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <tr className="border-bottom border-bottom-1 border-gray-500 bg-gray-200">
                            <th className="text-center ps-4">#</th>
                            <th>IMEI</th>
                            <th>SERIAL NUMBER</th>
                            <th>KODE PRODUKSI</th>
                            <th>TANGGAL PRODUKSI</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left", justifyContent: "center" }}>
                        {dataRowsLogInventory && dataRowsLogInventory.map((row, index) => (
                            <tr key={index}>
                                <td className="text-center ps-4">{index + 1}</td>
                                <td>{row.imei ?? "-"}</td>
                                <td>{row.serial_number ?? "-"}</td>
                                <td>{row.production_code ?? "-"}</td>
                                <td>{row.production_date ?? "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                ) : null}
                </div>
            </div>
        </Modal.Body>
    </Modal>

    </>    
    )
}

export default TableLogRowModal