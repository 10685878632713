import axios from 'axios'

const endpoint = '/api/firebase_tokens'

interface IDataRegister {
  token: string
  user_id: number
}

interface ErrorResponse {
  data: null | string
  error: null | string
  message: null | string
}

export default async function registerToken({token, user_id = 123}: IDataRegister) {
  // Change or remove default
  const data = {
    token,
    user_id,
  }
  try {
    return await axios.post(endpoint, data)
  } catch (error) {
    if (axios.isAxiosError<ErrorResponse>(error)) {
      if (error.response?.data.error === 'Duplicate entry') {
        console.log('Token already registered')
      } else {
        return await Promise.reject<ErrorResponse>(error)
      }
    }
  }
}
