interface GeofenceCircle {
  type: 'Feature'
  geometry: {
    type: 'Polygon'
    coordinates: [number, number][][]
  }
  properties: {
    radius: number
    center: [number, number]
  }
}

function createGeofenceCircle(
  center: [number, number],
  radiusInMeters: number,
  points = 64
): GeofenceCircle {
  const coordinates: [number, number][] = []
  const earthRadius = 6371000

  for (let i = 0; i < points; i++) {
    const angle = (i / points) * (2 * Math.PI)
    const dx = radiusInMeters * Math.cos(angle)
    const dy = radiusInMeters * Math.sin(angle)

    const latitude = center[1] + (dy / earthRadius) * (180 / Math.PI)
    const longitude =
      center[0] + ((dx / earthRadius) * (180 / Math.PI)) / Math.cos(center[1] * (Math.PI / 180))

    coordinates.push([longitude, latitude])
  }

  coordinates.push(coordinates[0])

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates],
    },
    properties: {
      radius: radiusInMeters,
      center,
    },
  }
}

export default createGeofenceCircle
