import React from 'react'
import { Chart } from 'react-google-charts'
import { useGetDataDashboard } from '../../../../app/hooks/dashboard'
import { Card } from 'react-bootstrap'

type Props = {
  className: string
}

export const DataPekerjaan = {
  title: 'Data Pekerjaan',
  textPosition: 'out',
  legend: 'none',
  titleTextStyle: {
    fontSize: 16,
    bold : false
  },
  chartArea: { width: '60%' },
  series: {
    0: { color: '#8362AB' },
  },
  annotations: {
    alwaysOutside: true,
    textStyle: {
      fontSize: 10,
      color: '#000',
    },
    stem: {
      color: 'none',
    },
  },
  hAxis: {
    minValue: 0,
    textPosition: 'out',
    textStyle: {
      fontSize: 10,
      color: '#000',
      italic: false,
      bold: false,
    },
    format: '0',
  },
  vAxis: {
    textPosition: 'out',
    textStyle: {
      fontSize: 10,
      color: '#000',
      italic: false,
    },
  },
}

export const ChartsWidget5: React.FC<Props> = ({ className }) => {
  const { data: dataDashboard } = useGetDataDashboard()
  const data = [
    ['Pekerjaan', 'Total Jamaah', { role: 'annotation' }],
    ['Pedagang', dataDashboard?.personal_info_by_job?.pedagang, dataDashboard?.personal_info_by_job?.pedagang],
    ['Ibu Rumah Tangga', dataDashboard?.personal_info_by_job?.ibu_rumah_tangga, dataDashboard?.personal_info_by_job?.ibu_rumah_tangga],
    ['Pegawai Swasta', dataDashboard?.personal_info_by_job?.pegawai_swasta, dataDashboard?.personal_info_by_job?.pegawai_swasta],
    ['Pegawai BUMN', dataDashboard?.personal_info_by_job?.pegawai_bumn, dataDashboard?.personal_info_by_job?.pegawai_bumn],
    ['Pelajar / Mahasiswa', dataDashboard?.personal_info_by_job?.pelajar_mahasiswa, dataDashboard?.personal_info_by_job?.pelajar_mahasiswa],
    ['Pensiun', dataDashboard?.personal_info_by_job?.pensiun, dataDashboard?.personal_info_by_job?.pensiun],
    ['PNS', dataDashboard?.personal_info_by_job?.pns, dataDashboard?.personal_info_by_job?.pns],
    ['Petani', dataDashboard?.personal_info_by_job?.petani, dataDashboard?.personal_info_by_job?.petani],
    ['TNI / Polri', dataDashboard?.personal_info_by_job?.tni_polri, dataDashboard?.personal_info_by_job?.tni_polri],
    ['Lainnya', dataDashboard?.personal_info_by_job?.lainnya, dataDashboard?.personal_info_by_job?.lainnya],
  ]

  return (
      <Card className='px-4'>
        <Chart chartType='BarChart' width='100%' height='338px' data={data} options={DataPekerjaan} />
      </Card>
  )
}
