import { Link, useParams } from "react-router-dom"
import { KTCard, toAbsoluteUrl } from "../../../_metronic/helpers"
import { PageTitle } from "../../../_metronic/layout/core"
import "./buttonadd.css"
import { Nav } from "react-bootstrap"
import { useGetPersonalInfoById } from "../../hooks/personalInfo"
import { useState } from "react"
import ListItem from "../../components/ListItem"
import { useGetListDataJamaah } from "../../hooks/useGetDataJamaahList"
import { useAppContext } from "../../../_metronic/helpers/AppContext"

const DetailJamaah = () => {
    const { id, type } = useParams();
    const { data: dataJamaahById } = useGetPersonalInfoById(id);
    const { role, organizationName, subOrganizationName, nameGroup } = useAppContext()
    const [activeMenu, setActiveMenu] = useState<any>(
        {
            menu_1: false,
            menu_2: true,
            menu_3: true,
        }
    )
    const {listDataJamaah , listDataKesehatan, listDataTambahan }= useGetListDataJamaah(id)
    const borderStyle = 'border-bottom border-2 border-gray-200';
    
    return (
        <>
            <PageTitle breadcrumbs={[
            {
                title: role === 2 ? organizationName : role === 3 ? subOrganizationName : role === 4 ?  `${subOrganizationName} - ${nameGroup}` : 'TSM',
                path:  type === 'jamaah' ? '/jamaah' : '/devices',
                description:'Informasi Detail',
                isActive: false,
                isSeparator: false,
            }]}>Jamaah</PageTitle>
            <KTCard className='p-5 shadow-sm'>
                <div className="card-title d-flex justify-content-between">
                    <div
                        data-bs-target='#kt_account_profile_details'
                        aria-controls="kt_account_profile_details">
                        <div className="card-title m-0 d-flex flex-column">
                            <div className='d-flex align-items-center position-relative my-1'>
                                <h3 className='m-0'>Informasi Detail</h3>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Link className="btn btn-secondary fw-bold text-gray-600" to={type === 'jamaah' ? '/jamaah' : '/devices'}><i className="bi bi-chevron-left"></i>Kembali</Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                    <div className='card-personal-info-photo py-5'>
                    <div className='symbol symbol-100px' style={{ overflow: 'hidden', width: '100px', height: '100px', borderRadius: '50%' }}>
                        <img
                            src={dataJamaahById?.photo_url ? dataJamaahById?.photo_url : toAbsoluteUrl('/assets/profile.jpg')}
                            alt=''
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            onError={(e) => {
                            e.currentTarget.src = toAbsoluteUrl('/media/svg/humans/custom-2.svg');
                            e.currentTarget.style.transform = 'scale(1.5)';
                            e.currentTarget.style.transition = 'transform 0.3s ease-in-out';
                            e.currentTarget.style.objectFit = 'cover';
                            }}
                        />
                        </div>

                        <p className='text-black-100 fw-bold mt-2'>{dataJamaahById?.name}</p>
                        <p className='text-black-50'>{dataJamaahById?.gender === 'male' ? 'Laki-laki' : 'Perempuan'}</p>
                        <p className='text-black-50'>
                            {dataJamaahById?.age} Tahun
                        </p>
                        <div className="d-flex flex-column gap-3">
                            {parseInt(dataJamaahById?.elderly_status ?? '0') === 0 ? <></> : <>
                                <div className="py-1 px-6 rounded fw-bold text-center" style={{ color: 'white', backgroundColor: '#E5201D' }}>
                                    Lansia
                                </div>
                            </>}
                            {parseInt(dataJamaahById?.disability_status ?? '0') === 0 ? <></> : <> 
                                <div className="py-1 px-6 rounded text-center fw-bold" style={{ color: 'white', backgroundColor: '#E5651D' }}>
                                    Disabilitas
                                </div>
                            </>}
                            {dataJamaahById?.status_risti  === 0 ? <></> : <> 
                                <div>
                                    <div className="py-1 px-6 rounded text-center fw-bold" style={{ color: 'black', backgroundColor: '#E1E51D' }}>
                                        Risti
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                    </div>
                    <div className="col-9">
                    <Nav variant="underline" className="navbar-personal-info" defaultActiveKey={"link-1"}>
                        <Nav.Item>
                            <Nav.Link eventKey="link-1" onClick={() => setActiveMenu({menu_1: false, menu_2: true, menu_3: true})}>Data Jamaah</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-2" onClick={() => setActiveMenu({menu_1: true, menu_2: false, menu_3: true})}>Data Kesehatan</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="link-3" onClick={() => setActiveMenu({menu_1: true, menu_2: true, menu_3: false})}>Data Haji / Umroh</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <div className="card-personal-info-detail">
                        <div className="data-jamaah  flex-column" hidden={activeMenu.menu_1}>
                            {listDataJamaah.map((item, index) => (
                                <ListItem key={index} className={index !== listDataJamaah.length - 1 ? borderStyle : ""} label={item.label} data={item.data} />
                            ))}
                        </div>
                        <div className="data-kesehatan  flex-column" hidden={activeMenu.menu_2}>
                            {listDataKesehatan.map((item, index) => (
                                <ListItem key={index} className={index !== listDataKesehatan.length - 1 ? borderStyle : ""} label={item.label} data={item.data} />
                            ))}
                        </div>
                        <div className="data-haji-umroh flex-column" hidden={activeMenu.menu_3}>
                            {listDataTambahan.map((item, index) => (
                                <ListItem key={index} className={index !== listDataTambahan.length - 1 ? borderStyle : ""} label={item.label} data={item.data} />
                            ))}
                        </div>
                    </div>
                    </div>
                </div>
            </KTCard>
        </>
    )
}

export default DetailJamaah